import React, { Component } from "react";
import { Menu } from "./menu";
import Mobilemenu from "./Mobile_menu";
import { Link } from "react-router-dom";
import logo from "./../../images/logo.png";

export class Header extends Component {

  state = {
    show: false,
  };

  toggle = () => this.setState((currentState) => ({ show: !currentState.show }));

  render() {
    return (
      <header id="masthead" className="header ttm-header-style-01">
        <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-5">
                <ul className="top-contact text-left">
                  <li><i className="fas fa-map-marker-alt" />&nbsp;&nbsp;<a href="http://rvsangha.org/">An Institution of Rajya Vokkaligara Sangha (RVS)</a></li>
                  <li><i className="far fa-envelope" />&nbsp;&nbsp;<a href="true" to="mailto:info@example.com.com">info@example.com</a></li>
                </ul>
              </div>
              <div className="col-lg-7">
                <div className="topbar-right text-right d-flex justify-content-end">
                  <div className="ttm-social-links-wrapper list-inline">
                    <ul className="social-icons">
                      <li>
                        <a href="https://www.facebook.com/Vokkaligara-Sangha-Dental-College-Hospital-Bengaluru-100355139067559" target="_blank" className=" tooltip-bottom" data-tooltip="Facebook"><i className="fab fa-facebook-f" /></a>
                      </li>
                      <li className="social-twitter">
                        <a href=" https://www.instagram.com/vsdentalcollege/ " className="tooltip-bottom" target="_blank" data-tooltip="Instagram"><i className="fab fa-instagram"></i></a>
                      </li>
                    </ul>
                  </div>
                  <div className="header-btn">
                    <Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor" to={process.env.PUBLIC_URL + "/Admission"}>Admission</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ttm-header-wrap">
          <div id="navbar" className="ttm-stickable-header clearfix">
            <div className="site-header-menu">
              <div className="container-xl">
                <div className="site-branding">
                  <a className="home-link" href={process.env.PUBLIC_URL + "/"} title="VSDC" rel="home">
                    <img id="logo-img" className="img-fluid" alt="logo" src={logo} />
                  </a>
                </div>
                <div className="site-navigation">
                  <Menu />
                  <div className="mobilemenu"><Mobilemenu /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
