import React, { Component } from 'react';
import Header from '../../layout/Header';
import image1 from "../../../images/Bestpractices/Orofacialpainclinicimage.jpeg";
import image2 from "../../../images/Bestpractices/DanthaBhagya1.jpeg";
import image3 from "../../../images/Bestpractices/TCc.jpg";
import image4 from "../../../images/Bestpractices/Implant.jpeg";
import image5 from "../../../images/Bestpractices/Express1.png";
import image6 from "../../../images/Bestpractices/Schoolhealth1.jpg";
import image7 from "../../../images/Bestpractices/workshop3.jpg";
import image8 from "../../../images/Bestpractices/Orientation2.jpeg";
import image9 from "../../../images/Bestpractices/IndhiraGandhi.jpeg";
import image10 from "../../../images/Bestpractices/InnovationCell.jpg";
import image11 from "../../../images/Bestpractices/NSS/NSS9.jpg"
import { Link } from 'react-router-dom';

export class BestPractices extends Component {
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row bestpractices-banner">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading  card_box">
                            <h4 className="title" style={{color:"white"}}>Best Practices VSDCH</h4>
                            <div className="ttm-color-home">
                            <span>
                                <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>VSDCH</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}
                        </div>{/* /.page-title-captions */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                     
                </div>
                {/* page-title end*/}
               <section className="ttm-row grid-section clearfix">
                    <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                        <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                            <div className="featured-thumbnail">{/* featured-thumbnail */}
                            <img className="dept-image" src={image1} alt="image" />
                            </div> 
                            <div className="featured-content box-shadow">
                            <div className="featured-title">{/* featured-title */}
                                <h5><Link to ={process.env.PUBLIC_URL + '/OrofacialPainClinic'}>Orofacial Pain clinic</Link></h5>
                            </div>
                            </div>
                        </div>{/* featured-imagebox-team end*/}
                        </div>
                        <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                        <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                            <div className="featured-thumbnail">{/* featured-thumbnail */}
                            <img className="dept-image" src={image2} alt="image" />
                            </div> 
                            <div className="featured-content box-shadow">
                            <div className="featured-title">{/* featured-title */}
                                <h5><Link to ={process.env.PUBLIC_URL + '/DanthaBhagyaYojane'}>Dhantha Bhagya Yogane</Link></h5>
                            </div>
                            </div>
                        </div>{/* featured-imagebox-team end*/}
                        </div>
                        <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                        <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                            <div className="featured-thumbnail">{/* featured-thumbnail */}
                            <img className="dept-image" src={image3} alt="image" />
                            </div> 
                            <div className="featured-content box-shadow">
                            <div className="featured-title">{/* featured-title */}
                                <h5><Link to ={process.env.PUBLIC_URL + '/TobaccoCessationCentre'}>Tobacco Cessation Centre</Link></h5>
                            </div>
                            </div>
                        </div>{/* featured-imagebox-team end*/}
                        </div>
                        <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                        
                        <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                            <div className="featured-thumbnail">{/* featured-thumbnail */}
                            <img className="dept-image" src={image4} alt="image" />
                           
                            </div> 
                            <div className="featured-content box-shadow">
                            <div className="featured-title">{/* featured-title */}
                                <h5><Link to ={process.env.PUBLIC_URL + '/ImplantClinic'}>Implant Clinic</Link></h5>
                            </div>
                            </div>
                        </div>{/* featured-imagebox-team end*/}
                        </div>
                        <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                        <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                            <div className="featured-thumbnail">{/* featured-thumbnail */}
                            <img className="dept-image" src={image5} alt="image" />
                            
                            </div> 
                            <div className="featured-content box-shadow">
                            <div className="featured-title">{/* featured-title */}
                                <h5><Link to ={process.env.PUBLIC_URL + '/ExpressDentalCare'}>Express Dental Care	</Link></h5>
                            </div>
                            </div>
                        </div>{/* featured-imagebox-team end*/}
                        </div>
                        <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                        <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                            <div className="featured-thumbnail">{/* featured-thumbnail */}
                            <img className="dept-image" src={image6} alt="image" />
                            </div> 
                            <div className="featured-content box-shadow">
                            <div className="featured-title">{/* featured-title */}
                                <h5><Link to ={process.env.PUBLIC_URL + '/SchoolHealth'}>School Health Programs</Link></h5>
                            </div>
                            </div>
                        </div>{/* featured-imagebox-team end*/}
                        </div>
                    </div>
                    {/* row end*/}
                    <div className="row">
                        <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                        <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                            <div className="featured-thumbnail">{/* featured-thumbnail */}
                            <img className="dept-image" src={image7} alt="image" />
                           </div> 
                            <div className="featured-content box-shadow">
                            <div className="featured-title">{/* featured-title */}
                                <h5><Link to={process.env.PUBLIC_URL + '/Workshop'}>Capacity Building Workshops for life skills and Scientific Temper</Link></h5>
                            </div>
                            </div>
                        </div>{/* featured-imagebox-team end*/}
                        </div>
                        <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                        <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                            <div className="featured-thumbnail">{/* featured-thumbnail */}
                            <img className="dept-image" src={image8} alt="image" />
                            </div> 
                            <div className="featured-content box-shadow">
                            <div className="featured-title">{/* featured-title */}
                                <h5><Link to ={process.env.PUBLIC_URL + '/OrientationSessions'}>Orientation Sessions for Interns</Link></h5>
                            </div>                         
                            </div>
                        </div>{/* featured-imagebox-team end*/}
                        </div>
                        <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                        <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                            <div className="featured-thumbnail">{/* featured-thumbnail */}
                            <img className="dept-image" src={image9} alt="image" />
                           </div> 
                            <div className="featured-content box-shadow">
                            <div className="featured-title">{/* featured-title */}
                                <h5><Link to ={process.env.PUBLIC_URL + '/IndhiraGandhiChildHealth'}>Indhira Gandhi Institute of Child Health</Link></h5>
                            </div>
                            </div>
                        </div>{/* featured-imagebox-team end*/}
                       </div>
                       <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                        <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                            <div className="featured-thumbnail">{/* featured-thumbnail */}
                            <img className="dept-image" src={image10} alt="image" />
                           </div> 
                            <div className="featured-content box-shadow">
                            <div className="featured-title">{/* featured-title */}
                                <h5><Link to ={process.env.PUBLIC_URL + '/InnovationCell'}>Institution Innovation Cell</Link></h5>
                            </div>
                            </div>
                        </div>{/* featured-imagebox-team end*/}
                       </div>
                       <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                        <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                            <div className="featured-thumbnail">{/* featured-thumbnail */}
                            <img className="dept-image" src={image11} alt="image" />
                           </div> 
                            <div className="featured-content box-shadow">
                            <div className="featured-title">{/* featured-title */}
                                <h5><Link to ={process.env.PUBLIC_URL + '/NSS'}>NSS</Link></h5>
                            </div>
                            </div>
                        </div>{/* featured-imagebox-team end*/}
                       </div>
                      </div>
                    </div>
                </section>  
            </div>
            
            )
    }
}


export default BestPractices;