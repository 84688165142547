import React, { Component } from 'react';
import Header from '../../layout/Header';
import image1 from"../../../images/Bestpractices/Orientation1.jpeg";

export class OrientationSessions extends Component {
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row bestpractices-banner">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading  card_box">
                            <h4 className="title" style={{color:"white"}}>Orientation Session For Interns  </h4>
                            <div className="ttm-color-home">
                            <span>
                                <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>VSDCH</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}
                        </div>{/* /.page-title-captions */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                     
                </div>
                {/* page-title end*/}
                {/* about-services-section */}
                <section className="ttm-row about-services-section clearfix">
                    <div className="container-xl">
                        <div className="row align-items-center">{/* row */}
                            <div className="col-lg-6">
                                <div className="position-relative">
                                    {/* ttm_single_image-wrapper */}
                                    <div className="ttm_single_image-wrapper text-sm-center">
                                        <img className="img-fluid" src={image1} title="single-img-ten" alt="single-img-ten" />
                                    </div>{/* ttm_single_image-wrapper end */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="res-991-mt-30">
                                    {/* section title */}
                                    <div className="section-title with-desc clearfix">
                                        <div className="title-header">
                                            <h2 className="title"> Orientation <span> Sessions</span></h2>
                                        </div>
                                        <div className="title-desc">
                                            <p className='text-justify'>Dept of Oral and Maxillofacial Pathology initiated the orientation session for interns “Learning curves- Oral Pathology for Interns”, covering major sections in the subject. These sessions will help interns to understand the subject better to prepare for MDS Entrance examination. </p>
                                        </div>
                                    </div>{/* section title end */}
                                    {/* row */}
                                    <div className="row mt-25">
                                        <div className="col-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box iconalign-before-heading">
                                                <div className="featured-content">{/* featured-content */}

                                                    <div className="featured-title">{/* featured-title */}
                                                        <h5> Person in Charge: Dr Priya N S
                                                        </h5>
                                                    </div>
                                                </div>

                                            </div>{/* featured-icon-box */}
                                        </div>
                                    </div>
                                    {/* separator */}
                                </div>
                            </div>
                        </div>
                        {/* row end */}
                    </div>
                </section>
            </div>
        )
    }
}


export default OrientationSessions;