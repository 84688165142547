import React, { Component } from "react";
import ProsthodonticsSidebar from "./ProsthodonticsSidebar";
import Header from "../../../layout/Header";
import Slider from "react-slick";
import firestore from "../../../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import ProResAchievements from "./ProResAchievements";
import NoData from "../../../pages/NoData";

export class ProResearch extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Achievements: [],
      ConsultancyServices: [],
      Patents: [],
      Publication: [],
      ResearchFacilities: [],
      ResearchGrants: [],
      ResearchGuides: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "Prosthodontics");
    const achievementskey = "Achievements";
    const consultancykey = "ConsultancyServices";
    const patentskey = "Patents";
    const publicationkey = "Publication";
    const resfacilitykey = "ResearchFacilities";
    const resguidekey = "ResearchGuides";
    const resgrantskey = "ResearchGrants";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      console.log(Snapshot);
      const allDynamicData = this.getDynamicData(Snapshot);
      console.log(allDynamicData);
      this.dynamicData.Achievements = allDynamicData[achievementskey];
      this.dynamicData.ConsultancyServices = allDynamicData[consultancykey];
      this.dynamicData.Patents = allDynamicData[patentskey];
      this.dynamicData.Publication = allDynamicData[publicationkey];
      this.dynamicData.ResearchFacilities = allDynamicData[resfacilitykey];
      this.dynamicData.ResearchGuides = allDynamicData[resguidekey];
      this.dynamicData.ResearchGrants = allDynamicData[resgrantskey];

      console.log("test data");

      console.log(this.dynamicData.Achievements);
      console.log(this.dynamicData.ConsultancyServices);
      console.log(this.dynamicData.Patents);
      console.log(this.dynamicData.Publication);
      console.log(this.dynamicData.ResearchFacilities);
      console.log(this.dynamicData.ResearchGuides);
      console.log(this.dynamicData.ResearchGrants);
    }
    this.setState({});
  }
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row prosthodontics_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Research and Innovations
                    </h4>

                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Prosthodontics and Crown and Bridge</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.row */}
            </div>
            {/* /.page-title-captions */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <section className="ttm-row service-section-style2 res-991-pb-0 ttm-bgcolor-grey clearfix">
                  <div className="container-xl">
                    <div className="row">
                      {/* row */}
                      <div className="col-lg-12">
                        {/* section title */}
                        <div className="section-title with-desc text-center clearfix">
                          <div className="title-header">
                            <h5>Research Innovations</h5>
                            <h2 className="title">
                              ACHIEVEMENTS<span></span>
                            </h2>
                          </div>
                        </div>
                        {/* section title end */}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      {/* row */}
                      <ProResAchievements />
                    </div>
                    {/* row end */}
                  </div>
                </section>

                <section className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
                  <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                  <div className="container-xl">
                    <div className="row">
                      <div className="col-lg-8 offset-lg-2">
                        {/* section title */}
                        <div className="section-title with-desc text-center clearfix">
                          <div className="title-header">
                            <h2 className="title">
                              Research <span>Guides </span>
                            </h2>
                          </div>
                        </div>
                        {/* section title end */}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      {/* row */}
                      {this.dynamicData.ResearchGuides === undefined ? (
                        <NoData />
                      ) : (
                        this.dynamicData.ResearchGuides.length &&
                        this.dynamicData.ResearchGuides.map(function (
                          item,
                          index
                        ) {
                          return (
                            <div
                              className="col-md-4 "
                              key={index}
                              style={{ padding: "5px" }}
                            >
                              {/*  featured-icon-box */}
                              <div className="featured-icon-box style6">
                                <div className="featured-content">
                                  {/*  featured-content */}
                                  <div className="featured-title">
                                    {/*  featured-title */}
                                    <h5> {item.Name}</h5>
                                  </div>
                                  <div className="featured-desc">
                                    {/*  featured-desc */}
                                    <p>
                                      <i
                                        class="fab fa-searchengin"
                                        style={{
                                          color: "rgb(67, 67, 122)",
                                        }}
                                      ></i>{" "}
                                      Research: {item.Research}
                                    </p>
                                  </div>
                                  <div className="featured-desc">
                                    {/*  featured-desc */}
                                    <p>
                                      <i
                                        class="fas fa-user-graduate"
                                        style={{
                                          color: "rgb(67, 67, 122)",
                                        }}
                                      />{" "}
                                      Students Pursuing: {item.Student}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/*  featured-icon-box END */}
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-3 widget-area">
                <ProsthodonticsSidebar />
              </div>
            </div>
            {/* row end */}
            <section className="ttm-row service-section-style2 res-991-pb-0 ttm-bgcolor-grey clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title with-desc text-center clearfix">
                      <div className="title-header">
                        <h2 className="title">
                          Research <span>Facilities</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  {/* row */}
                  {this.dynamicData.ResearchFacilities === undefined ? (
                    <NoData />
                  ) : (
                    this.dynamicData.ResearchFacilities.length &&
                    this.dynamicData.ResearchFacilities.map(function (
                      item,
                      index
                    ) {
                      return (
                        <div className="col-md-6 col-lg-3" key={index}>
                          {/*  featured-icon-box */}
                          <div className="featured-icon-box style6">
                            <div className="featured-content">
                              {/*  featured-content */}
                              <div className="featured-title">
                                {/*  featured-title */}
                                <h5> {item.Name}</h5>
                              </div>
                              <div className="featured-desc">
                                {/*  featured-desc */}
                                <p>{item.Description}</p>
                              </div>

                              <div className="featured-desc">
                                {/*  featured-desc */}
                                <p>
                                  <i class="fas fa-user-shield"></i> faculty:
                                  {item.Facility}
                                </p>
                                <p>
                                  <i class="fas fa-atlas"></i> Application:
                                  {item.Application}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/*  featured-icon-box END */}
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </section>

            <section className=" price-text-section res-991-pb-0 ttm-bgcolor-grey clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-md-12">
                    {/* section title */}
                    <div className="section-title text-center with-desc clearfix">
                      <div className="title-header">
                        <h2 className="title">
                          Research <span>Grants</span>
                        </h2>
                      </div>
                    </div>
                    {/* section title end */}
                  </div>
                </div>
              </div>
            </section>
            {/* price text section end*/}
            {/* price section*/}
            <section
              className="ttm-row topzero-padding-section res-991-pt-0 clearfix"
              style={{ paddingBlockEnd: "10px" }}
            >
              <div className="container-xl">
                {/* row */}

                <div className="row no-gutters mt_100 res-991-mt-0">
                  {this.dynamicData.ResearchGrants === undefined ? (
                    <NoData />
                  ) : (
                    this.dynamicData.ResearchGrants.length &&
                    this.dynamicData.ResearchGrants.map(function (item, index) {
                      return (
                        <div className="col-md-4" key={index}>
                          {/*ttm-pricing-plan*/}
                          <div
                            className="ttm-pricing-plan box-shadow text-left clearfix "
                            style={{
                              marginRight: "20px",
                              marginBlockEnd: "20px",
                            }}
                          >
                            <div className="ttm-ptablebox-title">
                              <h3>{item.Title}</h3>
                            </div>
                            {/*title*/}
                            <div className="ttm-ptablebox-desc">
                              {item.Name}
                            </div>
                            <div className="ttm-ptablebox-content">
                              <div className="ttm-ptablebox-price-w">
                                <div className="ttm-ptablebox-cur-symbol-before">
                                  <i class="fas fa-rupee-sign"></i>
                                </div>
                                <div
                                  className="ttm-ptablebox-price"
                                  style={{ fontSize: "30px" }}
                                >
                                  {item.Amount}
                                </div>
                                {/*price*/}
                              </div>
                              <div className="ttm-ptablebox-features">
                                {/*features*/}
                                <ul className="ttm-feature-lines">
                                  <li>Duration : {item.Duration}</li>
                                  <li>Investor : {item.Investor}</li>
                                  <li>Status : {item.Status}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/*ttm-pricing-plan*/}
                        </div>
                      );
                    })
                  )}
                </div>
                {/* row end */}
              </div>
            </section>
            {/* price section end*/}

            <section className=" aboutus-section clearfix">
              <div className=" only-one-section ttm-bgcolor-white clearfix">
                <div className="container-xl">
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">Research <span>Publication</span></h2>
                    </div>
                  </div>
                  <section className="ttm-row bottomzero-padding-section  ttm-bg ttm-bgimage-yes bg-img5 ttm-bgcolor-skincolor clearfix">
                    <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                    <div className="container-xl pb-5 res-991-pb-0">
                      <div className="row align-items-center ttm-textcolor-white">
                        <div className="col-lg-8 res-991-text-center">
                        <h5 className="title-desc">A repository of research publications of teaching faculty and post graduate scholars related to subject specialty illuminating on innovative practices and research concepts</h5>
                        </div>
                        <div className="col-lg-4 text-right res-991-text-center res-991-mt-20">
                          <a href="true" className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white">Publications</a>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <section className="ttm-row service-section position-relative z-1 clearfix">
              <div className="container-xl">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Research <span>Patents</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
                <div className="row no-gutters  ">
                  {this.dynamicData.Patents === undefined ? (
                    <NoData />
                  ) : (
                    this.dynamicData.Patents.length &&
                    this.dynamicData.Patents.map(function (item, index) {
                      return (
                        <div className="col-lg-4 col-md-6 d-flex" key={index}>
                          {/* featured-imagebox-blog */}
                          <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                            <div className="featured-content">
                              {/* featured-content */}
                              <div className="featured-title">
                                {/* featured-title */}
                                <h6>{item.InventorName}</h6>
                              </div>
                              <div className="post-meta">
                                {/* post-meta */}
                                <p>
                                  {" "}
                                  <span className="ttm-meta-line">
                                    <i class="fab fa-accusoft"></i>Title:
                                    {item.PatentTitle}
                                  </span>
                                </p>
                              </div>
                              <div className="post-meta">
                                {/* post-meta */}
                                <span className="ttm-meta-line">
                                  <i class="fas fa-battery-full"></i> Status:
                                  {item.Status}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* featured-imagebox-blog end */}
                        </div>
                        // <div className="col-md-4" key={index}>
                        //   <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100">
                        //     <div className="featured-icon">
                        //       {/*  featured-icon */}
                        //       <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                        //         <i className="flaticon flaticon-system" />
                        //         {/*  ttm-icon */}
                        //       </div>
                        //     </div>
                        //     <div className="featured-content">
                        //       {/*  featured-content */}
                        //       <div className="featured-title">
                        //         {/*  featured-title */}
                        //         <h5>{item.InventorName}</h5>
                        //       </div>
                        //       <div className="featured-title">
                        //         {/*  featured-title */}
                        //         <h5>{item.PatentTitle}</h5>
                        //       </div>
                        //       <div className="featured-desc">
                        //         {/*  featured-desc */}
                        //         <p>{item.Status}</p>
                        //       </div>
                        //     </div>
                        //   </div>
                        // </div>
                      );
                    })
                  )}
                </div>
              </div>
            </section>

            <section className="ttm-row grid-section ttm-bgcolor-grey clearfix">
              <div className="container-xl">
                {/* row */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Consultancy <span>Services</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
                <div className="row ">
                  {this.dynamicData.ConsultancyServices === undefined ? (
                    <NoData />
                  ) : (
                    this.dynamicData.ConsultancyServices.length &&
                    this.dynamicData.ConsultancyServices.map(function (
                      item,
                      index
                    ) {
                      return (
                        <div className="col-lg-4 col-md-6 d-flex" key={index}>
                          {/* featured-imagebox-blog */}
                          <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                            <div className="featured-thumbnail">
                              {/* featured-thumbnail */}
                              <img
                                className="img-fluid"
                                src={item.Image}
                                alt=""
                              />
                            </div>
                            <div className="featured-content">
                              {/* featured-content */}

                              <div className="featured-title">
                                {/* featured-title */}
                                <h5>{item.Service}</h5>
                                <h6>Services Offered:</h6><p>{item.ServiceOffered}</p>
                              </div>
                            </div>
                          </div>
                          {/* featured-imagebox-blog end */}
                        </div>
                      );
                    })
                  )}
                </div>
                {/* row end */}
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default ProResearch;
