import React, { Component } from "react";
import Header from "../../layout/Header";
import image1 from "../../../images/Bestpractices/NSS/NSS1.jpg";
import image2 from "../../../images/Bestpractices/NSS/NSS2.jpg";
import image3 from "../../../images/Bestpractices/NSS/NSS3.jpg";
import image4 from "../../../images/Bestpractices/NSS/NSS4.jpg";
import image5 from "../../../images/Bestpractices/NSS/NSS5.jpg";

export class NSS extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row bestpractices-banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading  card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      NSS
                    </h4>
                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>VSDCH</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.page-title-captions */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        {/* aboutus-section */}
        <section className="ttm-row aboutus-section-style3 clearfix spacing">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-6">
                <div className="position-relative">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center mr-35 res-991-m-0">
                    <img
                      className="img-fluid"
                      src={image1}
                      title="single-img-nine"
                      alt="single-img-nine"
                    />
                  </div>
                  {/* ttm_single_image-wrapper end */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="res-991-mt-30">
                  {/* section title */}
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">Vision</h2>
                    </div>
                    <div className="title-desc">
                      <p>
                        The vision of National Service Scheme is to build the
                        youth with the mind and spirit to serve the society and
                        work for the social uplift of the downtrodden masses of
                        our nation as a movement.
                      </p>
                    </div>
                    <div className="title-header">
                      <h2 className="title">Mision</h2>
                    </div>
                    <div className="title-desc">
                      <ul>
                        <li>
                          To enable the development of total personality through
                          co-curricular and extra curricular activities.To
                          enable the development of total personality through
                          co-curricular and extra curricular activities.
                        </li>
                        <li>
                          To inculcate human activities like mutual love, truth
                          and nonviolence.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* section title end */}
                  {/* row */}
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* aboutus-section end */}
        {/* zero-padding-section */}
        <section className="ttm-row  res-991-p-0 mt_80 res-991-mt-0 clearfix">
          <div className="container-xl">
            <div className="row no-gutters">
              <div className="col-lg-6">
                {/* col-bg-img-one */}
                <div className="col-bg-img-nss ttm-bg ttm-col-bgimage-yes ttm-left-span res-991-h-auto ">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div className="ttm-bg-layer-inner" />
                  </div>
                </div>
                {/* Responsive View image */}
                <img
                  src={image2}
                  className="img-fluid ttm-equal-height-image"
                  alt="col-bgimage-3"
                />
              </div>
              <div className="col-lg-6 z-9">
                <div className="ttm-bgcolor-skincolor ttm-textcolor-white position-relative ttm-right-span">
                  <div className="spacing-1">
                    {/* section title */}
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h2 className="title">
                          Objectives{" "}
                          <span className="ttm-textcolor-white"></span>
                        </h2>
                      </div>
                    </div>
                    {/* section title end */}
                    <div className="row mt-40">
                      <div className="col-12">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box style2 left-icon icon-align-top">
                          <div className="featured-icon">
                            {/* featured-icon */}
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              {/* featured title */}
                              <p>
                                {" "}
                                <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor header" />{" "}
                                Understand the community in which they work
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-12">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box style2 left-icon icon-align-top">
                          <div className="featured-content">
                            <div className="featured-title">
                              {/* featured title */}
                              <p>
                                <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor header" />{" "}
                                Identify the needs and problems in the community
                                and apply their education in tending practical
                                solution to individual and community problem
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-12">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box style2 left-icon icon-align-top">
                          <div className="featured-content">
                            <div className="featured-title">
                              {/* featured title */}
                              <p>
                                {" "}
                                <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor header" />{" "}
                                Acquire leadership qualities and democratic
                                attitude.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-12">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box style2 left-icon icon-align-top">
                          <div className="featured-content">
                            <div className="featured-title">
                              {/* featured title */}
                              <p>
                                <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor header" />{" "}
                                Gain skills in mobilising community
                                participation and develop capacity to meet
                                emergencies and natural disasters.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* zero-padding-section end */}
        {/* team-section */}
        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-6">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      NSS UNIT OF <span>VSDCH</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
                <div className="row no-gutters mt-4 mb-4">
                  <p>
                    Dr.VIJAYALAKSHMI.B, NSS PROGRAM OFFICER has conducted
                    various NSS activities like regular oral health
                    screening,free dental health camps for rural community,blood
                    donations camps, various awareness programmes through skits,
                    role play, street play and celebration of national holidays.
                  </p>
                </div>
                {/* row END*/}
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image3}
                      title="single-img-seven"
                      alt="single-img-seven"
                    />
                  </div>
                  {/* ttm_single_image-wrapper end */}
                </div>
              </div>
            </div>
            {/* row end */}
            <section className="ttm-row aboutus-section clearfix">
              <div className="container-xl">
                <div className="row">
                  {/* row */}
                  <div className="col-lg-6">
                    <div className="position-relative res-991-mt-30">
                      {/* ttm_single_image-wrapper */}
                      <div className="ttm_single_image-wrapper text-sm-center">
                        <img
                          className="img-fluid"
                          src={image4}
                          title="single-img-seven"
                          alt="single-img-seven"
                          style={{ height: "405px", width: "540px" }}
                        />
                      </div>
                      {/* ttm_single_image-wrapper end */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="position-relative res-991-mt-30">
                      {/* ttm_single_image-wrapper */}
                      <div className="ttm_single_image-wrapper text-sm-center">
                        <img
                          className="img-fluid"
                          src={image5}
                          title="single-img-seven"
                          alt="single-img-seven"
                        />
                      </div>
                      {/* ttm_single_image-wrapper end */}
                    </div>
                  </div>
                </div>
                {/* row end */}
              </div>
            </section>
          </div>
        </section>
      </div>
    );
  }
}

export default NSS;
