import React, { Component } from "react";
import OralMedicineSidebar from "./OralMedicineSidebar";
import Slider from "react-slick";
import Header from "../../../layout/Header";
import pdf from "../../../../assets/OralMedicineRadiologyBDS.pdf";
import pdf1 from "../../../../assets/OralMedicineRadiologyBDS2.pdf";
import docx from "../../../../assets/OralMedicineRadiologyMDS.docx";
import image1 from "../../../../images/OralMedicinedept/deptimage1.JPG";
import image2 from "../../../../images/OralMedicinedept/deptimage2.jpg";
import image3 from "../../../../images/OralMedicinedept/deptimage3.JPG";
import image4 from "../../../../images/OralMedicinedept/deptimage4.JPG";
import firestore from "../../../../Config/Config";
import { collection, doc, onSnapshot } from "firebase/firestore";
import News from "./News";
import NoData from "../../../pages/NoData";

export class OralMedicineRadiology extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Achievers: [],
      Notifications: [],
    };

    this.state = {
      // photoIndex: 0,
      // isOpen: false,
      Notifications: [],
      Achievers: [],
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
          // arr[key] = docData[key];
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  //Load Data From Here
  async componentDidMount() {
    const path = collection(firestore, "OralMedicine");
    const achieverskey = "Achievers";

    onSnapshot(doc(firestore, "OralMedicine", "Achievers"), (doc) => {
      if (doc.exists()) {
        Object.entries(doc.data()).forEach(([key, value]) => {
          this.dynamicData.Achievers.push(Object.assign({ Id: key }, value));
        });
        this.setState({
          Achievers: this.dynamicData.Achievers,
        });
      }
    });
  }

  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row oralmedicine_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Oral Medicine Radiology
                    </h4>

                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">
                        Oral Medicine Radiology
                      </span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
            </div>
            {/* /.page-title-captions */}
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        About <span>Department</span>
                      </h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="ttm_single_image-wrapper mb-35">
                    <img
                      className="d-block w-100"
                      src={image1}
                      alt="First slide"
                    />
                  </div>
                  <div className="ttm-service-description">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header">
                        <h2 className="title">
                          Oral Medicine <span>Radiology</span>{" "}
                        </h2>
                      </div>
                    </div>
                    {/* section title end */}
                    <p className="text-justify">
                      It is one of the specialty of dentistry where the students
                      are taught in a systematic way to approach the patient's
                      problems, thereby they should be able to diagnose dental
                      caries and its complications , mucosal diseases including
                      local and systematic causes, bone and joint disorders and
                      systemic diseases manifesting in the oral cavity. The
                      students should be able to treat orofacial pain, mucosal
                      lesions, temporamandibular disorders and salivary gland
                      diseases. Students are taught radiology where they gain
                      knowledge about the basics of radiation, its uses, hazards
                      and protection. Students are also made to understand about
                      the advanced imaging modalities like ultrasound, computed
                      tomography(CT), magnetic resonance imaging(MRI) and
                      positron emission tomography (PET-Scan) so that they are
                      able to write appropriate investigations and also
                      interpret the results.
                    </p>

                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Vision</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <p className="mb-35 res-991-mb-20">
                          Vision of the department is to become the recognized
                          leader in the field of teaching, research, serving
                          patients to improve quality of oral health care
                          system.
                        </p>
                      </div>
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <img
                            className="vision-block"
                            src={image2}
                            alt="First slide"
                          />
                        </div>
                      </div>
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
                          <img
                            className="vision-block"
                            src={image3}
                            alt="First slide"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Mission</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <p className="mb-35 res-991-mb-20">
                          Improve access to services Identify social,
                          Economical, environmental and emotional determinants
                          for treatment planning Improve quality of patient care
                          Shape and advance the scientific base in OMR by
                          fostering research
                        </p>
                      </div>
                    </div>
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Objectives</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <ul className="ttm-list ttm-list-style-icon">
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              To promote and provide excellence in education,
                              diagnosis, Research and patient care in the field
                              of oral medicine and radiolog
                            </span>
                          </li>
                          <li>
                            {" "}
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              To increase public and professional awareness in
                              the field of oral medicine, Radiology including
                              oral oncology Provide the society with the skilled
                              and knowledgeable professionals Who are able to
                              act as change agents, Problem solvers and
                              facilitators within the OMR fraternity{" "}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              To emphasize the importance of intimate
                              relationship Between oral health and general
                              health Ensure human values, ethical practice and
                              communication abilities
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <img
                            className="vision-block"
                            src={image4}
                            alt="First slide"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              <div className="col-lg-3 widget-area">
                <OralMedicineSidebar />
              </div>
            </div>
            {/* row end */}
          </div>
          <section className="  ttm-bgcolor-grey ttm-bg ttm-bgimage-yes bg-img14 clearfix">
            <div className="container-xl">
              <div className="row align-items-center">
                {/* row */}
                <div className="col-lg-9 col-xl-9 col-md-9">
                  {/* section-title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        {" "}
                        Course<span> Offered</span>
                      </h2>
                    </div>
                  </div>
                  {/* section-title end */}
                </div>
              </div>
              <Slider
                className="position-relative z-1 row slick_slider ttm-boxes-spacing-30px"
                {...slick_slider}
                slidesToShow={2}
              >
                <div className="ttm-box-col-wrapper">
                  {/* featured-imagebox-services */}
                  <div className="featured-imagebox featured-imagebox-services style1">
                    <div className="featured-thumbnail">
                      {/* featured-thumbnail */}
                      <img className="img-fluid" src={image1} alt="image" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        {/* featured-title */}
                        <h5>BDS</h5>
                      </div>

                      <a
                        href={pdf}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      >
                        download Syllabus
                      </a>
                    </div>
                  </div>
                  {/* featured-imagebox-services */}
                </div>
                <div className="ttm-box-col-wrapper">
                  {/* featured-imagebox-services */}
                  <div className="featured-imagebox featured-imagebox-services style1">
                    <div className="featured-thumbnail">
                      {/* featured-thumbnail */}
                      <img className="img-fluid" src={image3} alt="image" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        {/* featured-title */}
                        <h5>MDS</h5>
                      </div>

                      <a
                        href={docx}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      >
                        Download Syllabus
                      </a>
                    </div>
                  </div>
                  {/* featured-imagebox-services */}
                </div>
              </Slider>
              <div
                className="ttm-row tab-section clearfix"
                style={{ paddingBlockEnd: "10px" }}
              >
                <div className="container-xl">
                  <div className="row">
                    <div className="col-12">
                      <div className="section-title with-desc text-left clearfix zero-padding-section">
                        <div className="title-header">
                          <h2 className="title">Achievers</h2>
                        </div>
                      </div>
                      {/* section title end */}
                      <div className="ttm-tabs text-center ttm-tab-style-classic style2">
                        <div className="content-tab">
                          <div className="row multi-columns-row ttm-boxes-spacing-5px">
                            {this.state.Achievers === undefined ? (
                              <NoData />
                            ) : (
                              this.state.Achievers.length &&
                              this.state.Achievers.map(function (item, index) {
                                return (
                                  <div
                                    className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6"
                                    key={index}
                                  >
                                    {/* featured-imagebox */}
                                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                                      {/* featured-thumbnail */}
                                      <div className="featured-thumbnail">
                                        <img
                                          className="img-fluid"
                                          src={item.Image}
                                          alt="image"
                                          style={{
                                            width: "360px",
                                            height: "390px",
                                          }}
                                        />
                                      </div>
                                      {/* featured-thumbnail end*/}
                                      <div className="featured-content">
                                        <div className="category">
                                          <p>Name : {item.Name}</p>
                                        </div>
                                        <div className="category">
                                          <p>
                                            Achievements : {item.Achievements}
                                          </p>
                                        </div>
                                        <div className="category">
                                          <p>
                                            Description : {item.Description}
                                          </p>
                                        </div>
                                        <div className="category">
                                          <p>Year : {item.Year}</p>
                                        </div>
                                      </div>
                                    </div>
                                    {/* featured-imagebox */}
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <News />
        </div>
      </div>
    );
  }
}

export default OralMedicineRadiology;
