import React, { Component } from "react";
import Header from "../layout/Header";
import NoData from "../pages/NoData";
import Loading from '../../components/common/Loading';
import firestore from "../../Config/Config";
import { doc, getDoc } from "firebase/firestore";

export class Administration extends Component {

  state = { Datas: [] }

  //Load Data Here
  async componentDidMount() {
    const docRef = doc(firestore, "Explore", "AdministrationVSDC");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
      });
      console.log(Data.sort(function (a, b) { return a.Priority - b.Priority; }));
      Data.length === 0 ?
        this.setState({ Datas: "NO_999" }) :
        this.setState({ Datas: Data })
    } else {
      this.setState({
        Datas: "NO_999"
      })
    }
  }

  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row administrationbanner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>Administration VSDC</h4>
                    <div className=" ttm-color-home">
                      <span className="ttm-color-home">
                        <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">VSDC</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="ttm-row grid-section clearfix">
          <div className="container-xl">
            <div className="section-title with-desc text-left clearfix">
              <div className="title-header">
                <h2 className="title">Administration <span> VSDCH</span></h2>
              </div>
            </div>
            <div className="row">
              {
                this.state.Datas.length === 0 ? <Loading /> :
                  this.state.Datas === "NO_999" ? <NoData /> :
                    this.state.Datas.map((data, i) => (
                      <div className="col-md-6 col-lg-4 col-sm-6" Key={i}>
                        <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                          <div className="featured-thumbnail"><img className="img-fluid" src={data.Image} alt="image" /></div>
                          <div className="featured-content box-shadow">
                            <div className="featured-title"><h5>{data.Name}</h5></div>
                            <p className="category">{data.Designation}</p>
                            <p className="category"><small>{data.Role}</small></p>
                          </div>
                        </div>
                      </div>
                    ))
              }
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Administration;
