import React, { Component } from "react";
import firestore from "../../../../Config/Config";
import { collection, getDocs, doc, onSnapshot } from "firebase/firestore";
import NoData from "../../../pages/NoData";

export default class PaedoNews extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Notifications: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    onSnapshot(doc(firestore, "Paedodontics", "Notifications"), (doc) => {
      if (doc.exists()) {
        console.log("doc", doc);
        console.log("docData", doc.data());
        Object.entries(doc.data()).forEach(([key, value]) => {
          this.dynamicData.Notifications.push(
            Object.assign({ Id: key }, value)
          );
        });
        console.log("NOTIFICATIONS");

        console.log(this.dynamicData.Notifications);
        this.setState({
          Notifications: this.dynamicData.Notifications,
        });
      } else {
        // this.setState({
        //     Data: []
        // })
      }
    });
  }

  render() {
    return (
      <div>
        <div className="container-xl">
          {/* row */}
          <div className="row">
            <div className="col-lg-12 content-area">
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  <h2 className="title">
                    News and <span>Notifications </span>
                  </h2>
                </div>
              </div>
              <div className=" row ">
                {this.dynamicData.Notifications == 0 ? (
                  <NoData />
                ) : (
                  this.dynamicData.Notifications.length &&
                  this.dynamicData.Notifications.map(function (item, index) {
                    return (
                      <div
                        className="col-lg-6  box-shadownews "
                        key={index}
                        style={{ padding: "0.5rem" }}
                      >
                        <div className="text-center spacing-11">
                          <h6>{item.Header}</h6>
                         
                          {item.Description}
                          <br />
                        <a href={item.Content} target="_blank" style={{textDecoration: "underline",color:"#3b6eda"}}>{item.Content}</a>
                          <br />
                          <p>
                            <i
                              class="fas fa-calendar-alt"
                              style={{ color: "rgb(67, 67, 122)" }}
                            />{" "}
                            {item.Date}
                          </p>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </div>
    );
  }
}
