import React, { Component } from "react";
import image from "../../../../images/OMpathology/HOD.jpg";
import { NavLink } from "react-router-dom";

export class OralMaxilSidebar extends Component {
  render() {
    return (
      <div>
        <div className="widget widget-nav-menu">
          <ul className="widget-menu">
            <li><NavLink to={process.env.PUBLIC_URL + "/OralMaxilPathology"}>About</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/PathologyFaculties"}>Faculties and Staff</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/PathologyTeaching"}>Teaching - Learning</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/PathologyResearch"}>Research and Innovations</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/PathologyActivities"}>Activities</NavLink></li>
          </ul>
        </div>

        <div className="widget ">
          <div className="section-title with-desc text-left clearfix">
            <div className="title-header">
              <h2 className="title">Head of the <span>department</span></h2>
            </div>
          </div>
          <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
            <img className="d-block w-100" src={image} alt="img" />
          </div>

          <h5 style={{ color: "black" }}>Dr Priya N S</h5>
          <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
            <li><i class="fas fa-star"/>Experience : UG: 16 YEARS PG: 12 YEARS</li>
            <li><i class="fas fa-star"/>Qualification : MDS</li>
          </ul>
        </div>
        
        <div className="widget widget-download">
          <h3 className="widget-title">Contact</h3>
          <ul className="download">
            <li>
              <i className="fa fa-phone" />
              <a> 080-22426705</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default OralMaxilSidebar;
