import React, { Component } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdbreact";
import firestore from "../../../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import NoData from "../../../pages/NoData";

export default class SurgeryAchievements extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Achievements: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "OMFS");
    const achievementskey = "Achievements";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      console.log(Snapshot);
      const allDynamicData = this.getDynamicData(Snapshot);
      console.log(allDynamicData);
      this.dynamicData.Achievements = allDynamicData[achievementskey];

      console.log("test data");

      console.log(this.dynamicData.Achievements);
    }
    this.setState({});
  }

  render() {
    return (
      <div>
        <MDBRow>
          {this.dynamicData.Achievements === undefined ? (
            <NoData />
          ) : (
            this.dynamicData.Achievements.length &&
            this.dynamicData.Achievements.map(function (item, index) {
              return (
                <MDBCol
                  md="4"
                  key={index}
                  style={{ paddingTop: "10px" }}
                  className="d-flex"
                >
                  <MDBCard cascade>
                    <MDBCardBody cascade>
                      <MDBCardTitle> {item.Name}</MDBCardTitle>
                      <hr />
                      <MDBCardText>
                        <p>
                          <i
                            class="fab fa-buffer"
                            style={{ color: "rgb(67, 67, 122)" }}
                          />{" "}
                          {"  "}Category:{item.Category}
                        </p>
                        <p>
                          <i
                            class="fab fa-artstation"
                            style={{ color: "rgb(67, 67, 122)" }}
                          ></i>{" "}
                          Programs :{item.Programs}
                        </p>
                        <p>
                          {" "}
                          <i
                            class="fas fa-trophy"
                            style={{ color: "rgb(67, 67, 122)" }}
                          ></i>{" "}
                          {item.Prize}{" "}
                        </p>
                      </MDBCardText>
                    </MDBCardBody>
                    <div className="rounded-bottom mdb-color lighten-3 text-center pt-3">
                      <ul className="list-unstyled list-inline font-small">
                        <li className="list-inline-item pr-2 white-text">
                          <i
                            class="fas fa-calculator"
                            style={{ color: "rgb(67, 67, 122)" }}
                          ></i>{" "}
                          {""}
                          {item.Year}
                        </li>
                      </ul>
                    </div>
                  </MDBCard>
                </MDBCol>
              );
            })
          )}
        </MDBRow>
      </div>
    );
  }
}
