import React, { Component } from "react";
import Header from "../layout/Header";
import pdf from "../../assets/ServiceRules.pdf";
import pdf1 from "../../assets/VsdchengRules.pdf";

export class Rules extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row rules-banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{color:"white"}}>Rules and Regulations</h4>
                  {/* /.page-title-captions */}
                  <div className="ttm-color-home">
                    <span className="ttm-color-home">
                      <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Home
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span className="ttm-color-home">VSDCH Rules</span>
                  </div>
                </div>
              </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}

        <section className="ttm-row service-section-style2 res-991-pb-0 ttm-bgcolor-grey clearfix">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-12">
                {/* section title */}
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      VSDCH <span>Rules</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            <div className="row no-gutters">
              {/* row */}
              <div className="col-md-6 col-lg-3">
                {/*  featured-icon-box */}
                <div className="featured-icon-box style6">
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>Service Rules</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p className="text-justify">
                        These Rules shall apply to all employees serving in
                        connection with the affairs of Vokkaligara Sangha in
                        respect of whose conditions of service the Sangha is
                        competent to make rules.
                      </p>
                    </div>
                  </div>
                  <a
                      href={pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      style={{ position: "absolute", bottom: "10px" }}
                    >
                      download pdf
                    </a>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div className="col-md-6 col-lg-3">
                {/*  featured-icon-box */}
                <div className="featured-icon-box style6">
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>C and R Rules</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p className="text-justify">
                        The Draft of the Vokkaligara Sangha Dental College and
                        Hospital(Cadre & Recruitment) Rules,2019, which the
                        Administrator of the Rajya Vokkaligara Sangha,appointed
                        by the State Government Under Section (27A) of Karnataka
                        Society Registeration Act.
                      </p>
                    </div>
                  </div>

                  <a
                      href={pdf1}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      style={{ position: "absolute", bottom: "10px" }}
                    >
                      download pdf
                    </a>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div className="col-md-6 col-lg-3">
                {/*  featured-icon-box */}
                <div className="featured-icon-box style6">
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>DCI Rules and Regulations</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p className="text-justify">
                        Expertise in a IT consultancy for the many companies,
                        for their different working areas.
                      </p>
                    </div>
                  </div>
                  <a
                      href={pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      style={{ position: "absolute", bottom: "10px" }}
                    >
                      download pdf
                    </a>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div className="col-md-6 col-lg-3">
                {/*  featured-icon-box */}
                <div className="featured-icon-box style6">
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>Rajiv Gandhi University of Health Sciences(RGUHS) Curriculum</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p className="text-justify">
                        We develop, migrate &amp; work on applications to ensure
                        that run capably on cloud.
                      </p>
                    </div>
                  </div>
                  <a
                      href={pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      style={{ position: "absolute", bottom: "10px" }}
                    >
                      download pdf
                    </a>
                </div>
                {/*  featured-icon-box END */}
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
      </div>
    );
  }
}

export default Rules;
