import React, { Component } from "react";
import Header from "../layout/Header";
import pdf3 from "../../assets/Governmentofkarnataka.pdf";
import pdf4 from "../../assets/BBMP.pdf";
import pdf1 from "../../assets/DCI.pdf";
import pdf2 from "../../assets/RGUHS.pdf";

export class Accreditation extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row accreditationbanner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading  card_box">
                    <h4 className="title" style={{ color: "white" }}>Accreditation and Approvals</h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home"><a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}><i className="ti ti-home" />&nbsp;&nbsp;Home</a></span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">VSDC </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="ttm-row service-section-style2 res-991-pb-0 ttm-bgcolor-grey clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title">Accreditation &amp; <span>Approvals</span></h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-md-6 col-lg-3">
                <div className="featured-icon-box style6">
                  <div className="featured-content">
                    <div className="featured-title"><h5>Dental Council of India (Statutory Body)</h5></div>
                    <div className="featured-desc"><p>Recognition of BDS & MDS degrees by Rajiv Gandhi University of Health Sciences, Karnataka</p></div>
                  </div>
                  <a className=" btn-inline style2 ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white" href={pdf1} target="_blank" rel="noopener noreferrer" download style={{ position: "absolute", bottom: "10px" }}>Download pdf</a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="featured-icon-box style6">
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Rajiv Gandhi University of Health Sciences</h5>
                    </div>
                    <div className="featured-desc">
                      <p>RGUHS Grants Continuation of affiliation as per section 45 of RGUHS act 1994.</p>
                    </div>
                  </div>
                  <a
                    className="ttm-btn btn-inline style2 ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                    href={pdf2}
                    re="noopener noreferrer"
                    download
                    style={{ position: "absolute", bottom: "10px" }}
                  >
                    Download Pdf
                  </a>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div className="col-md-6 col-lg-3">
                {/*  featured-icon-box */}
                <div className="featured-icon-box style6">
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>Government of Karnataka</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p>
                        Government of Karnataka have issued the Essentiality
                        Certificate and Feasability Certificate .
                      </p>
                    </div>
                  </div>
                  <a
                    className="ttm-btn btn-inline style2 ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                    href={pdf3}
                    re="noopener noreferrer"
                    download
                    style={{ position: "absolute", bottom: "10px" }}
                  >
                    Download Pdf
                  </a>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div className="col-md-6 col-lg-3">
                {/*  featured-icon-box */}
                <div className="featured-icon-box style6">
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>Shanthinagar Satellite Centre - BBMP MOU</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p>Urban satellite centre provides oral health services and referrals to the underprivileged neighborhood.</p>
                    </div>
                  </div>
                  <a
                    className="ttm-btn btn-inline style2 ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                    href={pdf4}
                    re="noopener noreferrer"
                    download
                    style={{ position: "absolute", bottom: "10px" }}
                  >
                    Download Pdf
                  </a>
                </div>
                {/*  featured-icon-box END */}
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
      </div>
    );
  }
}

export default Accreditation;
