import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../../layout/Header";
import firestore from "../../../../Config/Config";
import { doc, onSnapshot } from "firebase/firestore";
import PaedodonticsSidebar from "./PaedodonticsSidebar";
import NoData from "../../../pages/NoData";

export class PaedodonticsFaculties extends Component {

  constructor(props) {
    super(props);

    this.dynamicData = {
      Faculties: [],
      TechnicalStaff: [],
    };

    this.state = {
      Faculties: [],
      TechnicalStaff: [],
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    onSnapshot(doc(firestore, "Paedodontics", "Faculties"), (doc) => {
      if (doc.exists()) {
        Object.entries(doc.data()).forEach(([key, value]) => {
          this.dynamicData.Faculties.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value));
        });
        this.setState({
          Faculties: this.dynamicData.Faculties.sort(function (a, b) { return a.Priority - b.Priority; }),
        });
      }
    });

    onSnapshot(doc(firestore, "Paedodontics", "TechnicalStaff"), (doc) => {
      if (doc.exists()) {
        Object.entries(doc.data()).forEach(([key, value]) => {
          this.dynamicData.TechnicalStaff.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value));
        });
        this.setState({
          TechnicalStaff: this.dynamicData.TechnicalStaff.sort(function (a, b) { return a.Priority - b.Priority; }),
        });
      } 
    });

  }
  
  render() {
    return (
      <div className="site-main">
        <Header />

        {/* page-title */}
        <div className="ttm-page-title-row paedodontics_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Faculties and Staff
                    </h4>

                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">
                        Paedodontics and Preventive Dentistry
                      </span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
            </div>
            {/* /.page-title-captions */}
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}

        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <section className="ttm-row grid-section clearfix">
                  <div className="container-xl">
                    {/* row */}
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header">
                        <h2 className="title">
                          Teaching <span>Faculty</span>
                        </h2>
                      </div>
                    </div>
                    {/* section title end */}
                    <div className="row">
                      {this.state.Faculties === undefined ? (
                        <NoData />
                      ) : (
                        this.state.Faculties.length &&
                        this.state.Faculties.map(function (item, index) {
                          return (
                            <div
                              className="col-md-6 col-lg-4 col-sm-6"
                              key={index}
                            >
                              {/* featured-imagebox-team */}
                              <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                                <div className="featured-thumbnail">
                                  {/* featured-thumbnail */}
                                  <img
                                    className="img-fluid d-flex"
                                    src={item.Image}
                                    alt="Paedo-image2"
                                    style={{
                                      width: "233px",
                                      height: "217px",
                                    }}
                                  />
                                </div>
                                <div className="featured-content box-shadow">
                                  <div className="featured-title">
                                    {/* featured-title */}
                                    {/* {{const facIditem.Id}} */}
                                    <h5>
                                      <Link
                                        to={{
                                          pathname:
                                            process.env.PUBLIC_URL +
                                            `/Faculties/${item.Id}/Paedodontics`,
                                          state: { facData: item },
                                        }}
                                      >
                                        {" "}
                                        {item.Name}
                                      </Link>
                                    </h5>
                                  </div>
                                  <p className="category">{item.Designation}</p>
                                  {/* category */}
                                </div>
                              </div>
                              {/* featured-imagebox-team end*/}
                            </div>
                          );
                        })
                      )}
                    </div>

                    {/* row end*/}
                  </div>
                </section>

                <section className=" grid-section clearfix">
                  <div className="container-xl">
                    {/* row */}
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header">
                        <h2 className="title">
                          Non - Teaching <span>Staff</span>
                        </h2>
                      </div>
                    </div>
                    {/* section title end */}
                    <div className="row">
                      {this.state.TechnicalStaff === undefined ? (
                        <NoData />
                      ) : (
                        this.state.TechnicalStaff.length &&
                        this.state.TechnicalStaff.map(function (item, index) {
                          return (
                            <div
                              className="col-md-6 col-lg-4 col-sm-6"
                              key={index}
                            >
                              {/* featured-imagebox-team */}
                              <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                                <div className="featured-thumbnail">
                                  {/* featured-thumbnail */}
                                  <img
                                    className="img-fluid d-flex"
                                    src={item.Image}
                                    alt="Paedo-fac"
                                    style={{
                                      width: "233px",
                                      height: "217px",
                                    }}
                                  />
                                </div>
                                <div className="featured-content box-shadow">
                                  <div className="featured-title">
                                    {/* featured-title */}
                                    <h5>
                                      <Link
                                        to={{
                                          pathname:
                                            process.env.PUBLIC_URL +
                                            `/TechStaff/${item.Id}/Paedodontics`,
                                          state: { StaffData: item },
                                        }}
                                      >
                                        {" "}
                                        {item.Name}
                                      </Link>
                                    </h5>
                                  </div>
                                  <p className="category">{item.Designation}</p>
                                  {/* category */}
                                </div>
                              </div>
                              {/* featured-imagebox-team end*/}
                            </div>
                          );
                        })
                      )}
                    </div>

                    {/* row end*/}
                  </div>
                </section>
              </div>

              <div className="col-lg-3 widget-area">
                <PaedodonticsSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaedodonticsFaculties;
