import React, { Component } from 'react';
import Header from '../../layout/Header';
import image from "../../../images/Bestpractices/TCc.jpg"

export class TobaccoCessationCentre extends Component {
    render() {
         return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row bestpractices-banner">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading  card_box">
                            <h4 className="title" style={{color:"white"}}>Tobacco Cessation Centre</h4>
                            <div className="ttm-color-home">
                            <span>
                                <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>VSDCH</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}
                        </div>{/* /.page-title-captions */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                     
                </div>
                {/* page-title end*/}
                {/* about-services-section */}
                <section className="ttm-row about-services-section clearfix">
                    <div className="container-xl">
                    <div className="row align-items-center">{/* row */}
                        <div className="col-lg-6">
                        <div className="position-relative">
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper text-sm-center">
                            <img className="img-fluid" src={image} title="single-img-ten" alt="single-img-ten" />
                            </div>{/* ttm_single_image-wrapper end */}
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="res-991-mt-30">
                            {/* section title */}
                            <div className="section-title with-desc clearfix">
                            <div className="title-header">
                                <h2 className="title">Tobacco Cessation <span>Centre</span></h2>
                            </div>
                            <div className="title-desc">
                                <p>Department of Public Health Dentistry, VSDCH has established a Tobacco Cessation Centre since 2011 as per Operational Guidelines given by DCI. 
Behavioral Health Service Programs include:
</p>
                            </div>
                            </div>{/* section title end */}
                            {/* row */}
                            <div className="row mt-25">
                            <div className="col-12">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box iconalign-before-heading">
                                <div className="featured-content">{/* featured-content */}
                                    <div className="ttm-icon ttm-bgcolor-grey style1">
                                    <i className="ti ti-info ttm-num" />
                                    </div>
                                    <div className="featured-title">{/* featured-title */}
                                    <h5> Counseling patients to stop use of tobacco</h5>
                                    </div>
                                </div>
                                
                                </div>{/* featured-icon-box */}
                            </div>
                            </div>
                            {/* separator */}
                            <div className="separator">
                            <div className="sep-line mt-4 mb-3" />
                            </div>
                            {/* separator */}
                            <div className="row">
                            <div className="col-12">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box iconalign-before-heading">
                                <div className="featured-content">{/* featured-content */}
                                    <div className="ttm-icon ttm-bgcolor-grey style1">
                                    <i className="ti ti-info ttm-num" />
                                    </div>
                                    <div className="featured-title">{/* featured-title */}
                                    <h5>Support them in the development of skills to maintain oral health
</h5>
                                    </div>
                                </div>
                                
                                </div>{/* featured-icon-box */}
                            </div>
                            </div>
                             {/* separator */}
                             <div className="separator">
                            <div className="sep-line mt-4 mb-3" />
                            </div>
                            {/* separator */}
                            <div className="row">
                            <div className="col-12">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box iconalign-before-heading">
                                <div className="featured-content">{/* featured-content */}
                                    <div className="ttm-icon ttm-bgcolor-grey style1">
                                    <i className="ti ti-info ttm-num" />
                                    </div>
                                    <div className="featured-title">{/* featured-title */}
                                    <h5>  To increase their oral health knowledge
</h5>
                                    </div>
                                </div>
                                
                                </div>{/* featured-icon-box */}
                            </div>
                            </div>
                             {/* separator */}
                             <div className="separator">
                            <div className="sep-line mt-4 mb-3" />
                            </div>
                            {/* separator */}
                            <div className="row">
                            <div className="col-12">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box iconalign-before-heading">
                                <div className="featured-content">{/* featured-content */}
                                    <div className="ttm-icon ttm-bgcolor-grey style1">
                                    <i className="ti ti-info ttm-num" />
                                    </div>
                                    <div className="featured-title">{/* featured-title */}
                                    <h5>   Referral to NIMHANS for Pharmacological assistance</h5>
                                    </div>
                                </div>
                                
                                </div>{/* featured-icon-box */}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* row end */}
                    </div>
                </section>
            </div>
            )
    }
}


export default TobaccoCessationCentre;