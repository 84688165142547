import React, { Component } from "react";
import { Link } from "react-router-dom";
import pdf from "./../../assets/css.pdf";
import image from "../../images/RVSlogo.png";
import image1 from "../../images/footerimg.jpg";
import styles from "./footer.module.css";

export class Footer extends Component {
  render() {
    return (
      <footer className="footer widget-footer clearfix">
        {this.props.children}
        <div
          className={`first-footer ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes prospectusBacImg ${styles.prospectusBacImg}`}
        >
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row align-items-md-center">
              <div className="col-lg-4 col-md-4 col-sm-12 order-md-2">
                <div className="footer-logo text-sm-center">
                  {/* <img
                    data-src="images/footer-logo.png"
                    className="img-fluid"
                    alt="footer-logo"
                    src="images/footer-logo.png"
                  /> */}
                  VOKKALIGARA SANGHA DENTAL COLLEGE
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 order-md-1 res-767-mt-20">
                <div className="text-left">
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box left-icon icon-align-top">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                        <i className="ti ti-location-pin" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                    <div className="featured-content">
                      {/*  featured-content */}
                      <div className="featured-desc">
                        <p>
                          Vokkaligara Sangha Dental College & Hospital K.R.Road,
                          V.V.Puram, Bengaluru, Karnataka - 560004, India.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/*  featured-icon-box END */}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 order-md-3 res-767-mt-20">
                <div className="text-sm-right">
                  <a
                    href={pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"
                  >
                    <i className="far fa-envelope" /> Prospectus
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="second-footer ttm-textcolor-white footer-img-one">
          <div className="container-xl">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                <div className="widget widget_text  clearfix">
                  <h3 className="widget-title">About Our Institution</h3>
                  <div className="textwidget widget-text ">
                    V.S. Dental College and Hospital is one of the most
                    sought-after Institutions in India for undergraduate and
                    postgraduate admissions which offers state of the art
                    clinical and community training facilities. The teaching
                    hospital is the hub of excellent oral healthcare services
                    for the patients and a renowned centre for learning
                    Dentistry with care.
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                <div className="widget link-widget clearfix">
                  <h3 className="widget-title">Quick Links</h3>
                  <ul id="menu-footer-services">
                    <li>
                      <a href="http://rvsangha.org/">
                        Rajya Vokkaligara Sangha{" "}
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a>Anti-Ragging Committee</a>
                    </li>

                    <li>
                      <a href="/#Course">BDS Courses , MDS Courses</a>
                    </li>
                    <li>
                      <a href="/#Course">PhD Courses</a>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/Admission"}>
                        Admission
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/Department"}>
                        Department
                      </Link>
                    </li>

                    <li>
                      <Link to={process.env.PUBLIC_URL + "/Rules"}>
                        Rules and Regulation{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/Accreditation"}>
                        Accreditation and Approvals
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area res-991-mb-40 res-767-mb-0">
                <div className="widget widget_text  clearfix">
                  <div style={{ margin: "30px 0px 0px 0px" }}>
                    <h5>Follow Us On</h5>
                    <div className="social-icons circle social-hover">
                      <ul className="list-inline">
                        <li className="social-facebook">
                          <a className="tooltip-top rounded-circle" 
                            href="https://www.facebook.com/Vokkaligara-Sangha-Dental-College-Hospital-Bengaluru-100355139067559" target="_blank"
                            rel="noopener noreferrer"
                            data-tooltip="Facebook"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li className="social-twitter">
                          <a
                            href=" https://www.instagram.com/vsdentalcollege/ "
                            className="tooltip-bottom"
                            target="_blank"
                            data-tooltip="Instagram"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li className="social-twitter">
                          <a
                            href="https://www.youtube.com/watch?v=yRzFxT8xwh0"
                            className="tooltip-bottom"
                            target="_blank"
                            data-tooltip="Youtube"
                          >
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                <div className="widget widget_text  clearfix">
                  <h3 className="widget-title">For Support</h3>
                  <div className="quicklink-box widjet-text">
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box left-icon">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <span className="flaticon flaticon-call" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                      <div className="featured-content">
                        {/*  featured-content */}
                        <div className="featured-desc">
                          {/*  featured-desc */}
                          <p>Any Queries</p>
                        </div>
                        <div className="featured-title">
                          {/*  featured-title */}
                          <h5>080-26618066</h5>
                        </div>
                      </div>
                    </div>
                    {/*  featured-icon-box END */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
          <div className="container-xl">
            <div className="row copyright align-items-center res-767-text-center">
              <div className="col-md-6">
                <div>
                  <span>
                    Copyright © 2021&nbsp;<a to="/">VSDC</a> by{" "}
                    <a href="http://shoonyeka.com/#/" target="_blank">
                      Shoonyeka Solutions , Mysuru.
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
