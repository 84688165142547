import React, { Component } from 'react';
import ProsthodonticsSidebar from './ProsthodonticsSidebar';
import Header from '../../../layout/Header';

export class ProTeaching extends Component {
    render() {
        return (
          <div className="site-main">
            <Header />
            {/* page-title */}
            <div className="ttm-page-title-row prosthodontics_banner">
              <div className="container-xl">
                <div className="row">
                  <div className="col-md-12">
                    <div className="title-box text-center">
                      <div className="page-title-heading card_box">
                        <h4 className="title " style={{ color: "white" }}>
                         Teaching and Learning
                        </h4>

                        <div className="ttm-color-home">
                          <span>
                            <a
                              className="ttm-color-home"
                              title="Homepage"
                              href={process.env.PUBLIC_URL + "/"}
                            >
                              <i className="ti ti-home" />
                              &nbsp;&nbsp;Home
                            </a>
                          </span>
                          <span className="ttm-bread-sep">
                            &nbsp; : : &nbsp;
                          </span>
                          <span>Prosthodontics and Crown and Bridge</span>
                        </div>
                      </div>
                    </div>
                    {/* /.col-md-12 */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.page-title-captions */}
              </div>
              {/* /.container */}
            </div>
            {/* page-title end*/}
            <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
              <div className="container-xl">
                {/* row */}
                <div className="row">
                  <div className="col-lg-9 content-area order-lg-2">
                    <h3>Teaching Learning</h3>
                    Teaching-Learning BDS The educational journey of BDS
                    students studying in each year is mentored, monitored and
                    evaluated by faculty in-charge. The faculty in-charge for
                    each year of BDS students are responsible for maintaining
                    regular teaching and practical/clinical time tables,
                    assessment of their internal assessment performance,
                    co-curricular activities inside and outside campus. In
                    addition to in-charge faculty, mentors for each year of BDS
                    are responsible for overall guidance for students’
                    individual performance and wellbeing during their course.
                    Life Skills workshops are conducted every year by the
                    in-house expert faculty for empowering them to take
                    responsibility for their learning. BDS students who are
                    posted to each department of clinical, non-clinical and
                    para-clinical subjects are mentored batchwise. Each batch of
                    students posted in each department will consist of around 10
                    students who are mentored by assigned faculty throughout
                    their posting period. They are mentored and guided in
                    clinical discussions, patient care and project assignments.
                    Interns: The batch of Interns are mentored by in-charge
                    faculty in their clinical postings. MDS Students pursuing
                    MDS are trained and mentored in their specialty subjects
                    according to the syllabus prescribed by RGUHS in each
                    department. The PG students are mentored by their respective
                    PG-Guides throughout their three years of postgraduation in
                    patient care, research and public health services. In
                    addition, year wise in-charge faculty, subject specialty
                    wise in-charge faculty will nurture them by training them
                    specifically as per the requirements for each year and
                    specialty oral health care.{" "}
                    {/* ttm-service-single-content-are */}
                    {/* ttm-service-single-content-are */}
                    <div className="row">
                      <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                      </div>
                      <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                      </div>
                      <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                      </div>

                      <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                      </div>
                      <div className="col-md-6 col-lg-4 col-sm-6">
                        {/* featured-imagebox-team */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 widget-area">
                    <ProsthodonticsSidebar />
                  </div>
                </div>
                {/* row end */}
              </div>
            </div>
          </div>
        );
    }
}


export default ProTeaching;