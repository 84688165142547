import React, { Component } from "react";
import image from "../../../../images/Periodontics/HOD.JPG";
import firestore from "../../../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import { NavLink } from "react-router-dom";

export class PeriodonticsSidebar extends Component {
  render() {
    return (
      <div>
        <div className="widget widget-nav-menu">
          <ul className="widget-menu">
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/Periodontics"}>
                About
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/PeriodonticsFaculties"}>
                Faculties and Staff
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/PeriodonticsTeaching"}>
                Teaching - Learning
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/PeriodonticsResearch"}>
                Research and Innovations
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/PeriodonticsActivities"}>
                Activities
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="widget ">
          <div className="section-title with-desc text-left clearfix">
            <div className="title-header">
              <h2 className="title">
                Head of the <span>department</span>
              </h2>
            </div>
          </div>
          <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
            <img className="d-block w-100" src={image} alt="img" />
          </div>

          <h5>Dr. Vinayak S Gowda</h5>
          <h6> Education and Qualification:</h6>
          <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
            <li>
              <i class="fas fa-star"></i>BDS From Banglore university, 1998{" "}
            </li>
            <li>
              <i class="fas fa-star"></i>MDS IN Periodontics Rajiv Gandhi
              University Of Health Sciences, 2000-2002{" "}
            </li>
            <li>
              <i class="fas fa-star"></i>International Qualifying Exam For
              National Health Service, (UK AND SCOTLAND) PART A & PART B FROM
              EASTMAN INSTITUTE (LONDON) 2006
            </li>
            <li>
              <i class="fas fa-star"></i>Fellowship IN International Congress OF
              Oral Implantalogy 2008{" "}
            </li>
          </ul>
          <br />
          <h6>Work Experience: </h6>
          <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
            <li>
              <i class="fas fa-star"></i>2002-2003 AT Banglore Institute of
              Dental Sciences{" "}
            </li>
            <li>
              <i class="fas fa-star"></i>2003-Till Date V S Dental college and
              Hospital{" "}
            </li>
          </ul>
        </div>

        <div className="widget widget-download">
          <h3 className="widget-title">Contact</h3>
          <ul className="download">
            <li>
              <i className="fa fa-phone" />
              <a> 080-22426705</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default PeriodonticsSidebar;
