import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Header from "../layout/Header";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import image1 from "../../images/Gallery/image1.jpg";
import image2 from "../../images/Gallery/image2.JPG";
import image3 from "../../images/Gallery/image3.JPG";
import image4 from "../../images/Gallery/image4.JPG";
import image5 from "../../images/Gallery/image5.JPG";
import image6 from "../../images/Gallery/image6.JPG";
import image7 from "../../images/Gallery/image7.JPG";
import image8 from "../../images/Gallery/image8.JPG";
import image9 from "../../images/Gallery/image9.JPG";
import image10 from "../../images/Gallery/image10.JPG";
import image11 from "../../images/Gallery/image11.JPG";
import image12 from "../../images/Gallery/image12.JPG";
import image13 from "../../images/Gallery/image13.JPG";
import image14 from "../../images/Gallery/image14.JPG";
import image15 from "../../images/Gallery/image15.JPG";
import image16 from "../../images/Gallery/image16.JPG";
import image17 from "../../images/Gallery/image17.JPG";
import image18 from "../../images/Gallery/image18.JPG";
import image19 from "../../images/Gallery/image19.JPG";
import image20 from "../../images/Gallery/image20.JPG";
import image21 from "../../images/Gallery/image21.JPG";

const images = [
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
];

export class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row gallery-banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Gallery{" "}
                    </h4>
                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>VSDC Gallery</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.page-title-captions */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
        <div className="ttm-row tab-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-12">
                <div className="ttm-tabs text-center ttm-tab-style-classic style2">
                  <div className="row multi-columns-row ttm-boxes-spacing-5px">
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img className="img-fluid" src={image1} alt="image" />
                        </div>
                        <div className="featured-content">
                          <div className="category">
                            <p>Institute Par Excellence.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img className="img-fluid" src={image2} alt="image" />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>Central Library</p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img className="img-fluid" src={image3} alt="image" />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>PG - Study Room </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img className="img-fluid" src={image4} alt="image" />
                        </div>
                        <div className="featured-content">
                          <div className="category">
                            <p>Treatment Camp</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img className="img-fluid" src={image5} alt="image" />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>Oral Health Screening Camp</p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img className="img-fluid" src={image6} alt="image" />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>Satellite Centre - Shanthinagara</p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img className="img-fluid" src={image7} alt="image" />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>Primary Health Centre</p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img className="img-fluid" src={image8} alt="image" />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>CDE Programme</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img className="img-fluid" src={image9} alt="image" />
                        </div>
                        <div className="featured-content">
                          <div className="category">
                            <p>Outdoor Sports</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row multi-columns-row ttm-boxes-spacing-5px">
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={image10}
                            alt="image"
                          />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>Students Sports team </p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={image11}
                            alt="image"
                          />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>Staff Cricket Team</p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={image12}
                            alt="image"
                          />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>Sports Team</p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                  </div>

                  <div className="row multi-columns-row ttm-boxes-spacing-5px">
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={image13}
                            alt="image"
                          />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>Clinical Training</p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={image14}
                            alt="image"
                          />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>Dept of Prosthodontics</p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={image15}
                            alt="image"
                          />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>Dept of Oral Surgery</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row multi-columns-row ttm-boxes-spacing-5px">
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={image16}
                            alt="image"
                          />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>Dept of Paedodontics</p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={image17}
                            alt="image"
                          />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>Dept of Orthodontics</p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={image18}
                            alt="image"
                          />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>dept of Periodontics</p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>

                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={image19}
                            alt="image"
                          />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>
                              Dept of Conservative Dentistry and Endodontics
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={image20}
                            alt="image"
                          />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>Museum - Public Health Dentistry</p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
