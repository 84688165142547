import React, { Component } from "react";
import image1 from "../../../../images/PublicHealth/image5.jpg";
import { NavLink } from "react-router-dom";

export class PublicHealthSidebar extends Component {
  render() {
    return (
      <div>
        <div className="widget widget-nav-menu">
          <ul className="widget-menu">
            <li><NavLink to={process.env.PUBLIC_URL + "/PublicHealth"}>About</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/PHDFacultiesandStaff"}>Faculties and Staff</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/PHDTeachingLearning"}>Teaching - Learning</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/PHDResearch"}>Research and Innovations</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/PHDActivities"}>Activities</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/phdfieldprograms"}>Field Programs</NavLink></li>
          </ul>
        </div>

        <div className="widget">
          <div className="section-title with-desc text-center clearfix">
            <div className="title-header">
              <h2 className="title">Head of the <span className="ttm-textcolor-blue">Department</span></h2>
            </div>
          </div>
          <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
            <img className="d-block w-100" src={image1} alt="img" />
          </div>
          <h5 style={{ color: "black" }}>Dr. G Radha</h5>
          <p>Professor & HOD</p>
          <h6>Education and Qualification:</h6>
          <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
            <li><i class="fas fa-star"></i>Experience : 15 Years</li>
            <li><i class="fas fa-star"></i>Qualification : B.D.S,M.D.S,PhD</li>
          </ul>
        </div>

        <div className="widget widget-download">
          <h3 className="widget-title">Contact</h3>
          <ul className="download">
            <li><i className="fa fa-phone" /><a> 080-22426705</a></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default PublicHealthSidebar;
