import React, { Component } from "react";
import Slider from "react-slick";
import Header from "../layout/Header";
import image1 from "../../images/VSDCCampus/Image1.jpeg";
import image2 from "../../images/VSDCCampus/image2.jpg";
import image3 from "../../images/VSDCCampus/image3.jpg";
import image4 from "../../images/VSDCCampus/image4.jpg";
import image5 from "../../images/VSDCCampus/image5.jpg";

export class Campus extends Component {
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row campus-banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading  card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      VSDC Campus
                    </h4>
                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/Single_style1"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>VSDC Campus</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className=" project-single-section clearfix">
          <div className="container-xl">
            <div className="row mt-4">
              <div className="col-12">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Campus <span> </span>
                    </h2>
                  </div>
                </div>
                <p className="text-justify">
                  V S Dental College & Hospital (VSDCH) is one of the dynamic
                  institutions which is spread around 16 acres of campus area.
                  Since its inception, it has evolved as one of the finest
                  centres for learning. Campus is a dwelling space with all the
                  adequate amenities of higher standards. It comprises of
                  students of diverse cultural background who will be assured of
                  academic excellence and VSDCH thrives to instil leadership
                  qualities and competitive spirit amongst students. VSDCH has a
                  well-established infrastructure which includes facilities to
                  students and staff members. Primary facilities include hostel
                  accommodation for boys and girls, ATM facility, canteen,
                  pharmacy and college bus facility for transportation. The
                  students are encouraged to actively indulge in all the events
                  held by our Cultural and Sports committees. In a nutshell,
                  there is a scope for a cultural mosaic, as VSDCH coexists with
                  other educational institutions in the same campus.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-8">
                <Slider
                  className="slick_slider project-single"
                  {...slick_slider}
                  arrows={false}
                  dots={true}
                >
                  <div className="featured-thumbnail">
                    <img
                      src={image1}
                      alt="campus_image"
                      className="img-fluid"
                      style={{ width: "760px", height: "400px" }}
                    />
                  </div>
                  <div className="featured-thumbnail">
                    <img
                      src={image2}
                      alt="campus_image"
                      className="img-fluid"
                      style={{ width: "760px", height: "400px" }}
                    />
                  </div>
                  <div className="featured-thumbnail">
                    <img
                      src={image3}
                      alt="campus_image"
                      className="img-fluid"
                      style={{ width: "760px", height: "400px" }}
                    />
                  </div>
                </Slider>
              </div>
              <div className="col-lg-12 col-sm-12 col-xl-4">
                <div className="row res-1199-mt-20">
                  <div className="col-xl-12 col-lg-6 col-md-6 col-sm-6">
                    <div className="ttm_single_image-wrapper mb-20">
                      <img
                        className="img-fluid"
                        src={image4}
                        title="single-img-twentytwo"
                        alt="single-img-twentytwo"
                      />
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-6 col-md-6 col-sm-6 mb-20">
                    <div className="ttm_single_image-wrapper">
                      <img
                        className="img-fluid"
                        src={image5}
                        title="single-img-twentythree"
                        alt="single-img-twentythree"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Campus;
