import React, { Component } from 'react';
import Header from '../../layout/Header';
import image from"../../../images/Bestpractices/IndhiraGandhi.jpeg";
import image2 from"../../../images/Bestpractices/IndhiraGandhi2.jpeg";
import image3 from"../../../images/Bestpractices/IndhiraGandhi3.jpeg";



export class IndhiraGandhiChildHealth extends Component {
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row bestpractices-banner">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading  card_box">
                            <h4 className="title" style={{color:"white"}}>Indhira Gandhi Institutre of Child Health</h4>
                            <div className="ttm-color-home">
                            <span>
                                <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>VSDCH</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}
                        </div>{/* /.page-title-captions */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                     
                </div>
                {/* page-title end*/}
                {/* aboutus-section */}
                <section className="ttm-row aboutus-section-style3 clearfix " style={{paddingBottom:"10px"}}>
                 <div className="container-xl">
                     <div className="row">{/* row */}
                        <div className="col-lg-6">
                          <div className="position-relative">
                               {/* ttm_single_image-wrapper */}
                                 <div className="ttm_single_image-wrapper text-sm-center mr-35 res-991-m-0">
                                     <img className="img-fluid" src={image} title="single-img-nine" alt="single-img-nine" />
                                 </div>{/* ttm_single_image-wrapper end */}
                           </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="res-991-mt-30">
                            {/* section title */}
                                 <div className="section-title with-desc clearfix">
                                    <div className="title-header">
                                         <h5></h5>
                                        <h2 className="title">Indhira Gandhi Institute of <span>child Health</span></h2>
                                 </div>
                                <div className="title-desc">
                                  <p className='text-justify'>It is a premier organisation promoting tertiary level child health care services and is a government referral centre for children in Karnataka state. The Department of Pedodontics, VSDCH has a peripheral Dental unit set up in the Hospital, with PG s and Staff posted on a rotation basis. Dept renders free treatment to the underprivileged children and those with special health care needs. The post graduates also get a chance to expose themselves to a diversity of medical conditions and perform dental treatment of the same.</p>
                                  </div>
                               
                            </div>{/* section title end */}
                            {/* row */}
                         
                        </div>
                        </div>
                    </div>
                    {/* row end */}
                    </div>
                </section>
                 {/* aboutus-section end */}
                {/* zero-padding-section */}
               
               
                   
                    <section className=" ttm-row  aboutus-section clearfix ">
                    <div className="container-xl">
                    <div className="row">{/* row */}
                       <div className="col-lg-6">
                        <div className="position-relative res-991-mt-30">
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper text-sm-center">
                            <img className="img-fluid" src={image2} title="single-img-seven" alt="single-img-seven" style={{height:"360px",width:"555px"}} />
                            </div>{/* ttm_single_image-wrapper end */}
                        </div>
                       </div>
                        <div className="col-lg-6">
                        <div className="position-relative res-991-mt-30">
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper text-sm-center">
                            <img className="img-fluid" src={image3} title="single-img-seven" alt="single-img-seven" />
                            </div>{/* ttm_single_image-wrapper end */}
                        </div>
                        </div>
                    </div>
                    {/* row end */}
                    </div>
                </section>
                    </div>
                
            
            )
        }
    }
    
    
    export default IndhiraGandhiChildHealth;