import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import Header from "../layout/Header";
import Details from "./Details";
import firestore from "../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import NoData from "../pages/NoData";

export class Facilities extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Photos: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "LifeAtVSDC");
    const photokey = "Photos";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      console.log(Snapshot);
      const allDynamicData = this.getDynamicData(Snapshot);
      console.log(allDynamicData);
      this.dynamicData.Photos = allDynamicData[photokey];

      console.log("test data");

      console.log(this.dynamicData.Photos);
    }

    this.setState({});
  }
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row facilities_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      FACILITIES
                    </h4>

                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>FACILITIES </span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.page-title-captions */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        <section className=" ttm-row project-single-section clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-8 col-xl-8">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Facilities <span> </span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
                <p>
                  V S Dental College & Hospital has well established
                  infrastructure which includes facilities to Students, staff
                  members and patients.
                </p>
                <div className="row">
                  <div className="col-md-12">
                    {/* ttm_single_image-wrapper */}
                    <div className="ttm_single_image-wrapper mt-35">
                      <img
                        className="img-fluid"
                        src="./images/facilitiesimg4.jpg"
                        alt="single-img-fourteen"
                        style={{ width: "780px", height: "555px" }}
                      />
                    </div>
                    {/* ttm_single_image-wrapper end */}
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-xl-4">
                <Details />
              </div>
            </div>
            {/* row end*/}
            {/* row */}
          </div>
        </section>

        <section className=" bottomzero-padding-section position-relative clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Facilities <span>Photos</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row */}
            <div className="row multi-columns-row ttm-boxes-spacing-10px ttm-bgcolor-white">
              {this.dynamicData.Photos === undefined ? (
                <NoData />
              ) : (
                this.dynamicData.Photos.length &&
                this.dynamicData.Photos.map(function (item, index) {
                  return (
                    <div
                      className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6"
                      key={index}
                    >
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={item.Image}
                            alt="image"
                            style={{ width: "360px", height: "303px" }}
                          />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>{item.Description}</p>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                  );
                })
              )}
            </div>
            {/* row end */}
          </div>
        </section>
      </div>
    );
  }
}

export default Facilities;
