import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Menu extends Component {
  render() {
    return (
      <nav id="menu" role="navigation" className="menu">
        <ul>
          <li className="menu-hasdropdown"><Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>Home</Link></li>
          <li className="menu-hasdropdown">
            <a>Explore Us</a>
            <ul className="menu-dropdown">
              <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/aboutVSDC"}>About</Link></li>
              <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/ManagementRVS"}>Management RVS</Link></li>
              <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/ChairmanVSDC"}>Chairman</Link></li>
              <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/PrincipalVSDC"}>Principal</Link></li>
              <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/Administration"}>Administration</Link></li>
              <li><Link className="nav-link" to={process.env.PUBLIC_URL + "/Rules"}>Rules &amp; Regulations</Link></li>
              <li><Link to={process.env.PUBLIC_URL + "/Accreditation"}>Accreditation &amp; Approvals</Link></li>
            </ul>
          </li>
          <li className="menu-hasdropdown"><Link to={process.env.PUBLIC_URL + "/Admission"}> Admission</Link></li>
          <li className="menu-hasdropdown">
            <a>Academics</a>
            <ul className="menu-dropdown">
              <li><Link to={process.env.PUBLIC_URL + "/Department"}>Departments</Link></li>
              <li><Link to={process.env.PUBLIC_URL + "/TeachingLearning"}>Teaching &amp; Learning</Link></li>
              <li><Link to={process.env.PUBLIC_URL + "/Library"}>Library</Link></li>
            </ul>
          </li>
          <li className="menu-hasdropdown">
            <a>Life @ VSDCH</a>
            <ul className="menu-dropdown">
              <li><Link to={process.env.PUBLIC_URL + "/Campus"}>VSDCH Campus</Link></li>
              <li><Link to={process.env.PUBLIC_URL + "/StudentActivities"}>Student Activities</Link></li>
              <li><Link to={process.env.PUBLIC_URL + "/SportsandGames"}>Sports &amp; Games at VSDCH</Link></li>
              <li><Link to={process.env.PUBLIC_URL + "/Facilities"}>Facilities</Link></li>
              <li><Link to={process.env.PUBLIC_URL + "/Alumni"}>Alumni</Link></li>
              <li><Link to={process.env.PUBLIC_URL + "/Achievers"}>Achievers</Link></li>
              <li><Link to={process.env.PUBLIC_URL + "/BestPractices"}>Best Practices at VSDCH</Link></li>
            </ul>
          </li>
          <li className="menu-hasdropdown"><Link to={process.env.PUBLIC_URL + "/Gallery"}> Gallery</Link></li>
          <li className="menu-hasdropdown"><Link to={process.env.PUBLIC_URL + "/Contact_01"}> Contact</Link></li>
        </ul>
      </nav>
    )
  }
}
