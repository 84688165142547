import React, { Component } from "react";
import Slider from "react-slick";
import Header from "../layout/Header";
import pdf from "../../assets/AlumniApplication.pdf";
import firestore from "../../Config/Config";
import NoData from "../pages/NoData";
import { collection, getDocs } from "firebase/firestore";

export class Alumni extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Noted: [],
      Activities: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "Alumni");
    const notedkey = "Noted";
    const activiteskey = "AlumAct";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      console.log(Snapshot);
      const allDynamicData = this.getDynamicData(Snapshot);
      console.log(allDynamicData);
      this.dynamicData.Noted = allDynamicData[notedkey];
      this.dynamicData.Activities = allDynamicData[activiteskey];

      console.log("test data");

      console.log(this.dynamicData.Noted);
      console.log(this.dynamicData.Activities);
    }
    const arr = [];
    this.setState({ Noted: arr });
    this.setState({ Activities: arr });
  }

  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row alumni_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Alumni VSDCH
                    </h4>

                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Alumni</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.page-title-captions */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        {/* aboutus-section */}
        <section className="ttm-row aboutus-section clearfix ">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-6">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Alumni <span>VSDCH</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p className="text-justify">
                      Alumni- VSDCH made a humble beginning in the year 2001
                      with a bunch of faculty members who were old students of
                      the Institution. The Alumni through the years has
                      strengthened and presently has about 1640 members enrolled
                      as life members. we look forward to enrol each and every
                      student as an Alumni member. The association has
                      constituted an ad-hoc Committee for day-to-day activities.
                      Official meetings are held regularly with Patron/Principal
                      and few advisers from among the senior faculty. The Office
                      bearers shall be elected/nominated during the annual
                      General Body meetings, until such time the Ad-hoc
                      Committee will be officially in charge.
                    </p>
                    <div className="position-relative res-991-mt-30">
                      {/* ttm_single_image-wrapper */}
                      <div className="row no-gutters mt-4 mb-4">
                        <div className="col-md-6 col-lg-6 col-sm-6">
                          <div
                            className="ttm-pf-single-detail-box mb-35"
                            style={{ paddingBlockEnd: "30px" }}
                          >
                            <ul className="ttm-pf-detailbox-list">
                              <li>
                                <i className="fa fa-user" />
                                <span> President: </span>Dr Ravindra Savadi
                              </li>
                              <li>
                                <i className="fa fa-calendar" />
                                <span>Treasurer:</span> Dr Chandan G D
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-6 col-sm-6">
                          <div
                            className="ttm-pf-single-detail-box mb-35"
                            style={{ paddingBlockEnd: "30px" }}
                          >
                            <ul className="ttm-pf-detailbox-list">
                              <li>
                                <i className="far fa-bookmark" />
                                <span> Secretary: </span>Dr Deepak TA{" "}
                              </li>
                              <li>
                                <i className="far fa-bookmark" />
                                <span>Past president: </span> Dr Srinath .N{" "}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* row END*/}
                    </div>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  {/* ttm_single_image-wrapper */}
                  <div className="row no-gutters mt-4 mb-4">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="ttm-pf-single-title">
                        <h5>Association Members</h5>
                      </div>
                      <div
                        className="ttm-pf-single-detail-box mb-35"
                        style={{ paddingBlockEnd: "0px" }}
                      >
                        <ul className="ttm-pf-detailbox-list">
                          <li>
                            <i className="fa fa-calendar" />
                            <span>Treasurer:</span> Dr Chandan G D
                          </li>
                          <li>
                            <i className="fa fa-user" />
                            <span>Past president: </span> Dr Srinath .N{" "}
                          </li>
                          <li>
                            <i className="fa fa-user" />
                            <span>President Elect:</span> Dr Vijay lakshmi B{" "}
                          </li>
                          <li>
                            <i className="fa fa-user" />
                            <span> Vice president: </span>Dr Nalinakshamma Dr
                            Reyaz{" "}
                          </li>
                          <li>
                            <i className="fa fa-user" />
                            <span>Joint Secretary:</span> Dr Smitha T{" "}
                          </li>
                          <li>
                            <i className="fa fa-user" />
                            <span>Members: </span> Dr Ashwin D P
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="ttm-pf-single-title">
                        <h6>.</h6>
                      </div>
                      <div
                        className="ttm-pf-single-detail-box mb-35"
                        style={{ paddingBlockEnd: "45px" }}
                      >
                        <ul className="ttm-pf-detailbox-list">
                          <li>
                            <span></span>Dr Keerthi{" "}
                          </li>
                          <li>
                            <span></span> Dr Chenna Keshav{" "}
                          </li>
                          <li>
                            <span></span> Dr Kumar{" "}
                          </li>
                          <li>
                            <span></span> Dr Annaji A G{" "}
                          </li>
                          <li>
                            <span></span> Dr shashikala{" "}
                          </li>
                          <li>
                            <span></span> Dr Ramamurthy{" "}
                          </li>
                          <li>
                            <span></span> Dr Narendra{" "}
                          </li>
                          <li>
                            <span></span> Dr Naveen
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* row END*/}
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* aboutus-section end*/}
        {/* progress-bar-section */}
        <section className="ttm-row blog-section clearfix  spacing_bottom">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h5>Our Alumnis</h5>
                    <h2 className="title">
                      Noted <span>Alumnis</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            <Slider
              className="row slick_slider ttm-boxes-spacing-30px"
              {...slick_slider}
              slidesToShow={3}
              infinite={
                this.dynamicData.Noted != undefined &&
                this.dynamicData.Noted.length <= 3
                  ? true
                  : false
              }
            >
              {this.dynamicData.Noted === undefined ? (
                <NoData />
              ) : (
                this.dynamicData.Noted.length &&
                this.dynamicData.Noted.map(function (item, index) {
                  return (
                    <div className="ttm-box-col-wrapper" key={index}>
                      <div className="featured-imagebox featured-imagebox-blog style2">
                        <div className="featured-thumbnail">
                          {/* featured-thumbnail */}
                          <img
                            className="img-fluid d-flex"
                            alt=""
                            src={item.Image}
                            style={{ width: "360px", height: "330px" }}
                          />
                        </div>
                        <div className="featured-content">
                          {/* featured-content */}
                          <h5>{item.Name}</h5>
                          <h6>{item.Header}</h6>
                          <div className="post-meta">
                            {/* post-meta */}
                            <span className="ttm-meta-line">
                              <i className="fa fa-comments" />
                              {item.Description}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        </section>
        {/* blog-section end */}
        <section className="ttm-row blog-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h5>Our Alumnis</h5>
                    <h2 className="title">
                      Alumni <span>Activities</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            <Slider
              className="row slick_slider ttm-boxes-spacing-30px"
              {...slick_slider}
              slidesToShow={3}
            >
              {this.dynamicData.Activities === undefined ? (
                <NoData />
              ) : (
                this.dynamicData.Activities.length &&
                this.dynamicData.Activities.map(function (item, index) {
                  return (
                    <div className="ttm-box-col-wrapper" key={index}>
                      <div className="featured-imagebox featured-imagebox-blog style2">
                        <div className="featured-thumbnail">
                          {/* featured-thumbnail */}
                          <img
                            className="img-fluid"
                            alt=""
                            src={item.Image}
                            style={{ width: "360px", height: "330px" }}
                          />
                        </div>
                        <div className="featured-content">
                          {/* featured-content */}
                          <h6>{item.Header}</h6>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        </section>

        <section className="ttm-row team-member-section clearfix  spacing_bottom">
          <div className="container-xl">
            <div className="row align-items-center mb-5">
              <div className="col-lg-5 res-991-text-center">
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header ">
                    <h2 className="title">
                      Alumni <span>Registeration</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 res-991-text-center">
                <div className="title-desc text-justify">
                  Students admitted prior to academic year 2000-2001 are
                  requested to enrol themselves as life members by paying Rs
                  1000/ as one time registration fee. All old students are
                  requested to communicate with office bearers for their
                  membership enrolment.
                </div>
              </div>
              <div className="col-lg-2 pl-0 text-right res-991-mt-20 res-991-text-center">
                <a
                  href={pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                >
                  {" "}
                  Application Form{" "}
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Alumni;
