import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import Header from '../../layout/Header';
import image1 from "../../../images/Bestpractices/Express3.png"


export class ExpressDentalCare extends Component {
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row bestpractices-banner">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading  card_box">
                            <h4 className="title" style={{color:"white"}}>Express Dental Care</h4>
                            <div className="ttm-color-home">
                            <span>
                                <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>VSDCH</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}
                        </div>{/* /.page-title-captions */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                     
                </div>
                <section className="ttm-row aboutus-section clearfix">
                    <div className="container-xl">
                    <div className="row">{/* row */}
                        <div className="col-lg-6">
                        {/* section title */}
                        <div className="section-title with-desc clearfix">
                            <div className="title-header">                        
                            <h2 className="title">Express Dental <span> Care</span></h2>
                            </div>
                            <div className="title-desc">
                            <p className='text-justify'> It is a speciality clinic which was established in VSDCH to provide treatment on priority basis from skilled dental experts which is offered at a slightly higher cost.</p>                         
                            </div>
                        </div>{/* section title end */}
                        {/* row */}
                       
                        </div>
                        <div className="col-lg-6">
                        <div className="position-relative res-991-mt-30">
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper text-sm-center">
                            <img className="img-fluid" src={image1} title="single-img-seven" alt="single-img-seven" />
                            </div>{/* ttm_single_image-wrapper end */}
                        </div>
                        </div>
                    </div>
                    {/* row end */}
                    </div>
                </section>
            </div>
        )
    }
}


export default ExpressDentalCare;