import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import Header from "../layout/Header";
import "react-image-lightbox/style.css";
import Slider from "react-slick";
import firestore from "../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import NoData from "../pages/NoData";

export class StudentActivities extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      AnnualFest: [],
      FreshersDay: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "LifeAtVSDC");
    const annualfestkey = "AnnualFest";
    const freshersdaykey = "FreshersDay";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      console.log(Snapshot);
      const allDynamicData = this.getDynamicData(Snapshot);
      console.log(allDynamicData);
      this.dynamicData.AnnualFest = allDynamicData[annualfestkey];
      this.dynamicData.FreshersDay = allDynamicData[freshersdaykey];

      console.log("test data");

      console.log(this.dynamicData.AnnualFest);
    }
    this.setState({});
  }

  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const { photoIndex, isOpen } = this.state;
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row studentact_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      VSDCH Student Activities
                    </h4>

                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>VSDCH Student Activities</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
            </div>
            {/* /.page-title-captions */}
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}

        <section
          className="ttm-row aboutus-section-style3 clearfix "
          style={{ paddingBottom: "10px" }}
        >
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-6">
                <div className="position-relative">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center mr-35 res-991-m-0">
                    <img
                      className="img-fluid"
                      src="./images/studentactivitiesimg6.jpg"
                      alt="single-img-nine"
                      style={{ width: "555px", height: "285px" }}
                    />
                  </div>
                  {/* ttm_single_image-wrapper end */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="res-991-mt-30">
                  {/* section title */}
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        {" "}
                        Student <span>Activities</span>
                      </h2>
                    </div>
                    <div className="title-desc">
                      <p className="text-justify">
                        Co-curricular activities are an integral part of our
                        institution where students are self-reliant and involve
                        themselves exceptionally into sports arts and cultural
                        events.
                      </p>
                    </div>
                  </div>
                  {/* section title end */}
                  {/* row */}
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>

        <section>
          <div className="ttm-row tab-section clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-12">
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        {" "}
                        Activities <span></span>
                      </h2>
                    </div>
                    <div className="title-desc">
                      <p className="text-justify">
                        V S Dental College & Hospital is well known for its
                        vivacious energy when it comes to co- curricular
                        activities. Besides academic attainment, our institution
                        is complemented by enriching co -curriculum there by
                        achieving a well-rounded education. Cultural and Sports
                        committees are in place and students are encouraged to
                        actively indulge in all the events held. Since students
                        are volunteers and also part of the committees framed to
                        conduct Cultural fest/ Sports day/ Orientation Day /
                        Graduation Day etc, College indirectly lends a hand to
                        the students to enhance their skills in leadership and
                        team work.
                      </p>
                      <p> Students actively participate in</p>
                      <div className="row">
                        <div className="col-lg-6">
                          <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                            {" "}
                            <li>
                              {" "}
                              <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                              Cultural fests
                            </li>
                            <li>
                              {" "}
                              <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                              Fashion shows
                            </li>
                            <li>
                              {" "}
                              <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                              Street plays
                            </li>
                            <li>
                              {" "}
                              <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                              Celebration of Kannada Rajyothsava/ Independence
                              Day / Holi/ Deepawali / Christmas{" "}
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                            <li>
                              {" "}
                              <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                              Teachers day Celebration
                            </li>
                            <li>
                              {" "}
                              <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                              Inter/Intra College Sports Tournament / University
                              sports meet
                            </li>
                            <li>
                              {" "}
                              <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                              Inter college competitions (Dance / Drama/
                              Literary)
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-tabs text-center ttm-tab-style-classic style2">
                    <div className="content-tab">
                      <div className="row multi-columns-row ttm-boxes-spacing-5px">
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                          {/* featured-imagebox */}
                          <div className="featured-imagebox featured-imagebox-portfolio ">
                            {/* featured-thumbnail */}
                            <div className="featured-thumbnail">
                              <img
                                className="dept-image"
                                src="./images/studentactivitiesimg1.jpg"
                                alt="image"
                              />
                            </div>
                            {/* featured-thumbnail end*/}
                          </div>
                          {/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                          {/* featured-imagebox */}
                          <div className="featured-imagebox featured-imagebox-portfolio ">
                            {/* featured-thumbnail */}
                            <div className="featured-thumbnail">
                              <img
                                className="dept-image"
                                src="./images/studentactivitiesimg2.jpg"
                                alt="student_act"
                              />
                            </div>
                            {/* featured-thumbnail end*/}
                          </div>
                          {/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                          {/* featured-imagebox */}
                          <div className="featured-imagebox featured-imagebox-portfolio ">
                            {/* featured-thumbnail */}
                            <div className="featured-thumbnail">
                              <img
                                className="dept-image"
                                src="./images/studentactivitiesimg3.jpg"
                                alt="student_act"
                              />
                            </div>
                            {/* featured-thumbnail end*/}
                          </div>
                          {/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                          {/* featured-imagebox */}
                          <div className="featured-imagebox featured-imagebox-portfolio ">
                            {/* featured-thumbnail */}
                            <div className="featured-thumbnail">
                              <img
                                className="dept-image"
                                src="./images/studentactivitiesimg4.jpg"
                                alt="student_act"
                              />
                            </div>
                            {/* featured-thumbnail end*/}
                          </div>
                          {/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                          {/* featured-imagebox */}
                          <div className="featured-imagebox featured-imagebox-portfolio ">
                            {/* featured-thumbnail */}
                            <div className="featured-thumbnail">
                              <img
                                className="dept-image"
                                src="./images/studentactivitiesimg5.jpg"
                                alt="student_act"
                              />
                            </div>
                            {/* featured-thumbnail end*/}
                          </div>
                          {/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                          {/* featured-imagebox */}
                          <div className="featured-imagebox featured-imagebox-portfolio ">
                            {/* featured-thumbnail */}
                            <div className="featured-thumbnail">
                              <img
                                className="dept-image"
                                src="./images/studentactivitiesimg6.jpg"
                                alt="student_act"
                              />
                            </div>
                            {/* featured-thumbnail end*/}
                          </div>
                          {/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                          {/* featured-imagebox */}
                          <div className="featured-imagebox featured-imagebox-portfolio ">
                            {/* featured-thumbnail */}
                            <div className="featured-thumbnail">
                              <img
                                className="dept-image"
                                src="./images/studentactivitiesimg7.jpg"
                                alt="student_act"
                              />
                            </div>
                            {/* featured-thumbnail end*/}
                          </div>
                          {/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                          {/* featured-imagebox */}
                          <div className="featured-imagebox featured-imagebox-portfolio ">
                            {/* featured-thumbnail */}
                            <div className="featured-thumbnail">
                              <img
                                className="dept-image"
                                src="./images/studentactivitiesimg8.jpg"
                                alt="student_act"
                              />
                            </div>
                            {/* featured-thumbnail end*/}
                          </div>
                          {/* featured-imagebox */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className=" tab-section clearfix">
            <div className="container-xl">
              <div className="section-title with-desc clearfix">
                <div className="title-header">
                  <h2 className="title">
                    {" "}
                    Annual <span>Fest</span>
                  </h2>
                </div>
              </div>
              <div className="row multi-columns-row ttm-boxes-spacing-5px">
                {this.dynamicData.AnnualFest === undefined ? (
                  <NoData />
                ) : (
                  this.dynamicData.AnnualFest.length &&
                  this.dynamicData.AnnualFest.map(function (item, index) {
                    return (
                      <div
                        className="col-lg-4 col-md-6 ttm-box-col-wrapper"
                        key={index}
                      >
                        {/* featured-imagebox-portfolio */}
                        <div className="featured-imagebox featured-imagebox-portfolio style1">
                          {/* featured-thumbnail */}
                          <div className="featured-thumbnail">
                            <img
                              className="img-fluid"
                              src={item.Image}
                              alt="student_act"
                              style={{ width: "360px", height: "270px" }}
                            />
                          </div>
                          {/* featured-thumbnail end*/}
                          {/* ttm-box-view-overlay */}
                          <div className="ttm-box-view-overlay">
                            <p
                              style={{
                                color: "black",
                                padding: "20px",
                                fontSize: "15px",
                              }}
                            >
                              {item.Description}
                            </p>
                          </div>
                          {/* ttm-box-view-overlay end*/}
                          <div className="featured-content">
                            <div className="featured-title">
                              {/* featured-title */}
                              <h5 className="header">{item.Heading}</h5>
                            </div>
                          </div>
                        </div>
                        {/* featured-thumbnail */}
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row blog-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Freshers day Graduation day <span>Cultural Events</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            <Slider
              className="row slick_slider ttm-boxes-spacing-30px"
              {...slick_slider}
              slidesToShow={3}
            >
              {this.dynamicData.FreshersDay === undefined ? (
                <NoData />
              ) : (
                this.dynamicData.FreshersDay.length &&
                this.dynamicData.FreshersDay.map(function (item, index) {
                  return (
                    <div className="ttm-box-col-wrapper d-flex" key={index}>
                      <div className="featured-imagebox featured-imagebox-blog">
                        <div className="featured-thumbnail">
                          {/* featured-thumbnail */}
                          <img
                            className="img-fluid"
                            alt="student_act"
                            src={item.Image}
                            style={{ width: "387px", height: "257px" }}
                          />
                        </div>
                        <div className="featured-content">
                          {/* featured-content */}
                          <div className="ttm-box-post-date">
                            {/* ttm-box-post-date */}
                            <span className="ttm-entry-date">
                              <time
                                className="entry-date"
                                dateTime="2019-01-16T07:07:55+00:00"
                              >
                                <span className="entry-month entry-year">
                                  {item.Date}
                                </span>
                              </time>
                            </span>
                          </div>
                          <div className="featured-title">
                            {/* featured-title */}
                            <h5>{item.Heading}</h5>
                          </div>

                          <div className="featured-desc">
                            {/* featured-description */}
                            <p>{item.Description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        </section>
      </div>
    );
  }
}

export default StudentActivities;
