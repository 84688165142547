import React, { Component } from "react";
import Header from "../../layout/Header";
import image1 from "../../../images/Bestpractices/Implant.jpeg";

export class ImplantClinic extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row bestpractices-banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading  card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Implant Clinic
                    </h4>
                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>VSDCH</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.page-title-captions */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        {/* about-services-section */}
        <section className="ttm-row about-services-section clearfix">
          <div className="container-xl">
            <div className="row align-items-center">
              {/* row */}
              <div className="col-lg-6">
                <div className="position-relative">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image1}
                      title="single-img-ten"
                      alt="single-img-ten"
                    />
                  </div>
                  {/* ttm_single_image-wrapper end */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="res-991-mt-30">
                  {/* section title */}
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        Implant <span> Clinic</span>
                      </h2>
                    </div>
                    <div className="title-desc">
                      <p className="text-justify">
                        The postgraduate students from departments of
                        Prosthodontics, Oral and maxillofacial surgery and
                        Periodontics work as a team to define and carefully
                        execute the most suitable treatment for the patient. The
                        cases are presented to the faculty and scrutinized well
                        before the treatment protocols are started.
                      </p>
                      <p>Objectives</p>
                    </div>
                  </div>
                  {/* section title end */}
                  {/* row */}
                  <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15 text-justify">
                    <li>
                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        Communicate effectively with the patient, outlining the
                        benefits of the proposed treatment.{" "}
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {" "}
                        Carry out any procedures required to augment deficient
                        sites within the capabilities of the practitioner.
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {" "}
                        Carry out surgical and restorative aspects of implant
                        therapy and provide the patient with aesthetic and
                        functional restorations to a high degree of
                        predictability.
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {" "}
                        Monitor and maintain the treatment that has been
                        completed for the patient.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
      </div>
    );
  }
}

export default ImplantClinic;
