import React, { Component } from "react";
import Header from "../../layout/Header";
import image1 from "../../../images/OralMedicinedept/deptimage1.JPG";
import image2 from "../../../images/PublicHealth/image1c.jpg";
import image3 from "../../../images/OMFS/image2.JPG";
import image4 from "../../../images/Prosthodonticsimg/image2.JPG";
import image5 from "../../../images/Periodontics/image1.JPG";
import image6 from "../../../images/Conservative/image1.JPG";
import image7 from "../../../images/Peadodontics/image.JPG";
import image8 from "../../../images/orthodonticsimage/image1.JPG";
import image9 from "../../../images/OMpathology/image1.jpeg";
import { Link } from "react-router-dom";

export class Department extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row deptpage_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading  card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Department
                    </h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">Department</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.page-title-captions */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <section className="ttm-row grid-section clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-6">
                {/* featured-imagebox-team */}
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="dept-image"
                      src={image1}
                      alt="First slide"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title" style={{ padding: "10px" }}>
                      {/* featured-title */}
                      <h5>
                        <Link
                          to={process.env.PUBLIC_URL + "/OralMedicineRadiology"}
                        >
                          Oral Medicine and Radiology
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox-team end*/}
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                {/* featured-imagebox-team */}
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="dept-image"
                      src={image2}
                      alt="First slide"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <Link to={process.env.PUBLIC_URL + "/PublicHealth"}>
                          Public Health Dentistry
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox-team end*/}
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                {/* featured-imagebox-team */}
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img className="dept-image" src={image3} alt="dept" />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <Link to={process.env.PUBLIC_URL + "/OralMAxilloFS"}>
                          Oral maxillo Facial Surgery
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox-team end*/}
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                {/* featured-imagebox-team */}

                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img className="dept-image" src={image4} alt="dept" />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <Link to={process.env.PUBLIC_URL + "/Prosthodontics"}>
                          Prosthodontics and Crown and Bridge
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox-team end*/}
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                {/* featured-imagebox-team */}
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img className="dept-image" src={image5} alt="dept" />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <Link to={process.env.PUBLIC_URL + "/Periodontics"}>
                          Periodontics
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox-team end*/}
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                {/* featured-imagebox-team */}
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img className="dept-image" src={image6} alt="dept" />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <Link
                          to={process.env.PUBLIC_URL + "/ConservativeDentistry"}
                        >
                          Conservative Dentistry and Endodontics
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox-team end*/}
              </div>
            </div>
            {/* row end*/}
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-6">
                {/* featured-imagebox-team */}
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img className="dept-image" src={image7} alt="dept" />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <Link to={process.env.PUBLIC_URL + "/Paedodontics"}>
                          Paedodontics and Preventive Dentistry
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox-team end*/}
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                {/* featured-imagebox-team */}
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img className="dept-image" src={image8} alt="dept" />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <Link to={process.env.PUBLIC_URL + "/Orthodontics"}>
                          Orthodontics and Dentofacial Orthopedics
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox-team end*/}
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                {/* featured-imagebox-team */}
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img className="dept-image" src={image9} alt="dept" />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <Link
                          to={process.env.PUBLIC_URL + "/OralMaxilPathology"}
                        >
                          Oral and maxillo Facial Pathology
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox-team end*/}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Department;
