import React, { Component } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBRow} from "mdbreact";
import image1 from "../../images/Home/bannerimg01.jpg";
import image2 from "../../images/Home/bannerimg02.jpg";
import image3 from "../../images/Home/bannerimg03.jpg";
import image4 from "../../images/Home/bannerimg04.jpg";
import image5 from "../../images/Home/bannerimg05.jpg";
import image6 from "../../images/Home/bannerimg06.jpg";

export class CarouselPage extends Component {
  render() {
    return (
      <MDBContainer fluid>
        <MDBRow>
          <MDBCarousel
            activeItem={1}
            length={6}
            showControls={true}
            showIndicators={false}
            id="Home_banner"
          >
            <MDBCarouselInner>
              <MDBCarouselItem itemId="1">
                <MDBView>
                  <img className="d-block w-100" src={image1} alt="First slide"/>
                </MDBView>
                <MDBCarouselCaption>
                  <div className="container-xl">
                    <div className="carousel-caption-inner banner1">
                      <MDBAnimation className="slideInUp" count={1} delay="2s">
                        <h2 className="ttm-textcolor-darkgrey flipInY">
                          Develop{" "}
                          <strong className="ttm-textcolor-skincolor">
                            excellent dental professionals
                          </strong>{" "}
                          through quality dental education.
                        </h2>
                      </MDBAnimation>
                      <MDBAnimation
                        className="slideInUp"
                        count={1}
                        delay="2.5s"
                      >
                        <p className="text">
                          {/* To promote innovative teaching practices.
                          <br /> To prepare dental students to enter into efficient dental practice, advanced dental education & community services. */}
                        </p>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} delay="3s">
                        <p>
                          &nbsp; &nbsp;
                          {/* <button className="ttm-btn btn-inline ttm-btn-size-md ttm-textcolor-darkgrey ml-3">
                            <i className="ti-arrow-circle-right" />
                            get a Quote
                          </button> */}
                        </p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="2">
                <MDBView>
                  <img
                    className="d-block w-100"
                    src={image2}
                    alt="Second slide"
                  />
                </MDBView>
                <MDBCarouselCaption>
                  <div className="container-xl">
                    <div className="carousel-caption-inner banner2">
                      <MDBAnimation className="slideInUp" count={1} delay="5s">
                        <h2
                          className="ttm-textcolor-darkgrey"
                          style={{ marginLeft: "100px" }}
                        >
                          Excellent{" "}
                          <strong className="ttm-textcolor-skincolor">
                            dental {""}
                          </strong>
                          professionals through quality dental education.
                        </h2>
                      </MDBAnimation>
                      <MDBAnimation
                        className="slideInUp"
                        count={1}
                        delay="5.5s"
                      ></MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} delay="6s">
                        <p>
                          {/* <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white mr-3">
                            read more
                          </button> */}
                        </p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="3">
                <MDBView>
                  <img
                    className="d-block w-100"
                    src={image3}
                    alt="Second slide"
                  />
                </MDBView>
                <MDBCarouselCaption>
                  <div className="container-xl">
                    <div className="carousel-caption-inner banner1">
                      <MDBAnimation className="slideInUp" count={1} delay="5s">
                        <h2 className="ttm-textcolor-darkgrey">
                          Help Students improve their health,{""}
                          <strong className="ttm-textcolor-skincolor">
                            Develop social ,educational and leadership skills{" "}
                            {""}
                          </strong>
                          while playing and Having fun .
                        </h2>
                      </MDBAnimation>
                      <MDBAnimation
                        className="slideInUp"
                        count={1}
                        delay="7.5s"
                      >
                        {/* <p className="text">
                          We are equipped with an updated technical knowledge to
                          serve our customers
                          <br /> properly. Our method of application maintains
                          the industry.
                        </p> */}
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} delay="6s">
                        <p>
                          {/* <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white mr-3">
                            read more
                          </button> */}
                        </p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="4">
                <MDBView>
                  <img
                    className="d-block w-100"
                    src={image4}
                    alt="Second slide"
                  />
                </MDBView>
                <MDBCarouselCaption>
                  <div className="container-xl">
                    <div className="carousel-caption-inner banner2">
                      <MDBAnimation className="slideInUp" count={1} delay="5s">
                        <h2
                          className="ttm-textcolor-darkgrey"
                          style={{ marginLeft: "100px" }}
                        >
                          Profound integrity and ethical character in{" "}
                          <strong className="ttm-textcolor-skincolor">
                            every endeavour
                          </strong>
                        </h2>
                      </MDBAnimation>
                      <MDBAnimation
                        className="slideInUp"
                        count={1}
                        delay="9.5s"
                      >
                        {/* <p className="text">
                          We are equipped with an updated technical knowledge to
                          serve our customers
                          <br /> properly. Our method of application maintains
                          the industry.
                        </p> */}
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} delay="6s">
                        <p>
                          {/* <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white mr-3">
                            read more
                          </button> */}
                        </p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="5">
                <MDBView>
                  <img
                    className="d-block w-100"
                    src={image5}
                    alt="Second slide"
                  />
                </MDBView>
                <MDBCarouselCaption>
                  <div className="container-xl">
                    <div className="carousel-caption-inner banner1">
                      <MDBAnimation className="slideInUp" count={1} delay="5s">
                        <h2>
                          Support and develop advanced and{" "}
                          <strong className="ttm-textcolor-skincolor">
                            technological innovations{" "}
                          </strong>
                          in dentistry
                        </h2>
                      </MDBAnimation>
                      <MDBAnimation
                        className="slideInUp"
                        count={1}
                        delay="11.5s"
                      ></MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} delay="6s">
                        <p>
                          {/* <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white mr-3">
                            read more
                          </button> */}
                        </p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="6">
                <MDBView>
                  <img
                    className="d-block w-100"
                    src={image6}
                    alt="Second slide"
                  />
                </MDBView>
                <MDBCarouselCaption>
                  <div className="container-xl">
                    <div className="carousel-caption-inner banner2">
                      <MDBAnimation className="slideInUp" count={1} delay="5s">
                        <h2
                          className="ttm-textcolor-darkgrey"
                          style={{ marginLeft: "100px" }}
                        >
                          To make a difference in the community by transforming
                          education through{" "}
                          <strong className="ttm-textcolor-skincolor">
                            green dentistry
                          </strong>
                        </h2>
                      </MDBAnimation>
                      <MDBAnimation
                        className="slideInUp"
                        count={1}
                        delay="13.5s"
                      >
                        {/* <p className="text">
                          We are equipped with an updated technical knowledge to
                          serve our customers
                          <br /> properly. Our method of application maintains
                          the industry.
                        </p> */}
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} delay="6s">
                        <p>
                          {/* <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white mr-3">
                            read more
                          </button> */}
                        </p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
            </MDBCarouselInner>
          </MDBCarousel>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default CarouselPage;
