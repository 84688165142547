import React, { Component } from "react";
import {MDBNavbar, MDBNavbarNav, MDBNavItem, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,MDBHamburgerToggler} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";

class Mobilemenu extends Component {
  state = {
    collapse1: false,
    collapseID: "",
  };

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  toggleSingleCollapse = (collapseId) => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId],
    });
  };

  render() {
    return (
      <Router>
        <MDBNavbar>
          <MDBHamburgerToggler id="hamburger1" onClick={() => this.toggleSingleCollapse("collapse1")}/>
          <MDBCollapse isOpen={this.state.collapse1} navbar>
            <MDBNavbarNav left>
              <MDBNavItem active><MDBDropdownToggle nav caret><a href={process.env.PUBLIC_URL + "/"}>Home</a></MDBDropdownToggle></MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret><span>Explore Us</span></MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/aboutVSDC"}>About</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/managementRVS"}>Management RVS</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/ChairmanVSDC"}>Chairman VSDC</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/PrincipalVSDC"}>Principal VSDC</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/Administration"}>Administration VSDC</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/Rules"}>Rules &amp; Regulations</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/Accreditation"}>Accreditation &amp; Approvals</MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem><MDBDropdownToggle nav caret><a href={process.env.PUBLIC_URL + "/Admission"}>Admission</a></MDBDropdownToggle></MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret><span>Academics</span></MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/Department"}>Departments</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/TeachingLearning"}>Teaching &amp; Learning</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/Library"}>Library</MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret><span>Life @ VSDC</span></MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/Campus"}>VSDC Campus</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/StudentActivities"}>Student Activities</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/SportsandGames"}>Sports &amp; Games at VSDC</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/Facilities"}>Facilities</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/Alumni"}>Alumni VSDC</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/Achievers"}>Achievers VSDC</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/BestPractices"}>Best Practices at VSDC</MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem><MDBDropdownToggle nav caret><a href={process.env.PUBLIC_URL + "/Gallery"}>Gallery</a></MDBDropdownToggle></MDBNavItem>
              <MDBNavItem><MDBDropdownToggle nav caret><a href={process.env.PUBLIC_URL + "/Contact_01"}>Contact Us</a></MDBDropdownToggle></MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </Router>
    );
  }
}

export default Mobilemenu;
