import React, { Component } from "react";
import ProsthodonticsSidebar from "./ProsthodonticsSidebar";
import Slider from "react-slick";
import Header from "../../../layout/Header";
import image1 from "../../../../images/Prosthodonticsimg/image2.JPG";
import image2 from "../../../../images/Prosthodonticsimg/image3.JPG";
import image3 from "../../../../images/Prosthodonticsimg/image6.JPG";
import image4 from "../../../../images/Prosthodonticsimg/image5.JPG";
import pdf from "../../../../assets/Prosthodontics/BDSPROSTHODONTICS.pdf";
import docx from "../../../../assets/Prosthodontics/PROSTHODONTICSSYllabus.docx";
import firestore from "../../../../Config/Config";
import { collection, getDocs, onSnapshot, doc } from "firebase/firestore";
import Pronews from "./Pronews";
import NoData from "../../../pages/NoData";

export class Prosthodontics extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Achievers: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  // async componentDidMount() {
  //   onSnapshot(doc(firestore, "Prosthodonics", "Achievers"), (doc) => {
  //     if (doc.exists()) {
  //       Object.entries(doc.data()).forEach(([key, value]) => {
  //         this.dynamicData.Achievers.push(
  //           Object.assign({ Id: key }, value)
  //         );
  //       });
  //       this.setState({
  //         Achievers: this.Achievers.Notifications,
  //       });
  //     } else {
  //       // this.setState({
  //       //     Data: []
  //       // })
  //     }
  //   });
  // }
  async componentDidMount() {
    onSnapshot(doc(firestore, "Prosthodontics", "Achievers"), (doc) => {
      if (doc.exists()) {
        console.log("doc", doc);
        console.log("docData", doc.data());
        Object.entries(doc.data()).forEach(([key, value]) => {
          this.dynamicData.Achievers.push(Object.assign({ Id: key }, value));
        });

        this.setState({
          Achievers: this.dynamicData.Achievers,
        });
      } else {
        // this.setState({
        //     Data: []
        // })
      }
    });
  }

  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row prosthodontics_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Prosthodontics and Crown and Bridge
                    </h4>

                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Prosthodontics and Crown and Bridge</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.row */}
            </div>
            {/* /.page-title-captions */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h2 className="title">About Department</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="ttm_single_image-wrapper mb-35">
                    <img
                      className="img-fluid"
                      src={image1}
                      alt="single-img-twelve"
                    />
                  </div>
                  <div className="ttm-service-description">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header">
                        <h2 className="title">
                          Prosthodontics and <span>Crown and Bridge</span>{" "}
                        </h2>
                      </div>
                    </div>
                    {/* section title end */}
                    <p className="text-justify">
                      The department is currently located on the first floor of
                      the Dental college building. The department caters to the
                      needs of the people who have lost their teeth and/or the
                      oro-facial structures either due to disease or trauma by
                      artificial substitutes. The clinical work of the
                      department is broadly managed under the following
                      divisions: Complete Denture Prosthodontics. Removable
                      Partial Denture Prosthodontics. Fixed Partial Denture
                      Prosthodontics. Maxillo- Facial Prosthodontics. Implant
                      Prosthodontics.
                    </p>

                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Vision</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <p className="mb-35 res-991-mb-20">
                          To achieve quality in higher education through
                          evaluation, promotion and sustenance initiative .
                        </p>
                      </div>
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <img
                            className="vision-block"
                            src={image2}
                            alt="single-img-sixteen"
                          />
                        </div>
                      </div>
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
                          <img
                            className="vision-block"
                            src={image3}
                            alt="single-img-seventeen"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Mission</h2>
                          </div>
                        </div>
                        {/* section title end */}{" "}
                        <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                          <li>
                            {" "}
                            <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                            To arrange periodic assessment of academic
                            activities
                          </li>
                          <li>
                            {" "}
                            <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                            To improve the quality of teaching, learning and
                            research in higher education
                          </li>
                          <li>
                            {" "}
                            <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                            To promote self-evaluation and innovation in higher
                            education
                          </li>
                          <li>
                            {" "}
                            <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                            To achieve quality in patient care
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Objectives</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <ul>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              To promote training programs, research and to
                              improve standards in patient care{" "}
                            </span>
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              To create public awareness in quality of health as
                              related to prosthodontics{" "}
                            </span>{" "}
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              To provide society with professionals who can
                              improve the quality of life of patient by
                              providing good oral health care.
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <img
                            className="vision-block"
                            src={image4}
                            alt="single-img-sixteen"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              <div className="col-lg-3 widget-area">
                <ProsthodonticsSidebar />
              </div>
            </div>
            {/* row end */}
          </div>
          <section className=" services-slide-section ttm-bgcolor-grey ttm-bg ttm-bgimage-yes bg-img14 clearfix">
            <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
            <div className="container-xl">
              <div className="row align-items-center">
                {/* row */}
                <div className="col-lg-9 col-xl-9 col-md-9">
                  {/* section-title */}
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        Course<span> Offered</span>
                      </h2>
                    </div>
                  </div>
                  {/* section-title end */}
                </div>
              </div>
              <Slider
                className="position-relative z-1 row slick_slider ttm-boxes-spacing-30px"
                {...slick_slider}
                slidesToShow={2}
              >
                <div className="ttm-box-col-wrapper">
                  {/* featured-imagebox-services */}
                  <div className="featured-imagebox featured-imagebox-services style1">
                    <div className="featured-thumbnail">
                      {/* featured-thumbnail */}
                      <img className="img-fluid" src={image2} alt="image" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        {/* featured-title */}
                        <h5>BDS</h5>
                      </div>

                      <a
                        href={pdf}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      >
                        download Syllabus
                      </a>
                    </div>
                  </div>
                  {/* featured-imagebox-services */}
                </div>
                <div className="ttm-box-col-wrapper">
                  {/* featured-imagebox-services */}
                  <div className="featured-imagebox featured-imagebox-services style1">
                    <div className="featured-thumbnail">
                      {/* featured-thumbnail */}
                      <img className="img-fluid" src={image3} alt="image" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        {/* featured-title */}
                        <h5>MDS</h5>
                      </div>
                      <a
                        href={docx}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      >
                        Download Syllabus
                      </a>
                    </div>
                  </div>
                  {/* featured-imagebox-services */}
                </div>
              </Slider>
              <div className="ttm-row tab-section clearfix">
                <div className="container-xl">
                  <div className="row">
                    <div className="col-12">
                      <div className="section-title with-desc text-left clearfix">
                        <div className="title-header">
                          <h2 className="title">
                            Achievers in Department of{" "}
                            <span>Prosthodontics</span>
                          </h2>
                        </div>
                      </div>
                      {/* section title end */}
                      <div className="ttm-tabs text-center ttm-tab-style-classic style2">
                        <div className="content-tab">
                          <div className="row multi-columns-row ttm-boxes-spacing-5px">
                            {this.dynamicData.Achievers === undefined ? (
                              <NoData />
                            ) : (
                              this.dynamicData.Achievers.length &&
                              this.dynamicData.Achievers.map(function (
                                item,
                                index
                              ) {
                                return (
                                  <div
                                    className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6"
                                    key={index}
                                  >
                                    {/* featured-imagebox */}
                                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                                      {/* featured-thumbnail */}
                                      <div className="featured-thumbnail">
                                        <img
                                          className="img-fluid"
                                          src={item.Image}
                                          alt="image"
                                          style={{
                                            width: "360px",
                                            height: "390px",
                                          }}
                                        />
                                      </div>
                                      {/* featured-thumbnail end*/}
                                      <div className="featured-content">
                                        <div className="category">
                                          <p>Name : {item.Name}</p>
                                        </div>
                                        <div className="category">
                                          <p>
                                            Achievements : {item.Achievements}
                                          </p>
                                        </div>
                                        <div className="category">
                                          <p>
                                            Description : {item.Description}
                                          </p>
                                        </div>
                                        <div className="category">
                                          <p>Year : {item.Year}</p>
                                        </div>
                                      </div>
                                    </div>
                                    {/* featured-imagebox */}
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Pronews />
        </div>
      </div>
    );
  }
}

export default Prosthodontics;
