import React, { Component } from "react";
import image from "../../../../images/Conservative/HOD.jpg";
import { NavLink } from "react-router-dom";

export class ConservativeSidebar extends Component {
  render() {
    return (
      <div>
        <div className="widget widget-nav-menu">
          <ul className="widget-menu">
            <li><NavLink to={process.env.PUBLIC_URL + "/ConservativeDentistry"}>About</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/ConsFaculties"}>Faculties and Staff</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/ConsTeaching"}>Teaching - Learning</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/ConsResearch"}>Research and Innovations</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/ConsActivities"}>Activities</NavLink></li>
          </ul>
        </div>
        <div className="widget ">
          <div className="section-title with-desc text-left clearfix">
            <div className="title-header">
              <h2 className="title">Head of the <span>department</span></h2>
            </div>
          </div>
          <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
            <img className="d-block w-100" src={image} alt="img" />
          </div>
          <h5 style={{ color: "black" }}>Dr R Anitha Kumari</h5>
          <p>Professor and HOD</p>
          <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
            <li><i class="fas fa-star"/>Experience : 21 years of UG and 19 years PG teaching experience</li>
            <li><i class="fas fa-star"/>Qualification : BDS, MDS in Conservative Dentistry and Endodontics</li>
          </ul>  
        </div>
        <div className="widget widget-download">
          <h3 className="widget-title">Contact</h3>
          <ul className="download">
            <li className="header">
              <i className="fa fa-phone" />
              080-22426705
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default ConservativeSidebar;
