import React, { Component } from "react";
import image from "../../../../images/Peadodontics/HOD.jpg";
import { NavLink } from "react-router-dom";

export class PaedodonticsSidebar extends Component {
  render() {
    return (
      <div>
        <div className="widget widget-nav-menu">
          <ul className="widget-menu">
            <li><NavLink to={process.env.PUBLIC_URL + "/Paedodontics"}>About</NavLink></li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/PaedodonticsFaculties"}>
                Faculties and Staff
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/PaedodonticsTeaching"}>
                Teaching - Learning
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/PaedodonticsResearch"}>
                Research and Innovations
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/PaedodonticsActivities"}>
                Activities
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="widget ">
          <div className="section-title with-desc text-left clearfix">
            <div className="title-header">
              <h2 className="title">
                Head of the <span>department</span>
              </h2>
            </div>
          </div>
          <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
            <img className="d-block w-100" src={image} alt="img" />
          </div>

          <h4>DR. SUMA G</h4>
          <p>Professor and HOD</p>
          <h6>Education and Qualification:</h6>
          <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
            <li><i class="fas fa-star"></i>BDS</li>
            <li><i class="fas fa-star"></i>MDS</li>
          </ul>
          <br />
          <h6>Work Experience: </h6>
          <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
            <li>
              <i class="fas fa-star"></i>Experience 16 years</li>
          </ul>
        </div>
        <div className="widget widget-download">
          <h3 className="widget-title">Contact</h3>
          <ul className="download">
            <li>
              <i className="fa fa-phone" />
              <a> 080-22426705</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default PaedodonticsSidebar;
