import React, { Component } from 'react';

export class Details extends Component {
    render() {
        return (
            <div className="ttm-pf-single-detail-box mb-35 bottomzero-padding-section">
                <div className="ttm-pf-single-title">
                <h5>Primary Facilities</h5>
                </div>
                <ul className="ttm-pf-detailbox-list">
                
                <li><i className="far fa-bookmark" /><span> Hostel accommodation for Boys and Girls </span></li>
                <li><i className="far fa-bookmark" /><span>Canteen</span></li>
                <li><i className="far fa-bookmark" /><span>ATM & Canara Bank  </span></li>
                <li><i className="far fa-bookmark" /><span> Stationery and Photocopy shop  </span></li>
                <li><i className="far fa-bookmark" /><span> Pharmacy shop  </span></li>
                <li><i className="far fa-bookmark" /><span> College Bus facility for transportation </span></li>
                <li><i className="far fa-bookmark" /><span>Indoor sports facility  </span></li>
                </ul>
                <div className="ttm-pf-single-title">
                <h5>Other Facilities</h5>
                </div>
                <ul className="ttm-pf-detailbox-list">
                
                <li><i className="far fa-bookmark" /><span>  Drinking water facility </span></li>
                <li><i className="far fa-bookmark" /><span>Audio-visual Room </span></li>
                <li><i className="far fa-bookmark" /><span>Parking facility for staff and students  </span></li>
                </ul>
            </div>
            )
    }
}


export default Details;