import React, { Component } from "react";
import ConservativeSidebar from "./ConservativeSidebar";
import Slider from "react-slick";
import Header from "../../../layout/Header";
import pdf1 from "../../../../assets/Conservative/IVBDS.pdf";
import pdf2 from "../../../../assets/Conservative/REVISEDORDINANCEPhD.pdf";
import docx from "../../../../assets/Conservative/ConservativeSyllabus.docx";
import image1 from "../../../../images/Conservative/image1.JPG";
import image2 from "../../../../images/Conservative/image2.JPG";
import image3 from "../../../../images/Conservative/image3.JPG";
import image4 from "../../../../images/Conservative/image4.JPG";
import firestore from "../../../../Config/Config";
import { collection, getDocs, doc, onSnapshot } from "firebase/firestore";
import ConsNews from "./ConsNews";
import NoData from "../../../pages/NoData";

export class ConservativeDentistry extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Achievers: [],
    };

    this.state = {
      Achievers: [],
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    onSnapshot(doc(firestore, "Conservative", "Achievers"), (doc) => {
      if (doc.exists()) {
        Object.entries(doc.data()).forEach(([key, value]) => {
          this.dynamicData.Achievers.push(Object.assign({ Id: key }, value));
        });
        this.setState({
          Achievers: this.dynamicData.Achievers,
        });
      } else {
        // this.setState({
        //     Data: []
        // })
      }
    });
  }
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row conservative_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading  card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Conservative Dentistry and Endodontics
                    </h4>

                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Conservative Dentistry and Endodontics</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.row */}
            </div>
            {/* /.page-title-captions */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        About <span>Department</span>
                      </h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="ttm_single_image-wrapper mb-35">
                    <img
                      className="img-fluid"
                      src={image1}
                      alt="single-img-twelve"
                    />
                  </div>
                  <div className="ttm-service-description">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header">
                        <h2 className="title">
                          Conservative <span>Dentistry</span>{" "}
                        </h2>
                      </div>
                    </div>
                    {/* section title end */}
                    <p>
                      The Department of Conservative Dentistry and Endodontics
                      offers a wide variety of services from treatment of
                      decayed teeth to esthetic treatment options. Endodontic
                      treatment is the most commonly done procedure for patients
                      reporting with pain. During these pandemic times,
                      emergency services of our department had been kept open.
                      Salient features of the Department: • We are well equipped
                      with dental training simulators for UG and PG pre-clinical
                      work. • We are equipped with Dental Operating Microscope
                      (DOM) for Micro-esthetics and Micro-endodontic procedures.
                      • We also have “Studio Esthetique”, an exclusive section
                      for management of esthetic procedures and smile makeovers.
                      • It is one of the most sought-after Specialty and College
                      by BDS and MDS aspirants. • We are one of the large
                      departments to teach and guide from First to Final BDS,
                      according to the curriculum. • All the staff in the
                      Department have upgraded their Professional expertise and
                      knowledge on the subject which has benefitted the
                      students.
                    </p>

                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Vision</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <p className="mb-35 res-991-mb-20 text-justify">
                          {" "}
                          Provide quality education and training in Restorative,
                          Esthetic and Functional dentistry including
                          Endodontics on par with international standards. To
                          provide excellent patient care. To keep abreast with
                          current clinical, academic and research advances .
                        </p>
                      </div>
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <img
                            className="vision-block"
                            src={image2}
                            alt="single-img-sixteen"
                          />
                        </div>
                      </div>
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
                          <img
                            className="vision-block"
                            src={image3}
                            alt="single-img-seventeen"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Mission</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <ul>
                          <li>
                            {" "}
                            <i className="far fa-circle ttm-textcolor-skincolor" />{" "}
                            To increase awareness in patients about overall oral
                            functional health.
                          </li>
                          <li>
                            {" "}
                            <i className="far fa-circle ttm-textcolor-skincolor" />{" "}
                            To train UG and PG students to develop professional
                            attitude and skills in the field of restorations,
                            correction of esthetics, functional rehabilitation
                            of both vital and root canal treated teeth.
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />{" "}
                            To encourage professional attitude and skills to
                            reach towards excellent patient care.{" "}
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />{" "}
                            To encourage research and promote the development of
                            innovative treatment strategies.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Objectives</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <ul>
                          <li>
                            {" "}
                            <i className="far fa-circle ttm-textcolor-skincolor" />{" "}
                            To diagnose, prevent, intervene and restore caries
                            and other tooth defects in a timely, efficient and
                            esthetically pleasing manner.
                          </li>
                          <li>
                            {" "}
                            <i className="far fa-circle ttm-textcolor-skincolor" />{" "}
                            To train our UG students to achieve competency in
                            restoration, rehabilitation and maintenance of
                            dentition.{" "}
                          </li>
                          <li>
                            {" "}
                            <i className="far fa-circle ttm-textcolor-skincolor" />{" "}
                            To train our PG students to handle patient care at a
                            specialty level with proficiency and knowledge on
                            latest techniques and equipment.{" "}
                          </li>
                          <li>
                            {" "}
                            <i className="far fa-circle ttm-textcolor-skincolor" />{" "}
                            To encourage research and promote development of
                            innovative treatment strategies.{" "}
                          </li>
                          <li>
                            {" "}
                            <i className="far fa-circle ttm-textcolor-skincolor" />{" "}
                            To keep abreast with current clinical and academic
                            advancements through Continued Dental Education
                            (CDE) Programs and workshops.
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <img
                            className="img-fluid"
                            src={image4}
                            alt="single-img-sixteen"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              <div className="col-lg-3 widget-area">
                <ConservativeSidebar />
              </div>
            </div>
            {/* row end */}
          </div>
          <section className=" services-slide-section ttm-bgcolor-grey ttm-bg ttm-bgimage-yes bg-img14 clearfix">
            <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
            <div className="container-xl">
              <div className="row align-items-center">
                {/* row */}
                <div className="col-lg-9 col-xl-9 col-md-9">
                  {/* section-title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        {" "}
                        Course<span> Offered</span>
                      </h2>
                    </div>
                  </div>
                  {/* section-title end */}
                </div>
              </div>
              <Slider
                className="position-relative z-1 row slick_slider ttm-boxes-spacing-30px"
                {...slick_slider}
                slidesToShow={3}
              >
                <div className="ttm-box-col-wrapper">
                  {/* featured-imagebox-services */}
                  <div className="featured-imagebox featured-imagebox-services style1">
                    <div className="featured-thumbnail">
                      {/* featured-thumbnail */}
                      <img className="img-fluid" src={image1} alt="image" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        {/* featured-title */}
                        <h5>BDS</h5>
                      </div>
                      <a
                        href={pdf1}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      >
                        download Syllabus
                      </a>
                    </div>
                  </div>
                  {/* featured-imagebox-services */}
                </div>
                <div className="ttm-box-col-wrapper">
                  {/* featured-imagebox-services */}
                  <div className="featured-imagebox featured-imagebox-services style1">
                    <div className="featured-thumbnail">
                      {/* featured-thumbnail */}
                      <img className="img-fluid" src={image2} alt="image" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        {/* featured-title */}
                        <h5>MDS</h5>
                      </div>

                      <a
                        href={docx}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      >
                        Download Syllabus
                      </a>
                    </div>
                  </div>
                  {/* featured-imagebox-services */}
                </div>
                <div className="ttm-box-col-wrapper">
                  {/* featured-imagebox-services */}
                  <div className="featured-imagebox featured-imagebox-services style1">
                    <div className="featured-thumbnail">
                      {/* featured-thumbnail */}
                      <img className="img-fluid" src={image3} alt="image" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        {/* featured-title */}
                        <h5>PHD</h5>
                      </div>

                      <a
                        href={pdf2}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      >
                        download Syllabus
                      </a>
                    </div>
                  </div>
                  {/* featured-imagebox-services */}
                </div>
              </Slider>
              <div className="ttm-row tab-section clearfix">
                <div className="container-xl">
                  <div className="row">
                    <div className="col-12">
                      <div className="section-title with-desc text-left clearfix">
                        <div className="title-header">
                          <h2 className="title">Achievers</h2>
                        </div>
                      </div>
                      {/* section title end */}
                      <div className="ttm-tabs text-center ttm-tab-style-classic style2">
                        <div className="content-tab">
                          <div className="row multi-columns-row ttm-boxes-spacing-5px">
                            {this.state.Achievers === undefined ? (
                              <NoData />
                            ) : (
                              this.state.Achievers.length &&
                              this.state.Achievers.map(function (item, index) {
                                return (
                                  <div
                                    className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6"
                                    key={index}
                                  >
                                    {/* featured-imagebox */}
                                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                                      {/* featured-thumbnail */}
                                      <div className="featured-thumbnail">
                                        <img
                                          className="img-fluid"
                                          src={item.Image}
                                          alt="image"
                                          style={{
                                            width: "360px",
                                            height: "390px",
                                          }}
                                        />
                                      </div>
                                      {/* featured-thumbnail end*/}
                                      <div className="featured-content">
                                        <div className="category">
                                          <p>Name : {item.Name}</p>
                                        </div>
                                        <div className="category">
                                          <p>
                                            Achievements : {item.Achievements}
                                          </p>
                                        </div>
                                        <div className="category">
                                          <p>
                                            Description : {item.Description}
                                          </p>
                                        </div>
                                        <div className="category">
                                          <p>Year : {item.Year}</p>
                                        </div>
                                      </div>
                                    </div>
                                    {/* featured-imagebox */}
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <ConsNews />
        </div>
      </div>
    );
  }
}

export default ConservativeDentistry;
