import React, { useEffect, useState } from "react";
import { useWindowScroll } from "react-use";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { y: pageYOffset } = useWindowScroll();
  const [visble, setVisibility] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pageYOffset > 300) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  }, [pageYOffset]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const ScrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  if (!visble) {
    return false;
  }

  return (
    <div className="Scrolltop bounce" onClick={ScrollToTop}>
      <i className="fa fa-angle-up" />
    </div>
  );
};

export default ScrollToTop;
