import React, { Component } from 'react';
import Header from '../layout/Header';

export class Contact_01 extends Component {
    render() {
        return (
            <div className="site-main">
                <Header />
                <div className="ttm-page-title-row contact-banner">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title-box text-center">
                                    <div className="page-title-heading card_box">
                                        <h4 className="title" style={{ color: "white" }}>Contact Us</h4>
                                        <div className="ttm-color-home">
                                            <span>
                                                <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                                            </span>
                                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                                            <span>Contact</span>
                                        </div>
                                    </div>
                                </div>{/* /.col-md-12 */}
                            </div>{/* /.page-title-captions */}
                        </div>{/* /.row */}
                    </div>{/* /.container */}
                </div>
                {/* page-title end*/}
                <section className="ttm-row zero-padding-section clearfix">
                    <div className="container-xl">
                        <div className="row no-gutters">{/* row */}
                            <div className="col-lg-5">
                                <div className="spacing-9">
                                    {/* section title */}
                                    <div className="section-title with-desc clearfix">
                                        <div className="title-header">
                                            <h5>Come Visit Us At</h5>
                                            <h2 className="title">Address</h2>
                                        </div>
                                    </div>{/* section title end */}
                                    <div className="row">
                                        <div className="col-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                        <i className="ti-location-pin" />
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5> Address</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p>V S Dental College & Hospital K.R.Road, V.V.Puram, Bengaluru, Karnataka - 560004, India</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-4 mb-4" />
                                    </div>
                                    {/* separator */}
                                    <div className="row">
                                        <div className="col-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                        <i className="fa fa-phone" />
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5> Phone Number</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p>080-26618066</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-4 mb-4" />
                                    </div>
                                    {/* separator */}
                                    <div className="row">
                                        <div className="col-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                        <i className="ti ti-email" />
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5> Email</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p>vsdchbangalore@gmail.com Fax: +91-80-22426705 </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                        <div className="ttm-bg-layer-inner" />
                                    </div>
                                    {/* section title */}
                                    <div className="section-title with-desc clearfix">
                                        <div className="title-header">
                                            <h5>Send Message</h5>
                                            <h2 className="title">Drop Us A Line</h2>
                                        </div>
                                    </div>{/* section title end */}
                                    <form id="ttm-quote-form" className="row ttm-quote-form clearfix" method="post" action="#">
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="name" type="text" className="form-control bg-white" placeholder="Full Name*" required="required" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="phone" type="text" placeholder="Phone Number*" required="required" className="form-control bg-white" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="address" type="text" placeholder="Email Address*" required="required" className="form-control bg-white" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="subject" type="text" placeholder="Subject" required="required" className="form-control bg-white" />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <textarea name="Massage" rows={5} placeholder="Write A Massage..." required="required" className="form-control bg-white" defaultValue={""} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="text-left">
                                                <button type="submit" id="submit" className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor" value>
                                                    <a href="mailto:vsdchbangalore@gmail.com" className='header'>Submit</a>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* row end */}
                    </div>
                </section>
                <section className="ttm-row map-section res-991-p-0 clearfix">
                    <div className="map-wrapper">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7776.54924441309!2d77.575289!3d12.954271!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa7fe8d29e8c308b9!2sVokkaligara%20Sangha%20Dental%20College%20%26%20Hospital!5e0!3m2!1sen!2sin!4v1638011850691!5m2!1sen!2sin" width="600" height="450" allowFullScreen="" loading="lazy"></iframe>                    </div>
                </section>
            </div>
        )
    }
}


export default Contact_01;