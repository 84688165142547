import React, { Component } from "react";
import PublicHealthSidebar from "./PublicHealthSidebar";
import Slider from "react-slick";
import Header from "../../../layout/Header";
import image1 from "../../../../images/PublicHealth/image1.JPG";
import image2 from "../../../../images/PublicHealth/image2.JPG";
import image3 from "../../../../images/PublicHealth/image3.JPG";
import image4 from "../../../../images/PublicHealth/image4.jpg";
import image5 from "../../../../images/PublicHealth/image6.JPG";
import image6 from "../../../../images/PublicHealth/image7.JPG";
import pdf from "../../../../assets/PublicHealth/BDS1.pdf";
import docx from "../../../../assets/PublicHealth/MDSSyllabus.docx";
import firestore from "../../../../Config/Config";
import { collection, getDocs, onSnapshot, doc } from "firebase/firestore";
import PHDnews from "./PHDnews";
import NoData from "../../../pages/NoData";

export class PublicHealth extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Achievers: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    onSnapshot(doc(firestore, "PublicHealth", "Achievers"), (doc) => {
      if (doc.exists()) {
        Object.entries(doc.data()).forEach(([key, value]) => {
          this.dynamicData.Achievers.push(Object.assign({ Id: key }, value));
        });

        this.setState({
          Achievers: this.dynamicData.Achievers,
        });
      } else {
        // this.setState({
        //     Data: []
        // })
      }
    });
  }
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row publichealth_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Public Health Dentistry
                    </h4>
                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Public Health Dentistry</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
            </div>
            {/* /.page-title-captions */}
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h2 className="title">About Department</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="ttm_single_image-wrapper mb-35">
                    <img
                      className="img-fluid"
                      src={image1}
                      alt="single-img-twelve"
                    />
                  </div>
                  <div className="ttm-service-description">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header">
                        <h2 className="title">
                          Public Health <span>Dentistry</span>{" "}
                        </h2>
                      </div>
                    </div>
                    {/* section title end */}
                    <p className="text-justify">
                      Department of Public Health Dentistry is a para-clinical
                      specialty of dentistry that deals with the prevention of
                      oral disease and promotion of oral health. The Department
                      of Public Health Dentistry is the buffer between the
                      community and the Institution and has a state-of-the-art
                      air-conditioned mobile dental clinic.{" "}
                    </p>
                    <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                      <li>
                        {" "}
                        1. Largest number of community development programs in
                        Bengaluru rural and urban district, that cater to the
                        community by providing free dental treatment
                      </li>
                      <li>
                        {" "}
                        2. Oral Health Museum and education center for oral
                        health awareness’s and education for the general public.
                      </li>
                      <li>
                        {" "}
                        3. Oldest Dedicated Tobacco Cessation Centre running
                        since 2010 providing cognitive behavioral therapy for
                        patients with tobacco habits.
                      </li>
                      <li>
                        {" "}
                        4. Longest running School Oral Health Program with 6
                        adopted schools providing preventive care and treatment
                        for dental diseases for underprivileged school children.
                      </li>
                      <li>
                        {" "}
                        5. Community health training of students through
                        effective Role Plays, education videos and talks for the
                        public. Collaborations - Satellite centers: •
                        Established in collaboration with BBMP Maternity
                        Hospital at Shantinagara, Government of Karnataka. •
                        Shrigandhada Kavalu satellite centre for catering to
                        peri-urban population of Bengaluru. • Induction and
                        Orientation program towards dentistry for sister
                        Institutions of Vokkaligara Sangha.
                      </li>
                      <li>
                        {" "}
                        6. Oral Health Outreach Programs of the Department 1.
                        Oral health screening and treatment camps 2. School oral
                        health screening and treatment camps 3. School
                        preventive programs 4. Satellite centres-2  Shantinagar
                        centre  Srigandakavalu centre 5. Oral health education
                        and awareness programs School oral health programmes
                        List of adopted schools 1. Niveditha vidya peetha,
                        sharada mutt, bangalore started in 2001 2. Sri sajjan
                        rao vidya samasthe school, vv puram, bangalore started
                        in 2002 3. Ssk school, bengaluru started in 2003 4. Sri
                        balaganghadhar swamy school for blind, ramnagar started
                        in 2008 5. Jsvk school,chennenahalli started in 2008 6.
                        Vokkaligara sangha school, srigandadakavlu started in
                        2013 Special care institutions:- 1. Sri balaganghadhar
                        swamy school for blind, ramanagara. 2. Sri sajjan rao
                        vidya samasthe school for special children, vv puram,
                        bangalore.
                      </li>
                    </ul>

                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Vision</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <p className="mb-35 res-991-mb-20">
                          Striving to achieve optimum oral health of the
                          community through application of scientific knowledge
                          and clinical skills of Public Health Dentistry. .
                        </p>
                      </div>
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <img
                            className="vision-block"
                            src={image2}
                            alt="single-img-twelve"
                          />
                        </div>
                      </div>
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
                          <img
                            className="vision-block"
                            src={image3}
                            alt="single-img-twelve"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Mission</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <p className="mb-35 res-991-mb-20 text-justify">
                          Development of comprehensive oral health programs to
                          cater to unmet needs of the community through
                          effective collaboration with external stakeholders.
                          Enabling students to understand the unique rules and
                          responsibilities sin preventing oral diseases and
                          promoting oral health of individuals and communities.
                          Promoting of interdisciplinary community based on oral
                          health care research. Competency enhancement of
                          faculty members to help them realize their career
                          objectives.
                        </p>
                      </div>
                    </div>
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Objectives</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                          <li>
                            {" "}
                            <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                            To organize oral health programmes for delivering
                            comprehensive oral health care and health education
                            to various sections of the society and form
                            partnership with external agencies for improving
                            oral health of the community.{" "}
                          </li>
                          <li>
                            {" "}
                            <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />{" "}
                            To develop evidence based preventive dentistry
                            competencies in U.G and P.G students through
                            didactic and experimental methods.
                          </li>
                          <li>
                            {" "}
                            <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />{" "}
                            To engage students in health education activities
                            like development of health education material,
                            communicating and interlacing with various sections
                            of th society and tobacco cessation counseling.{" "}
                          </li>
                          <li>
                            {" "}
                            <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                            To provide high quality and ethical comprehensive
                            oral health care to the patients with emphasis on
                            prevention.{" "}
                          </li>
                          <li>
                            {" "}
                            <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                            To promote research in the field of Public Health
                            Dentistry.
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <img
                            className="vision-block"
                            src={image4}
                            alt="single-img-sixteen"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              <div className="col-lg-3 widget-area">
                <PublicHealthSidebar />
              </div>
            </div>
            {/* row end */}
          </div>
          <section className=" services-slide-section ttm-bgcolor-grey ttm-bg ttm-bgimage-yes bg-img14 clearfix zero-padding-section">
            <div className="container-xl">
              <div className="row align-items-center">
                {/* row */}
                <div className="col-lg-9 col-xl-9 col-md-9">
                  {/* section-title */}
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        {" "}
                        Course<span> Offered</span>
                      </h2>
                    </div>
                  </div>
                  {/* section-title end */}
                </div>
              </div>
              <Slider
                className="position-relative z-1 row slick_slider ttm-boxes-spacing-30px"
                {...slick_slider}
                slidesToShow={2}
              >
                <div className="ttm-box-col-wrapper">
                  {/* featured-imagebox-services */}
                  <div className="featured-imagebox featured-imagebox-services style1">
                    <div className="featured-thumbnail">
                      {/* featured-thumbnail */}
                      <img className="img-fluid" src={image5} alt="image" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        {/* featured-title */}
                        <h5>BDS</h5>
                      </div>

                      <a
                        href={pdf}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      >
                        download Syllabus
                      </a>
                    </div>
                  </div>
                  {/* featured-imagebox-services */}
                </div>
                <div className="ttm-box-col-wrapper">
                  {/* featured-imagebox-services */}
                  <div className="featured-imagebox featured-imagebox-services style1">
                    <div className="featured-thumbnail">
                      {/* featured-thumbnail */}
                      <img className="img-fluid" src={image6} alt="image" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        {/* featured-title */}
                        <h5>MDS</h5>
                      </div>

                      <a
                        href={docx}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      >
                        Download Syllabus
                      </a>
                    </div>
                  </div>
                  {/* featured-imagebox-services */}
                </div>
              </Slider>
              <div className="row tab-section clearfix">
                <div className="container-xl">
                  <div className="row">
                    <div className="col-12">
                      <div className="section-title with-desc text-left clearfix zero-padding-section ">
                        <div className="title-header">
                          <h2 className="title">
                            Achievers in Department of{" "}
                            <span>Public Health Dentistry</span>
                          </h2>
                        </div>
                      </div>
                      {/* section title end */}
                      <div className="ttm-tabs text-center ttm-tab-style-classic style2">
                        <div className="content-tab">
                          <div className="row multi-columns-row ttm-boxes-spacing-5px">
                            {this.dynamicData.Achievers === undefined ? (
                              <NoData />
                            ) : (
                              this.dynamicData.Achievers.length &&
                              this.dynamicData.Achievers.map(function (
                                item,
                                index
                              ) {
                                return (
                                  <div
                                    className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6"
                                    key={index}
                                  >
                                    {/* featured-imagebox */}
                                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                                      {/* featured-thumbnail */}
                                      <div className="featured-thumbnail">
                                        <img
                                          className="img-fluid"
                                          src={item.Image}
                                          alt="image"
                                          style={{
                                            width: "360px",
                                            height: "390px",
                                          }}
                                        />
                                      </div>
                                      {/* featured-thumbnail end*/}
                                      <div className="featured-content">
                                        <div className="category">
                                          <p>Name : {item.Name}</p>
                                        </div>
                                        <div className="category">
                                          <p>
                                            Achievements : {item.Achievements}
                                          </p>
                                        </div>
                                        <div className="category">
                                          <p>
                                            Description : {item.Description}
                                          </p>
                                        </div>
                                        <div className="category">
                                          <p>Year : {item.Year}</p>
                                        </div>
                                      </div>
                                    </div>
                                    {/* featured-imagebox */}
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <PHDnews />
        </div>
      </div>
    );
  }
}

export default PublicHealth;
