import React, { Component } from "react";
import OralMaxilSidebar from "./OralMaxilSidebar";
import Header from "../../../layout/Header";
import Slider from "react-slick";
import firestore from "../../../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import NoData from "../../../pages/NoData";

export class PathologyActivities extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Activties: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "OMFP");
    const activitieskey = "Activties";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      console.log(Snapshot);
      const allDynamicData = this.getDynamicData(Snapshot);
      console.log(allDynamicData);
      this.dynamicData.Activties = allDynamicData[activitieskey];
      //this.dynamicData.FreshersDay = allDynamicData[freshersdaykey];

      console.log("test data");

      console.log(this.dynamicData.Activties);
    }
    this.setState({});
  }

  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row pathology_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Activities
                    </h4>

                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Oral Maxillo Facial Pathology</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.row */}
            </div>
            {/* /.page-title-captions */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="container-xl">
                  <div className="row align-items-center">
                    {/* row */}
                    <div className="col-lg-9 col-xl-9 col-md-9">
                      {/* section-title */}
                      <div className="section-title with-desc text-center clearfix">
                        <div className="title-header">
                          <h2 className="title">
                            Activities <span></span>
                          </h2>
                        </div>
                      </div>
                      {/* section title end */}
                    </div>
                  </div>
                  <section className="ttm-row grid-section clearfix">
                    <div className="container-xl">
                      {/* row end */}
                      {/* row */}
                      <div className="row">
                        {this.dynamicData.Activties === undefined ? (
                          <NoData />
                        ) : (
                          this.dynamicData.Activties.length &&
                          this.dynamicData.Activties.map(function (
                            item,
                            index
                          ) {
                            return (
                              <div className="col-md-6 col-lg-6 " key={index}>
                                {/* featured-imagebox-services */}
                                <div className="featured-imagebox featured-imagebox-services style1 mb-30 ">
                                  <div className="featured-thumbnail">
                                    {/* featured-thumbnail */}
                                    <img
                                      className="img-fluid "
                                      src={item.Image}
                                      alt="image"
                                      style={{
                                        width: "350px",
                                        height: "230px",
                                      }}
                                    />
                                  </div>
                                  <div className="featured-content box-shadow">
                                    <div className="featured-desc">
                                      {/* featured-title */}
                                      <p>{item.Description}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                      {/* row end*/}
                    </div>
                  </section>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <OralMaxilSidebar />
              </div>
            </div>
            {/* row end */}
          </div>
        </div>
      </div>
    );
  }
}

export default PathologyActivities;
