import React, { Component } from "react";
import PublicHealthSidebar from "./PublicHealthSidebar";
import Header from "../../../layout/Header";
import firestore from "../../../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import NoData from "../../../pages/NoData";

export class PHDFieldPrograms extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      FieldPrograms: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "PublicHealth");
    const Fieldkey = "FieldPrograms";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      const allDynamicData = this.getDynamicData(Snapshot);
      this.dynamicData.FieldPrograms = allDynamicData[Fieldkey];
    }
    this.setState({});
  }
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row publichealth_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Field Programs
                    </h4>
                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Public Health Dentistry</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
            </div>
            {/* /.page-title-captions */}
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="container-xl">
                  <div className="row align-items-center">
                    {/* row */}
                    <div className="col-lg-9 col-xl-9 col-md-9">
                      {/* section-title */}
                      <div className="section-title with-desc text-center clearfix">
                        <div className="title-header">
                          <h2 className="title">
                            Field <span>Programs</span>
                          </h2>
                        </div>
                      </div>
                      {/* section title end */}
                    </div>
                  </div>
                  <section className="ttm-row grid-section clearfix">
                    <div className="container-xl">
                      {/* row end */}
                      {/* row */}
                      <div className="row">
                        {this.dynamicData.FieldPrograms === undefined ? (
                          <NoData />
                        ) : (
                          this.dynamicData.FieldPrograms.length &&
                          this.dynamicData.FieldPrograms.map(function (
                            item,
                            index
                          ) {
                            return (
                              <div className="col-md-6 col-lg-6 " key={index}>
                                <div className="ttm-box-col-wrapper">
                                  <div className="featured-imagebox featured-imagebox-blog style2">
                                    <div className="featured-thumbnail">
                                      <img
                                        className="img-fluid"
                                        alt=""
                                        src={item.Image}
                                        style={{
                                          width: "349px",
                                          height: "240px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                    <div className="featured-content">
                                      <div className="ttm-box-post-date">
                                        <span className="ttm-entry-date">
                                          <time
                                            className="entry-date"
                                            dateTime="2019-01-16T07:07:55+00:00"
                                            style={{ width: "100px" }}
                                          >
                                            {item.Date}
                                          </time>
                                        </span>
                                      </div>
                                      <div className="featured-title">
                                        <h5>{item.Header}</h5>
                                      </div>
                                      <div className="post-meta">
                                        {item.Description}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                      {/* row end*/}
                    </div>
                  </section>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <PublicHealthSidebar />
              </div>
            </div>
            {/* row end */}
          </div>
        </div>
      </div>
    );
  }
}

export default PHDFieldPrograms;
