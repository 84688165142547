import React, { Component } from "react";
import pdf from "../../assets/ServiceRules.pdf";
import pdf3 from "../../assets/Governmentofkarnataka.pdf";
import pdf4 from "../../assets/BBMP.pdf";
import pdf1 from "../../assets/DCI.pdf";
import pdf2 from "../../assets/RGUHS.pdf";
import pdf5 from "../../assets/VsdchengRules.pdf";
import { Link } from "react-router-dom";

export class Sidebar extends Component {
  render() {
    return (
      <div>
        <div className="widget widget-nav-menu">
          <h6>VSDCH Departments</h6>
          <ul className="widget-menu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/OralMedicineRadiology"}>
                Oral Medicine and Radiology
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/PublicHealth"}>
                Public Health Dentistry
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/OralMaxilloFS"}>
                Oral-Maxillo Facial Surgery
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/Prosthodontics"}>
                Prosthodontics and Crown And Bridge
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/Periodontics"}>
                Periodontics
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/ConservativeDentistry"}>
                Conservative Dentistry and Endodontics
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/Paedodontics"}>
                Paedodontics and Preventive Dentistry
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/Orthodontics"}>
                Orthodontics and Dentofacial Orthopedics
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/OralMaxilPathology"}>
                Oral-Maxillo Facial Pathology
              </Link>
            </li>
          </ul>
        </div>
        <div className="widget widget_media_image">
          <div className="sidebarbanner_box ttm-textcolor-white text-left">
            <div
              className="featured-content fatured-content-banner "
              style={{ marginLeft: "20px" }}
            >
              <div className="featured-title ttm-box-title">
                <h5>Rules and Regulations</h5>
              </div>
              <div>
                <ul className="download">
                  <li>
                    <a
                      href={pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      style={{ marginLeft: "20px" }}
                    >
                      VSDCH Service Rules
                    </a>
                  </li>
                  <li>
                    <a
                      href={pdf5}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white "
                      style={{ paddingRight: "85px", marginLeft: "20px" }}
                    >
                      VSDCH Rules
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="widget widget-download">
          <h3 className="widget-title">Accreditation &amp; Approvals</h3>
          <ul className="download">
            <li>
              <i className="far fa-file-pdf" />
              <a
                className="ttm-btn btn-inline style2"
                href={pdf1}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                Dental counsil of India
              </a>
            </li>
            <li>
              <i className="far fa-file-pdf" />
              <a
                className="ttm-btn btn-inline style2"
                href={pdf2}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                RGUHS
              </a>
            </li>
            <li>
              <i className="far fa-file-pdf" />
              <a
                className="ttm-btn btn-inline style2"
                href={pdf3}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                Govt of karnataka
              </a>
            </li>
            <li>
              <i className="far fa-file-pdf" />
              <a
                className="ttm-btn btn-inline style2"
                href={pdf4}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                MOU with BBMP
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
