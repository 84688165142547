import React, { Component } from "react";
import Sidebar from "../Explore/Sidebar";
import Header from "../layout/Header";
import image1 from "../../images/Teachingimages/image1.JPG";
import image2 from "../../images/Teachingimages/image2.JPG";
import image3 from "../../images/Teachingimages/image3.JPG";

export class TeachingLearning extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Teaching Learning</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Home
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Teaching Learning</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h2>Teaching Learning</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="ttm_single_image-wrapper mb-35">
                    <img
                      className="img-fluid"
                      src={image1}
                      alt="single-img-twelve"
                    />
                  </div>
                  <div className="ttm-service-description">
                    <h4>BDS</h4>
                    <p>
                      BDS The educational journey of BDS students studying in
                      each year is mentored, monitored and evaluated by faculty
                      in-charge. The faculty in-charge for each year of BDS
                      students are responsible for maintaining regular teaching
                      and practical/clinical time tables, assessment of their
                      internal assessment performance, co-curricular activities
                      inside and outside campus. In addition to in-charge
                      faculty, mentors for each year of BDS are responsible for
                      overall guidance for students individual performance and
                      wellbeing during their course. Life Skills workshops are
                      conducted every year by the in-house expert faculty for
                      empowering them to take responsibility for their learning.
                      BDS students who are posted to each department of
                      clinical, non-clinical and para-clinical subjects are
                      mentored batchwise. Each batch of students posted in each
                      department will consist of around 10 students who are
                      mentored by assigned faculty throughout their posting
                      period. They are mentored and guided in clinical
                      discussions, patient care and project assignments.
                    </p>
                    <p>
                      {" "}
                      Interns: The batch of Interns are mentored by in-charge
                      faculty in their clinical postings, programs for career
                      guidance by in house faculty and community oral health
                      services
                    </p>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-8">
                        <h4>MDS</h4>
                        <p className="mb-35 res-991-mb-20">
                          {" "}
                          MDS Students pursuing MDS are trained and mentored in
                          their specialty subjects according to the syllabus
                          prescribed by RGUHS in each department. The PG
                          students are mentored by their respective PG-Guides
                          throughout their three years of postgraduation in
                          patient care, research and public health services. In
                          addition, year wise in-charge faculty, subject
                          specialty wise in-charge faculty will nurture them by
                          training them specifically as per the requirements for
                          each year and specialty oral health care. Scientific
                          temper workshops are conducted every year by the
                          in-house expert faculty for MDS students to equip them
                          with creative and critical thinking skills essential
                          for their research work and PG studies.
                        </p>
                        <button className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-bgcolor-darkgrey">
                          Read more
                        </button>
                      </div>
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <img
                            className="img-fluid"
                            src={image2}
                            alt="single-img-sixteen"
                          />
                        </div>
                      </div>
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
                          <img
                            className="img-fluid"
                            src={image3}
                            alt="single-img-seventeen"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h4>PHD</h4>
                        <p className="mb-35 res-991-mb-20">
                          The Ph.D scholars are mentored by their Ph.D guides
                          who are experienced and seasoned academicians with
                          state of the art facilities in the campus required for
                          research.
                        </p>
                        <button className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-bgcolor-darkgrey">
                          Read more
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              <div className="col-lg-3 widget-area">
                <Sidebar />
              </div>
            </div>
            {/* row end */}
          </div>
        </div>
      </div>
    );
  }
}

export default TeachingLearning;
