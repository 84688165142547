import React, { Component } from 'react';
import Header from '../../layout/Header';
import image1 from "../../../images/Bestpractices/Schoolhealth1.jpg";
import image2 from "../../../images/Bestpractices/Schoolhealth2.jpg";

export class SchoolHealth extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row bestpractices-banner">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading  card_box">
                            <h4 className="title" style={{color:"white"}}>School Health Programs</h4>
                            <div className="ttm-color-home">
                            <span>
                                <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>VSDCH</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}
                        </div>{/* /.page-title-captions */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                     
                </div>
        {/* page-title end*/}
        {/* about-services-section */}
        <section className="ttm-row about-services-section clearfix">
          <div className="container-xl">
            <div className="row align-items-center">{/* row */}
            <div className="section-title with-desc clearfix">
                            <div className="title-header">                        
                            <h2 className="title">School Health <span> Programs</span></h2>
                            </div>
                           
                        </div>{/* section title end */}
                        {/* row */}
              <p className='text-justify'>Public Health Dentistry department has adopted Six Schools to cater Oral health services and is actively involved in Screening, Preventive and restorative procedures, Extractions, Health education programs and Referrals.</p>
              <div className="col-lg-6">
                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15 text-justify">
                  <h5>Adopted Schools are:</h5>
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    <span className="ttm-list-li-content"> NIVEDITHA VIDYA PEETHA, SHARADA MUTT, BANGALORE started in 2001 </span>
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    <span className="ttm-list-li-content">  SRI SAJJAN RAO VIDYA SAMASTHE SCHOOL, VV PURAM, BANGALORE started in 2002</span>
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    <span className="ttm-list-li-content"> SSK SCHOOL, BENGALURU started in 2003</span>
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    <span className="ttm-list-li-content">  SRI BALAGANGHADHAR SWAMY SCHOOL FOR BLIND, RAMNAGAR started in 2008</span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    <span className="ttm-list-li-content"> JSVK SCHOOL,CHENNENAHALLI started in 2008</span>
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    <span className="ttm-list-li-content"> VOKKALIGARA SANGHA SCHOOL, SRIGANDADAKAVLU started in 2013</span>
                  </li>
                  <p>Among These- 2 are special institutions</p>
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    <span className="ttm-list-li-content">  Sri Balaganghadhar Swamy School For Blind, Ramanagara. </span>
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                    <span className="ttm-list-li-content"> Sri Sajjan Rao Vidya Samasthe School For Special Children, VV Puram, Bangalore
                    </span>
                  </li>
                </ul>
              </div>


              <div className="col-lg-6">
                <div className="position-relative">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img className="img-fluid" src={image1} title="single-img-ten" alt="single-img-ten" />
                  </div>{/* ttm_single_image-wrapper end */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="position-relative">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img className="img-fluid" src={image2} title="single-img-ten" alt="single-img-ten" />
                  </div>{/* ttm_single_image-wrapper end */}
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
      </div>
    )
  }
}


export default SchoolHealth;