import React, { Component } from "react";
import image1 from "../../../../images/Prosthodonticsimg/image1.jpg";
import { NavLink } from "react-router-dom";

export class ProsthodonticsSidebar extends Component {
  render() {
    return (
      <div>
        <div className="widget widget-nav-menu">
          <ul className="widget-menu">
            <li><NavLink to={process.env.PUBLIC_URL + "/Prosthodontics"}>About</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/ProFaculties"}>Faculties and Staff</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/ProTeaching"}>Teaching - Learning</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/ProResearch"}>Research and Innovations</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/ProActivities"}>Activities</NavLink></li>
          </ul>
        </div>

        <div className="widget">
          <div className="section-title with-desc text-center clearfix">
            <div className="title-header">
              <h2 className="title">Head of the <span className="ttm-textcolor-blue">Department</span></h2>
            </div>
          </div>
          <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
            <img className="d-block w-100" src={image1} alt="img" />
          </div>
          <h5 style={{ color: "black" }}>Dr. Anupama N M</h5>
          <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
            <li><i class="fas fa-star"/>Experience : UG Experience : 19 yearsPG Experience : 19 yearsTotal : 19 YEARS</li>
            <li><i class="fas fa-star"/>Qualification : BDS MDS</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default ProsthodonticsSidebar;
