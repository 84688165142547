import React, { Component } from "react";
import image1 from "../../../../images/OMFS/image1.JPG";
import firestore from "../../../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import { NavLink } from "react-router-dom";

export class OralMaxilloFSsidebar extends Component {

  constructor(props) {
    super(props);

    this.dynamicData = {
      Notifications: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "OMFS");
    const notificationkey = "Notifications";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      console.log(Snapshot);
      const allDynamicData = this.getDynamicData(Snapshot);
      console.log(allDynamicData);
      this.dynamicData.Notifications = allDynamicData[notificationkey];

      console.log("test data");

      console.log(this.dynamicData.Notifications);
    }
    this.setState({});
  }

  render() {
    return (
      <div>
        <div className="widget widget-nav-menu">
          <ul className="widget-menu">
            <li><NavLink to={process.env.PUBLIC_URL + "/OralMaxilloFs"}>About</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/OMFSfaculties"}>Faculties and Staff</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/OMFSteaching"}>Teaching - Learning</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/OMFSResearch"}>Research and Innovations</NavLink></li>
            <li><NavLink to={process.env.PUBLIC_URL + "/OMFSActivities"}>Activities</NavLink></li>
          </ul>
        </div>

        <div className="widget">
          <div className="section-title with-desc text-center clearfix">
            <div className="title-header">
              <h2 className="title">Head of the <span className="ttm-textcolor-blue">Department</span></h2>
            </div>
          </div>
          <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
            <img className="d-block w-100" src={image1} alt="img" />
          </div>
          <h5 style={{ color: "black" }}>Dr Keerthi R</h5>
          <p>Professor and HOD</p>
          <h6> Education and Qualification :</h6>
          <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
            <li><i class="fas fa-star" />Experience : 19 Years</li>
            <li><i class="fas fa-star" />Qualification : BDS, MDS</li>
          </ul>
        </div>
        <div className="widget widget-download">
          <h3 className="widget-title">Contact</h3>
          <ul className="download">
            <li>
              <i className="fa fa-phone" />
              <a> 080-22426705</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default OralMaxilloFSsidebar;
