import React, { Component } from "react";
import Slider from "react-slick";
import Header from "../../layout/Header";
import image1 from "../../../images/Bestpractices/Danthabahgya3.jpg";
import image2 from "../../../images/Bestpractices/DanthaBhagya1.jpeg";
import image3 from "../../../images/Bestpractices/Danthabhagya2.jpg";
import image4 from "../../../images/Bestpractices/Danthabhagya4.jpg";
import image5 from "../../../images/Bestpractices/danthabhagya5.jpg";

export class DantaBhagyaYojane extends Component {
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row bestpractices-banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading  card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Dantha Bhagya Yojane
                    </h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          title="Homepage ttm-color-home"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">VSDCH</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="ttm-row project-single-section clearfix">
          <div className="container-xl">
            <div className="row mt-4">
              <div className="section-title with-desc clearfix">
                <div className="title-header">
                  <h2 className="title">
                    Dantha Bhagya <span> Yojane</span>
                  </h2>
                </div>
                <div className="title-desc">
                  <p className="text-justify">
                    Dept of Prosthodontics, VSDCH has signed Memorandum of
                    Understanding (MOU) with Health and Family Welfare Services,
                    Government of Karnataka on 30th of March 2015. to be part of
                    the Dhantha Bhagya Yojane, which is an oral health policy to
                    needy citizens below poverty line. Objectives of the scheme
                    are to provide complete & removable Partial dentures. Under
                    the scheme, Department has provided 25 RPDs and 172 CDs from
                    2016-2021.
                  </p>
                </div>
              </div>
              {/* section title end */}
            </div>
            {/* row end*/}
            <div className="row">
              <div className="col-lg-12 col-xl-8">
                <Slider
                  className="slick_slider project-single"
                  {...slick_slider}
                  arrows={false}
                  dots={true}
                >
                  <div className="featured-thumbnail">
                    <img src={image1} alt="DBY_image" className="img-fluid" />
                  </div>
                  <div className="featured-thumbnail">
                    <img src={image3} alt="DBY_image" className="img-fluid" />
                  </div>
                  <div className="featured-thumbnail">
                    <img src={image5} alt="DBY_image" className="img-fluid" />
                  </div>
                </Slider>
              </div>
              <div className="col-lg-12 col-sm-12 col-xl-4">
                <div className="row res-1199-mt-20">
                  <div className="col-xl-12 col-lg-6 col-md-6 col-sm-6">
                    <div className="ttm_single_image-wrapper mb-20">
                      <img
                        className="img-fluid"
                        src={image2}
                        title="single-img-twentytwo"
                        alt="single-img-twentytwo"
                      />
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-6 col-md-6 col-sm-6 mb-20">
                    <div className="ttm_single_image-wrapper">
                      <img
                        className="img-fluid"
                        src={image4}
                        title="single-img-twentythree"
                        alt="single-img-twentythree"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default DantaBhagyaYojane;
