import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from "../layout/Header";
import image from "../../images/admission_process.JPG";
import firestore from "../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import NoData from "../pages/NoData";

export class Admission extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      SeatMatrix: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "Admission");
    const seatmatrixkey = "SeatMatrix";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      console.log(Snapshot);
      const allDynamicData = this.getDynamicData(Snapshot);
      console.log(allDynamicData);
      this.dynamicData.SeatMatrix = allDynamicData[seatmatrixkey];
      //this.dynamicData.FreshersDay = allDynamicData[freshersdaykey];

      console.log("test data");

      console.log(this.dynamicData.SeatMatrix);
    }
    const arr = [];
    this.setState({ SeatMatrix: arr });
    // this.setState({FreshersDay : arr});
  }
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const styles = {
      tableContainer: {
        width: "100%",
        margin: "20px auto",
        borderCollapse: "collapse",
        border: "1px solid #ddd",
        fontFamily: "Arial, sans-serif",
      },
      th: {
        border: "1px solid #ddd",
        padding: "8px",
        textAlign: "center",
        backgroundColor: "#f2f2f2",
        fontWeight: "bold",
      },
      td: {
        border: "1px solid #ddd",
        padding: "8px",
        textAlign: "center",
      },
      header: {
        textAlign: "center",
        marginTop: "1rem",
      },
    };

    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row admission_banner ">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Admission
                    </h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">RVS</span>
                    </div>
                  </div>
                  {/* /.page-title-captions */}
                </div>
                {/* /.page-title-captions */}
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        {/* aboutus-section */}
        <section className="ttm-row aboutus-section clearfix ">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-6">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Admission <span>Process</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      We have an efficient office team which takes care of the
                      admission process. The office team will ensure smooth
                      completion of all the steps of the admission process:
                    </p>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
                <div className="row no-gutters mt-4 mb-4">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Transparent procedure{" "}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Parent friendly process{" "}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Assistance to students
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          All steps as per the rules and regulations of RGUHS
                          and DCI
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Student counselling prior to admission{" "}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Smooth collaborative assistance for hostel admission
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Notification of all updates accessible through the
                          college website{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* row END*/}
                <a className="ttm-btn ttm-bgcolor-skincolor ttm-btn-size-md mt-4">
                  VSDC Prospectus
                </a>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image}
                      title="single-img-seven"
                      alt="single-img-seven"
                    />
                  </div>
                  {/* ttm_single_image-wrapper end */}
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* aboutus-section end*/}

        <section className="ttm-row aboutus-section clearfix zero-padding-section">
          <div className=" only-one-section ttm-bgcolor-white clearfix zero-padding-section">
            <div className="container-xl">
              {/* row */}
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  <h2 className="title">
                    Seat <span>Matrix</span>
                  </h2>
                </div>
              </div>
              {/* section title end */}
              <div className="row">
                <div className="col-lg-12">
                  {/* ttm-cart-form */}
                  <form className="ttm-cart-form">
                    <table className="shop_table shop_table_responsive">
                      <thead>
                        <tr>
                          <th className="product-remove th_color">Course</th>
                          <th className="product-name th_color">Department</th>
                          <th className="product-mainprice th_color">
                            Government seats
                          </th>
                          <th className="product-quantity th_color">
                            NRI Seats
                          </th>
                          <th className="product-subtotal th_color">
                            Private seats
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.dynamicData.SeatMatrix === undefined ? (
                          <NoData />
                        ) : (
                          this.dynamicData.SeatMatrix.length &&
                          this.dynamicData.SeatMatrix.map(function (
                            item,
                            index
                          ) {
                            return (
                              <tr className="cart_item" key={index}>
                                <td
                                  className="product-name"
                                  data-title="Course"
                                >
                                  <h6>{item.Course}</h6>
                                </td>
                                <td
                                  className="product-name"
                                  data-title="Department"
                                >
                                  <h6>{item.Department}</h6>
                                </td>
                                <td
                                  className="product-name"
                                  data-title="Government"
                                >
                                  <h6>{item.Government}</h6>
                                </td>
                                <td
                                  className="product-mainprice"
                                  data-title="NRI"
                                >
                                  <h6>{item.NRI}</h6>
                                </td>
                                <td
                                  className="product-quantity"
                                  data-title="Private"
                                >
                                  <h6>{item.Private}</h6>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
              {/* row end */}
            </div>
          </div>
        </section>

        <section className="ttm-row aboutus-section clearfix zero-padding-section">
          <div className="sidebar ttm-bgcolor-white clearfix">
            <div className="container-xl">
              {/* row */}
              <div className="row">
                <div className="col-lg-12 content-area">
                  <div className="ttm-single-product-details product">
                    <div className="ttm-tabs tabs-for-single-products">
                      <Tabs>
                        <TabList>
                          <Tab>
                            <span>Eligibility</span>
                          </Tab>
                          <Tab>
                            <span>Procedure for Admission</span>
                          </Tab>
                          <Tab>
                            <span>Documents to be submitted</span>
                          </Tab>
                          <Tab>
                            <span>Contact for Admission</span>
                          </Tab>
                        </TabList>
                        <div className="content-tab">
                          <TabPanel>
                            {/* content-inner */}
                            <div className="content-inner">
                              <h2>Eligibility</h2>
                              <p>
                                {" "}
                                completion of BDS with one year rotatory
                                internship and appearance of NEET exam
                              </p>
                            </div>
                            {/* content-inner end*/}
                          </TabPanel>
                          <TabPanel>
                            {/* content-inner */}
                            <div className="content-inner">
                              <h2>Procedure for Admission</h2>
                              <p>
                                All admissions will be through NEET examination,
                                following the above mentioned documents need to
                                be produced at the time of admission.
                              </p>
                            </div>
                            {/* content-inner end*/}
                          </TabPanel>
                          <TabPanel>
                            <div className="row">
                              {" "}
                              <div className="col-lg-6">
                                {" "}
                                <div className="content-inner">
                                  <h6>BDS:Required documents</h6>
                                  <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li>1.KEA Order</li>
                                    <li>2.KEA fee paid challan</li>
                                    <li>3.Income and caste certificate</li>
                                    <li>4.10th, 12th marksheet</li>
                                    <li>
                                      5.Study certificate/ Conduct certificate
                                    </li>
                                    <li>6.Transfer certificate</li>
                                    <li>
                                      7.Student passport size photo ( 3 copies)
                                    </li>
                                    <li>8.Adhaar photocopy</li>
                                    <li>9.Affidavit</li>
                                    <li>10.Admit card</li>
                                    <li>
                                      11.Document verification certificate
                                    </li>
                                  </ul>
                                </div>{" "}
                              </div>
                              <div className="col-lg-6">
                                {" "}
                                <div className="content-inner">
                                  <h6>MDS:Required documents</h6>
                                  <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li>1.1.KEA admission order</li>
                                    <li>2.NEET score card</li>
                                    <li>3.NEET application</li>
                                    <li>4.Internship completion certificate</li>
                                    <li>5.Attempt certificate</li>
                                    <li>6.Study certificate</li>
                                    <li>7.Transfer certificate</li>
                                    <li>8.Adhaar photocopy</li>
                                    <li>
                                      9.BDS all five years original marks card
                                    </li>
                                    <li>10.Degree certificate</li>
                                    <li>11.State dental council certificate</li>
                                    <li>12.Admit card</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <h2>Contact</h2>
                              <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15 ttm-pf-detailbox-list">
                                <li>
                                  <i className="fa fa-user" />
                                  Dr. Rajkumar G C , Principal
                                </li>
                                <li>
                                  <i className="far fa-bookmark" />
                                  <span> Ph.No :</span>9845282113
                                </li>
                              </ul>
                            </div>
                          </TabPanel>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutus-section clearfix">
          <div className=" only-one-section ttm-bgcolor-white clearfix">
            <div className="container-xl">
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  <h3 className="title">
                    V.S DENTAL COLLEGE AND HOSPITAL.K.R ROAD <br /> V.V PURAM
                    BANGALORE-04
                  </h3>
                  <h5 style={styles.header}>
                    B.D.S FEE STRUCTURE FOR THE YEAR 2024-25
                  </h5>
                </div>
              </div>
              <table
                className="shop_table shop_table_responsive"
                style={{ textAlign: "center" }}
              >
                <thead>
                  <tr>
                    <th className="product-remove">Year</th>
                    <th className="product-name">Particular</th>
                    {/* <th className="product-mainprice">CET</th> */}
                    <th className="product-quantity">Comed-K</th>
                    <th className="product-subtotal">Management</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="cart_item">
                    <td className="product-remove">
                      <h6> I</h6>
                    </td>
                    <td>
                      <br />
                      Tuition Fee
                      <hr />
                      <b>Total Fee</b>
                    </td>
                    {/* <td className="product-name" data-title="Product">
                      <h6>To Be Announced by GOK</h6>
                    </td> */}
                    <td>
                      <br />
                      5,25,000/-
                      <hr />
                      <b> 5,25,000/-</b>
                    </td>
                    <td>
                      <br />
                      5,25,000/-
                      <hr />
                      <b> 5,25,000/-</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section
          className="aboutus-section clearfix"
          style={{ marginTop: "3rem" }}
        >
          <div className="only-one-section ttm-bgcolor-white clearfix">
            <div className="container-xl">
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  <h3 className="title">
                    V.S DENTAL COLLEGE AND HOSPITAL.K.R ROAD <br /> V.V PURAM
                    BANGALORE-04
                  </h3>
                  <h5 style={styles.header}>
                    FEE STRUCTURE FOR M.D.S (Post Graduate) FOR THE YEAR 2024-25
                  </h5>
                </div>
              </div>

              <table style={styles.tableContainer}>
                <thead>
                  <tr>
                    <th style={styles.th}>Year</th>
                    <th style={styles.th}>Particulars</th>
                    <th style={styles.th}>
                      Govt.(G) <br />
                      Excluding other Charges
                    </th>
                    <th style={styles.th}>
                      Private(P) <br />
                      Excluding other Charges
                    </th>
                    <th style={styles.th}>
                      MANAGEMENT NRI(N) <br />
                      Specialty
                    </th>
                    <th style={styles.th}>Excluding other Charges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={styles.td} rowSpan={3}>
                      I
                    </td>
                    <td style={styles.td}>Tuition Fee</td>
                    <td style={styles.td}>3,57,076/-</td>
                    <td style={styles.td}>6,65,684/-</td>
                    <td style={styles.td}>Oral Medicine</td>
                    <td style={styles.td}>2,15,750/-</td>
                  </tr>
                  <tr>
                    <td style={styles.td}>University fee</td>
                    <td style={styles.td}>15,750/-</td>
                    <td style={styles.td}>15,750/-</td>
                    <td style={styles.td}>Oral Surgery</td>
                    <td style={styles.td}>14,65,750/-</td>
                  </tr>
                  <tr>
                    <td style={styles.td}>Total</td>
                    <td style={styles.td}>3,72,826/-</td>
                    <td style={styles.td}>6,81,434/-</td>
                    <td style={styles.td}>Prosthodontics</td>
                    <td style={styles.td}>14,15,750/-</td>
                  </tr>
                  <tr>
                    <td style={styles.td} rowSpan={3}>
                      II
                    </td>
                    <td style={styles.td}>Tuition Fee</td>
                    <td style={styles.td}>3,57,076/-</td>
                    <td style={styles.td}>6,65,684/-</td>
                    <td style={styles.td}>Periodontics</td>
                    <td style={styles.td}>7,15,750/-</td>
                  </tr>
                  <tr>
                    <td style={styles.td}>Total</td>
                    <td style={styles.td}>3,57,076/-</td>
                    <td style={styles.td}>6,65,684/-</td>
                    <td style={styles.td}>Conservative</td>
                    <td style={styles.td}>18,15,750/-</td>
                  </tr>
                  <tr>
                    <td style={styles.td}></td>
                    <td style={styles.td}></td>
                    <td style={styles.td}></td>
                    <td style={styles.td}>Paedodontics</td>
                    <td style={styles.td}>9,15,750/-</td>
                  </tr>
                  <tr>
                    <td style={styles.td} rowSpan={3}>
                      III
                    </td>
                    <td style={styles.td}>Tuition Fee</td>
                    <td style={styles.td}>3,57,076/-</td>
                    <td style={styles.td}>6,65,684/-</td>
                    <td style={styles.td}>Orthodontics</td>
                    <td style={styles.td}>18,15,750/-</td>
                  </tr>
                  <tr>
                    <td style={styles.td}>Total</td>
                    <td style={styles.td}>3,57,076/-</td>
                    <td style={styles.td}>6,65,684/-</td>
                    <td style={styles.td}>Community</td>
                    <td style={styles.td}>2,15,750/-</td>
                  </tr>
                  <tr>
                    <td style={styles.td}></td>
                    <td style={styles.td}></td>
                    <td style={styles.td}></td>
                    <td style={styles.td}>Oral Pathology</td>
                    <td style={styles.td}>2,15,750/-</td>
                  </tr>
                </tbody>
              </table>
              <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                <h6>
                  Note: For Department of Oral Medicine, Public Health Dentistry
                  and Oral Pathology Government, Private and Management/NRI
                  seats Tuition fees fixed per year is Rs. 2,15,750/- (including
                  University fee of Rs. 15,750/- and excluding other charges)
                  for the academic year 2024-25.
                </h6>
              </div>
              <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                <h6>
                  Note : For Any Clarification contact (office) : 080-22426705
                </h6>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Admission;
