import React, { Component } from "react";
import OralMaxilloFSsidebar from "./OralMaxilloFSsidebar";
import Slider from "react-slick";
import Header from "../../../layout/Header";
import image1 from "../../../../images/OMFS/image2.JPG";
import image2 from "../../../../images/OMFS/image3.JPG";
import image3 from "../../../../images/OMFS/image4.JPG";
import pdf from "../../../../assets/OralMaxilFS/BDS.pdf";
import pdf2 from "../../../../assets/OralMaxilFS/PhD.pdf";
import docx from "../../../../assets/OralMaxilFS/MDSSyllabus.docx";
import firestore from "../../../../Config/Config";
import { collection, getDocs, onSnapshot, doc } from "firebase/firestore";
import OMFSNews from "./OMFSNews";
import NoData from "../../../pages/NoData";

export class OralMaxilloFS extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      Achievers: [],
    };

    this.state = {
      Achievers: [],
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    onSnapshot(doc(firestore, "OMFS", "Achievers"), (doc) => {
      if (doc.exists()) {
        Object.entries(doc.data()).forEach(([key, value]) => {
          this.dynamicData.Achievers.push(Object.assign({ Id: key }, value));
        });
        this.setState({
          Achievers: this.dynamicData.Achievers,
        });
      } else {
        // this.setState({
        //     Data: []
        // })
      }
    });
  }
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row oralmaxil_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Oral Maxillo Facial Surgery
                    </h4>
                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Oral Maxillo Facial Surgery</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
            </div>
            {/* /.page-title-captions */}
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        About <span>Department</span>
                      </h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="ttm_single_image-wrapper mb-35">
                    <img
                      className="img-fluid"
                      src={image1}
                      alt="single-img-twelve"
                    />
                  </div>
                  <div className="ttm-service-description">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header">
                        <h2 className="title">
                          Oral Maxillo <span>Facial Surgery</span>{" "}
                        </h2>
                      </div>
                    </div>
                    {/* section title end */}
                    <p className="text-justify">
                      The Department of Oral & Maxillofacial Surgery has been an
                      integral part of the V.S.Dental College since its
                      inception in 1986. Since then our Dept. has
                      metamorphosized through time adapting to newer
                      technologies and innovations thus providing state of the
                      art treatment to patients. Today our Dept. is a landmark
                      in the field of oral surgery care being provided in the
                      city of Bangalore, which can be ascertained by the number
                      and variety of patients being treated by the Dept. Ever
                      since, our dept. has delivered with contemporary
                      expertise, education and clinical practice, thus ensuring
                      complete patient satisfaction. We have distinguished
                      record in treating various types of Cancer cases including
                      cysts, tumors and infections of Maxillofacial region. The
                      Dept. being also associated with KIMS Casualty is
                      responsible for providing the Primary Care for all the
                      maxillofacial trauma patients. Our staff includes a
                      dedicated team of surgeons who excel in the treatment of
                      Midfacial and Mandibular trauma. The dept. boasts of daily
                      OP of 30-40 patients for whom minor surgical procedures
                      are performed by under/post graduate students under the
                      guidance of the staff. The UG's are made to undergo robust
                      training program during the course of which they acquire
                      the basic skills of extraction and get the glimpse of the
                      world they will explore if they take up oral surgery for
                      their post graduation. Our PG program is a rigorous one in
                      which our PGs attain varied skills starting from simple
                      extraction to assisting in complex surgical procedure in
                      the operation theater. The PG's are also trained in Dept.
                      of Gen. Medicine, Gen. Surgery, Emergency, ENT and Plastic
                      Surgery. Considering the amount of good service, we were
                      among the first few colleges who were permitted to start
                      the PG diploma course by the DCI.
                    </p>

                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Vision</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <p className="mb-35 res-991-mb-20">
                          To be leading centre for teaching,research & Provision
                          of equality services in the sphere of maxillofacial
                          surgery . .
                        </p>
                      </div>
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <img
                            className="vision-block"
                            src={image2}
                            alt="single-img-sixteen"
                          />
                        </div>
                      </div>
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
                          <img
                            className="vision-block"
                            src={image3}
                            alt="single-img-seventeen"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Mission</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <p className="mb-35 res-991-mb-20 text-justify">
                          To train high caliber oral health professionals,to
                          carryout creative,innovative & inventive research in
                          core fields. • To engage in the oractice of high
                          standards evidence based oral & Maxillofacial surgery•
                          health care Provision & TO Produce oral health care
                          products with a holistic approach to health care
                          management of patients with oral diseases especially
                          to benefit the community at large scale
                        </p>
                      </div>
                    </div>
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="section-title with-desc text-left clearfix">
                          <div className="title-header">
                            <h2 className="title">Objectives</h2>
                          </div>
                        </div>
                        {/* section title end */}
                        <ul className="text-justify">
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {" "}
                              Endeavor to develop & maintain ethical and
                              proffessional behaviour
                            </span>
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {" "}
                              To promote the value of
                              truth,integrity,honesty,tolerance and
                              accountability
                            </span>{" "}
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {" "}
                              Strive to great all with Respect ,equality and
                              Dignity
                            </span>
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {" "}
                              To Pursue A Global Perspective in all the
                              activities{" "}
                            </span>
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {" "}
                              To Promote capacity building & Individual
                              Development among staff and students{" "}
                            </span>
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {" "}
                              To Promote Sensitivity & Responsiveness to social
                              needs{" "}
                            </span>
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {" "}
                              To Proomote meritocracy And teamwork in field of
                              research & Practice
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <img
                            className="img-fluid"
                            src={image3}
                            alt="single-img-sixteen"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              <div className="col-lg-3 widget-area">
                <OralMaxilloFSsidebar />
              </div>
            </div>
            {/* row end */}
          </div>
          <section className=" services-slide-section ttm-bgcolor-grey ttm-bg ttm-bgimage-yes bg-img14 clearfix">
            <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
            <div className="container-xl">
              <div className="row align-items-center">
                {/* row */}
                <div className="col-lg-9 col-xl-9 col-md-9">
                  {/* section-title */}
                  <div className="section-title  with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        {" "}
                        Course<span> Offered</span>
                      </h2>
                    </div>
                  </div>
                  {/* section-title end */}
                </div>
              </div>
              <Slider
                className="position-relative z-1 row slick_slider ttm-boxes-spacing-30px"
                {...slick_slider}
                slidesToShow={3}
              >
                <div className="ttm-box-col-wrapper">
                  {/* featured-imagebox-services */}
                  <div className="featured-imagebox featured-imagebox-services style1">
                    <div className="featured-thumbnail">
                      {/* featured-thumbnail */}
                      <img className="img-fluid" src={image1} alt="image" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        {/* featured-title */}
                        <h5>BDS</h5>
                      </div>

                      <a
                        href={pdf}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      >
                        download Syllabus
                      </a>
                    </div>
                  </div>
                  {/* featured-imagebox-services */}
                </div>
                <div className="ttm-box-col-wrapper">
                  {/* featured-imagebox-services */}
                  <div className="featured-imagebox featured-imagebox-services style1">
                    <div className="featured-thumbnail">
                      {/* featured-thumbnail */}
                      <img className="img-fluid" src={image2} alt="image" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        {/* featured-title */}
                        <h5>MDS</h5>
                      </div>

                      <a
                        href={docx}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      >
                        Download Syllabus
                      </a>
                    </div>
                  </div>
                  {/* featured-imagebox-services */}
                </div>
                <div className="ttm-box-col-wrapper">
                  {/* featured-imagebox-services */}
                  <div className="featured-imagebox featured-imagebox-services style1">
                    <div className="featured-thumbnail">
                      {/* featured-thumbnail */}
                      <img className="img-fluid" src={image3} alt="image" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        {/* featured-title */}
                        <h5>PhD</h5>
                      </div>

                      <a
                        href={pdf2}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      >
                        download Syllabus
                      </a>
                    </div>
                  </div>
                  {/* featured-imagebox-services */}
                </div>
              </Slider>
              <div className="ttm-row tab-section clearfix">
                <div className="container-xl">
                  <div className="row">
                    <div className="col-12">
                      <div className="section-title with-desc text-left clearfix">
                        <div className="title-header">
                          <h2 className="title">
                            Achievers in Department of{" "}
                            <span>Oral Maxillo Facial Surgery</span>
                          </h2>
                        </div>
                      </div>
                      {/* section title end */}
                      <div className="ttm-tabs text-center ttm-tab-style-classic style2">
                        <div className="content-tab">
                          <div className="row multi-columns-row ttm-boxes-spacing-5px">
                            {this.state.Achievers === undefined ? (
                              <NoData />
                            ) : (
                              this.state.Achievers.length &&
                              this.state.Achievers.map(function (item, index) {
                                return (
                                  <div
                                    className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6"
                                    key={index}
                                  >
                                    {/* featured-imagebox */}
                                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                                      {/* featured-thumbnail */}
                                      <div className="featured-thumbnail">
                                        <img
                                          className="img-fluid"
                                          src={item.Image}
                                          alt="image"
                                          style={{
                                            width: "360px",
                                            height: "390px",
                                          }}
                                        />
                                      </div>
                                      {/* featured-thumbnail end*/}
                                      <div className="featured-content">
                                        <div className="category">
                                          <p>Name : {item.Name}</p>
                                        </div>
                                        <div className="category">
                                          <p>
                                            Achievements : {item.Achievements}
                                          </p>
                                        </div>
                                        <div className="category">
                                          <p>
                                            Description : {item.Description}
                                          </p>
                                        </div>
                                        <div className="category">
                                          <p>Year : {item.Year}</p>
                                        </div>
                                      </div>
                                    </div>
                                    {/* featured-imagebox */}
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <OMFSNews />
        </div>
      </div>
    );
  }
}

export default OralMaxilloFS;
