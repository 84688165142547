import React, { Component } from "react";
import Slider from "react-slick";
import Header from "../layout/Header";
import image from "../../images/sports_About.jpg";
import image2 from "../../images/throwball_team.jpeg";
import firestore from "../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import NoData from "../pages/NoData";
export class SportsandGames extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      SportsandGames: [],
    };

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "LifeAtVSDC");
    const sportskey = "SportsandGames";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      console.log(Snapshot);
      const allDynamicData = this.getDynamicData(Snapshot);
      console.log(allDynamicData);
      this.dynamicData.SportsandGames = allDynamicData[sportskey];
      //this.dynamicData.FreshersDay = allDynamicData[freshersdaykey];

      console.log("test data");

      console.log(this.dynamicData.SportsandGames);
    }
    const arr = [];
    this.setState({ SportsandGames: arr });
    // this.setState({FreshersDay : arr});
  }
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row sports_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box-updated text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title " style={{ color: "white" }}>
                      Sports and Games
                    </h4>

                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Sports and Games</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.page-title-captions */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        {/* about-services-section */}
        <section className="ttm-row about-services-section clearfix">
          <div className="container-xl">
            <div className="row align-items-center">
              {/* row */}
              <div className="col-lg-6">
                <div className="position-relative">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="sports"
                      src={image}
                      title="single-img-ten"
                      alt="single-img-ten"
                    />
                  </div>
                  {/* ttm_single_image-wrapper end */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="res-991-mt-30">
                  {/* section title */}
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        About
                        <br /> Sports and Games AT <span>VSDCH</span>
                      </h2>
                    </div>
                    <div className="title-desc">
                      <p className="text-justify">
                        The favourite part of the game is “The opportunity to
                        play.” -says Mike Singletary VSDCH encourages and
                        uplifts the sports spirit in students and offers them
                        excellent facilities.{" "}
                      </p>
                      <ul className="ttm-list ttm-list-style-icon">
                        <li className="text-justify">
                          <i class="far fa-dot-circle"></i>The institution has
                          designated a staff member as Sports secretary to
                          monitor the sports activities and sports meet
                          involving all the students, teaching and non-teaching
                          staff is conducted annually. Best outgoing award in
                          sports for Girls and boys is awarded each year by the
                          institution.
                        </li>
                        <li className="text-justify">
                          <i class="far fa-dot-circle"></i>A play ground is
                          available in the KIMS campus for playing cricket,
                          volleyball, basketball and to practise other sports.{" "}
                        </li>
                        <li className="text-justify">
                          <i class="far fa-dot-circle"></i>Women faculty members
                          are involved in yoga practice within the campus and
                          are actively participate and celebrate international
                          yoga day on June 21st every year.{" "}
                        </li>
                        <li className="text-justify">
                          <i class="far fa-dot-circle"></i>Sports room available
                          for indoor sports like Carom, Table Tennis, and Chess
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* section title end */}
                  {/* row */}
                  <div className="row mt-25">
                    <div className="col-12">{/* featured-icon-box */}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* about-services-section end */}
        {/* services-slide-section */}
        <section className="ttm-row zero-padding-section services-slide-section ttm-bgcolor-grey ttm-bg ttm-bgimage-yes bg-img14 clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
          <div className="container-xl">
            <div className=" align-items-center">
              {/* row */}
              <div className="col-lg-9 col-xl-9 col-md-9">
                {/* section-title */}
                <div className="section-title style2 clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      <span>Sports</span>
                    </h2>
                  </div>
                </div>
                {/* section-title end */}
              </div>
            </div>
            <Slider
              className="position-relative z-1 row slick_slider ttm-boxes-spacing-30px"
              {...slick_slider}
              slidesToShow={3}
            >
              <div className="ttm-box-col-wrapper">
                {/* featured-imagebox-services */}
                <div className="featured-imagebox featured-imagebox-services style1">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="img-fluid"
                      src="./images/sportsandgamesimg1.jpg"
                      alt="sports_image"
                      style={{ width: "360px", height: "303px" }}
                    />
                  </div>
                </div>
                {/* featured-imagebox-services */}
              </div>
              <div className="ttm-box-col-wrapper">
                {/* featured-imagebox-services */}
                <div className="featured-imagebox featured-imagebox-services style1">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="img-fluid"
                      src="./images/sportsandgamesimg2.jpg"
                      alt="sports_image2"
                    />
                  </div>
                </div>
                {/* featured-imagebox-services */}
              </div>
              <div className="ttm-box-col-wrapper">
                {/* featured-imagebox-services */}
                <div className="featured-imagebox featured-imagebox-services style1">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="img-fluid"
                      src="./images/sportsandgamesimg3.jpg"
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>
        {/* services-slide-section end */}
        {/* portfolio-section */}
        <section className="  mt_230  res-991-mt-0 position-relative clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Achievers in <span>Sports and Games</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row */}
            <div className="row multi-columns-row ttm-boxes-spacing-10px ttm-bgcolor-white">
              {this.dynamicData.SportsandGames === undefined ? (
                <NoData />
              ) : (
                this.dynamicData.SportsandGames.length &&
                this.dynamicData.SportsandGames.map(function (item, index) {
                  return (
                    <div
                      className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6"
                      key={index}
                    >
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-portfolio style2">
                        {/* featured-thumbnail */}
                        <div className="featured-thumbnail">
                          <img
                            className="img-fluid"
                            src={item.Image}
                            alt="image"
                            style={{ width: "360px", height: "303px" }}
                          />
                        </div>
                        {/* featured-thumbnail end*/}
                        <div className="featured-content">
                          <div className="category">
                            <p>{item.Description}</p>
                            <p>{item.Date}</p>
                          </div>
                          <div className="featured-title">
                            <h5>
                              <a>{item.Heading}</a>
                            </h5>
                          </div>
                        </div>
                      </div>
                      {/* featured-imagebox */}
                    </div>
                  );
                })
              )}
            </div>
            {/* row end */}
          </div>
        </section>
        {/* portfolio-section end */}
        {/* our-partner-section */}
        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-6">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>VSDCH</h5>
                    <h2 className="title">
                      Details of <span>Facilities</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
                <div className="row no-gutters mt-4 mb-4">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <h5> Indoor sports Facilities</h5>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Badminton court within the campus{" "}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Table Tennis
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">Carom</span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content"> Chess </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <h5> Outdoor sports facilities</h5>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {" "}
                          Cricket Nets – Practice Facilities
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Volley ball{" "}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content"> Foot ball </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content"> Shot put</span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Javelin throw
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {" "}
                          Discus throw
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* row END*/}
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={image2}
                      alt="single-img-seven"
                      style={{
                        width: "500px",
                        height: "360px",
                        paddingTop: "60px",
                      }}
                    />
                  </div>
                  {/* ttm_single_image-wrapper end */}
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
      </div>
    );
  }
}

export default SportsandGames;
