import React, { Component } from "react";
import Sidebar from "./Sidebar";
import Header from "../layout/Header";

export class managementRVS extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row explorebanner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>Management RVS</h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + "/"}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">RVS</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className=" grid-section clearfix">
          <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-lg-9 content-area order-lg-2">
                  <div className="ttm-service-single-content-area">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header">
                        <h2 className="title">Rajya Vokkaliga Sangha <span>(RVS)</span></h2>
                      </div>
                    </div>
                    <div className="ttm-service-description text-justify">
                      Our management, Rajya Vokkaligara Sangha (RVS) was founded
                      in the year 1906 to promote social, cultural and
                      educational aspirations of the agricultural community.
                      Rajya Vokkaligara Sangha with a commanding presence in
                      Bangalore, Ramanagara, Mandya, Mysore, Chikkamagalur,
                      Hassan, Tumkur, Chikkaballapura, Kolar and Chitradurga
                      districts and with a fairly well spread presence in other
                      regions of Karnataka has been conducting number of regular
                      activities for the development and well being of the
                      community in particular and to all sections of the society
                      in general. The sangha has also provided hostel facilities
                      for poor background student and students are making use of
                      this hostel facilities during their education carreer. The
                      Rajya Vokkaligara Sangha comprise of 35 directors
                      <div className="separator"><div className="sep-line mt-35 mb-35 res-991-mtb-20" /></div>
                      <div className="section-title with-desc text-left clearfix">
                        <div className="title-header">
                          <h2 className="title">
                            Organizational <span>Structure</span>
                          </h2>
                        </div>
                      </div>
                      <div className="row ttm-service-description" style={{ textAlign: "center" }}>
                        <div className='col-md-6 offset-md-3 paddingtop-1'>
                          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/management-1.jpg"} alt="error" />
                          <h5 className="paddingtop-1">Sri C N Balakrishna</h5>
                          <h6>President</h6>
                        </div>
                        <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/management-2.jpg"} alt="error" />
                          <h6 className="paddingtop-1">Dr. Renukaprasad</h6>
                          <small>Vice President</small>
                        </div>
                        <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/management-3.jpg"} alt="error" />
                          <h6 className="paddingtop-1">Sri D Hanumanthaiah</h6>
                          <small>Vice President</small>
                        </div>
                        <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/management-4.jpg"} alt="error" />
                          <h6 className="paddingtop-1">Sri T Konappareddy</h6>
                          <small>General Secretary</small>
                        </div>
                        <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/management-5.jpg"} alt="error" />
                          <h6 className="paddingtop-1">Sri Raghavendra</h6>
                          <small>Joint Secretary</small>
                        </div>
                        <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/management-6.jpg"} alt="error" />
                          <h6 className="paddingtop-1">Sri R Prakash</h6>
                          <small>Treasurer</small>
                        </div>
                        <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/management-7.jpg"} alt="error" />
                          <h6 className="paddingtop-1">Sri Hanumanthar ayappa</h6>
                          <small>Internal Auditor</small>
                        </div>
                        <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/management-8.jpg"} alt="error" />
                          <h6 className="paddingtop-1">Sri N Balakrishna</h6>
                          <small>Legal Advisor</small>
                        </div>
                        <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/management-9.jpg"} alt="error" />
                          <h6 className="paddingtop-1">Dr T H Anjanappa</h6>
                          <small>Honorary President</small>
                        </div>
                        <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/management-10.jpg"} alt="error" />
                          <h6 className="paddingtop-1">Sri C Devaraj</h6>
                          <small>Working President</small>
                        </div>
                        <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/management-11.jpg"} alt="error" />
                          <h6 className="paddingtop-1">Sri Ravindra H N</h6>
                          <small>Working President</small>
                        </div>
                      </div>
                      <div class="ttm-service-single-content-area">
                        <div className="section-title with-desc text-left clearfix paddingtop-2">
                          <div className="title-header" style={{ borderTopColor: "red" }}>
                            <h4 className="title">Executive Council Members</h4>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6">
                            <ul class="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                              <li>Sri.M.Puttaswamy : Chairman-Governing Council, BIT</li>
                              <li>Dr.T.H.Anjanappa</li>
                              <li>Sri.H.N.Ashoka H N</li>
                              <li>Sri.B.Kenchappa Gowda</li>
                              <li>Sri.C.Devaraju</li>
                              <li>Sri.H.C.Jayamuthu</li>
                              <li>Sri.C.M.Maregowda</li>
                              <li>Sri.L.Srinivas</li>
                              <li>Sri.K.S.Suresh</li>
                              <li>Sri.B.V.Rajashekaragowda</li>
                              <li>Sri.Venkataramegowda</li>
                              <li>Sri.M.S.Umapathi</li>
                              <li>Dr.V.Narayanaswamy</li>
                              <li>Sri.C.G.Gangadhar</li>
                              <li>Sri.K.V.Shridhar</li>
                            </ul>
                          </div>
                          <div class="col-lg-6">
                            <ul class="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                              <li>Sri.S.J.Ashok Jayaram</li>
                              <li>Dr.M.B.Manjegowda</li>
                              <li>Sri.Chandrashekar(Mudya Chandru)</li>
                              <li>Sri.N.Balakrishna(Nelligere Balu)</li>
                              <li>Sri.B.P.Manjegowda</li>
                              <li>Sri.S.S.Raghugowda</li>
                              <li>Sri.Hanumatharayappa R</li>
                              <li>Sri.Lokesh D Nagarajaiah</li>
                              <li>Dr.D.K.Ramesh</li>
                              <li>Sri.J.Raju</li>
                              <li>Sri.H.N.Ravidra</li>
                              <li>Sri.Yaluvalli N Ramesh</li>
                              <li>Sri.A.Poornesh</li>
                              <li>Sri.S.K.Dharmesh siribailu</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 widget-area">
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default managementRVS;
