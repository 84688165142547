import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Footer } from "./components/layout/Footer";

import Home from "./components/Home";

import Contact_01 from "./components/pages/Contact_01";

import ScrollToTop from "./components/layout/Gotop";
import ChairmanVSDS from "./components/Explore/ChairmanVSDS";
import AboutVSDC from "./components/Explore/AboutVSDC";
import PrincipalVSDC from "./components/Explore/PrincipalVSDC";
import managementRVS from "./components/Explore/ManagementRVS";
import Administration from "./components/Explore/Administration";
import TeachingLearning from "./components/Academics/TeachingLearning";
import Library from "./components/Academics/Library";
import Campus from "./components/LifeatVSDC/Campus";
import StudentActivities from "./components/LifeatVSDC/StudentActivities";
import SportsandGames from "./components/LifeatVSDC/SportsandGames";
import Facilities from "./components/LifeatVSDC/Facilities";
import Alumni from "./components/LifeatVSDC/Alumni";
import BestPractices from "./components/LifeatVSDC/BestPractices/BestPractices";
import OrofacialPainClinic from "./components/LifeatVSDC/BestPractices/OrofacialPainClinic";
import DantaBhagyaYojane from "./components/LifeatVSDC/BestPractices/DanthaBhagyaYojane";
import Department from "./components/Academics/Department/Department";
import OralMedicineRadiology from "./components/Academics/Department/OralMedicineRadiology/OralMedicineRadiology";
import OMRFacultiesandStaff from "./components/Academics/Department/OralMedicineRadiology/OMRFacultiesandStaff";
import OMRTeachingLearning from "./components/Academics/Department/OralMedicineRadiology/OMRTeachingLearning";
import OMRResearch from "./components/Academics/Department/OralMedicineRadiology/OMRResearch";
import OMRActivities from "./components/Academics/Department/OralMedicineRadiology/OMRActivities";
import PublicHealth from "./components/Academics/Department/PublicHealthDentistry/PublicHealth";
import PHDFacultiesandStaff from "./components/Academics/Department/PublicHealthDentistry/PHDFacultiesandStaff";
import PHDTeachingLearning from "./components/Academics/Department/PublicHealthDentistry/PHDTeachingLearning";
import PHDResearch from "./components/Academics/Department/PublicHealthDentistry/PHDResearch";
import PHDActivities from "./components/Academics/Department/PublicHealthDentistry/PHDActivities";
import OralMaxilloFS from "./components/Academics/Department/OralMaxilloFacialSurgery/OralMaxilloFS";
import OMFSfacultiesandStaff from "./components/Academics/Department/OralMaxilloFacialSurgery/OMFSfacultiesandStaff";
import OMFSTeachingLearning from "./components/Academics/Department/OralMaxilloFacialSurgery/OMFSteaching";
import OMFSResearch from "./components/Academics/Department/OralMaxilloFacialSurgery/OMFSResearch";
import OMFSActivities from "./components/Academics/Department/OralMaxilloFacialSurgery/OMFSActivities";
import Prosthodontics from "./components/Academics/Department/Prosthodontics/Prosthodontics";
import ProFaculties from "./components/Academics/Department/Prosthodontics/ProFaculties";
import { ProTeaching } from "./components/Academics/Department/Prosthodontics/ProTeaching";
import ProResearch from "./components/Academics/Department/Prosthodontics/ProResearch";
import ProActivities from "./components/Academics/Department/Prosthodontics/ProActivities";
import Periodontics from "./components/Academics/Department/Periodontics/Periodontics";
import PeriodonticsFaculties from "./components/Academics/Department/Periodontics/PeriodonticsFaculties";
import PeriodonticsTeaching from "./components/Academics/Department/Periodontics/PeriodonticsTeaching";
import PeriodonticsResearch from "./components/Academics/Department/Periodontics/PeriodonticsResearch";
import PeriodonticsActivities from "./components/Academics/Department/Periodontics/PeriodonticsActivities";
import ConservativeDentistry from "./components/Academics/Department/ConservativeDentistry/ConservativeDentistry";
import ConsFaculties from "./components/Academics/Department/ConservativeDentistry/ConsFaculties";
import ConsTeaching from "./components/Academics/Department/ConservativeDentistry/ConsTeaching";
import ConsResearch from "./components/Academics/Department/ConservativeDentistry/ConsResearch";
import ConsActivities from "./components/Academics/Department/ConservativeDentistry/ConsActivities";
import Paedodontics from "./components/Academics/Department/Paedodontics/Paedodontics";
import PaedodonticsFaculties from "./components/Academics/Department/Paedodontics/PaedodonticsFaculties";
import PaedodonticsTeaching from "./components/Academics/Department/Paedodontics/PaedodonticsTeaching";
import PaedodonticsResearch from "./components/Academics/Department/Paedodontics/PaedodonticsResearch";
import PaedodonticsActivities from "./components/Academics/Department/Paedodontics/PaedodonticsActivities";
import Orthodontics from "./components/Academics/Department/Orthodontics/Orthodontics";
import OrthodonticsActivities from "./components/Academics/Department/Orthodontics/OrthodonticsActivities";
import OrthodonticsFaculties from "./components/Academics/Department/Orthodontics/OrthodonticsFaculties";
import OrthodonticsResearch from "./components/Academics/Department/Orthodontics/OrthodonticsResearch";
import OrthodonticsTeaching from "./components/Academics/Department/Orthodontics/OrthodonticsTeaching";
import OralMaxilPathology from "./components/Academics/Department/OralMaxilPathology/OralMaxilPathology";
import PathologyActivities from "./components/Academics/Department/OralMaxilPathology/PathologyActivities";
import PathologyResearch from "./components/Academics/Department/OralMaxilPathology/PathologyResearch";
import PathologyTeaching from "./components/Academics/Department/OralMaxilPathology/PathologyTeaching";
import PathologyFaculties from "./components/Academics/Department/OralMaxilPathology/PathologyFaculties";
import TobaccoCessationCentre from "./components/LifeatVSDC/BestPractices/TobaccoCessationCentre";
import ImplantClinic from "./components/LifeatVSDC/BestPractices/ImplantClinic";
import { ExpressDentalCare } from "./components/LifeatVSDC/BestPractices/ExpressDentalCare";
import SchoolHealth from "./components/LifeatVSDC/BestPractices/SchoolHealth";
import Workshop from "./components/LifeatVSDC/BestPractices/Workshop";
import OrientationSessions from "./components/LifeatVSDC/BestPractices/OrientationSessions";
import IndhiraGandhiChildHealth from "./components/LifeatVSDC/BestPractices/IndhiraGandhiChildHealth";
import Admission from "./components/Admission/Admission";
import { Rules } from "./components/Explore/Rules";
import Accreditation from "./components/Explore/Accreditation";
import InnovationCell from "./components/LifeatVSDC/BestPractices/InnovationCell";
import Gallery from "./components/Gallery/Gallery";
import Achievers from "./components/LifeatVSDC/Achievers";
import { Faculty } from "./components/Academics/Department/OralMedicineRadiology/EachFaculty";
import NSS from "./components/LifeatVSDC/BestPractices/NSS";
import News from "./components/Academics/Department/OralMedicineRadiology/News";
import ResearchAchievements from "./components/Academics/Department/OralMedicineRadiology/ResearchAchievements";
import ConResAchievements from "./components/Academics/Department/ConservativeDentistry/ConResAchievements";
import SurgeryAchievements from "./components/Academics/Department/OralMaxilloFacialSurgery/surgeryAchievements";
import ProResAchievements from "./components/Academics/Department/Prosthodontics/ProResAchievements";
import PerioResAchievements from "./components/Academics/Department/Periodontics/PerioResAchievements";
import PeadoResAchievements from "./components/Academics/Department/Paedodontics/PeadoResAchievements";
import OrthoresAchievements from "./components/Academics/Department/Orthodontics/OrthoresAchievements";
import PathologyResAchievements from "./components/Academics/Department/OralMaxilPathology/PathologyResAchievements";
import OMFPNews from "./components/Academics/Department/OralMaxilPathology/OMFPNews";
import PHDnews from "./components/Academics/Department/PublicHealthDentistry/PHDnews";
import OMFSNews from "./components/Academics/Department/OralMaxilloFacialSurgery/OMFSNews";
import Pronews from "./components/Academics/Department/Prosthodontics/Pronews";
import PeriodonticsNews from "./components/Academics/Department/Periodontics/PeriodonticNews";
import ConsNews from "./components/Academics/Department/ConservativeDentistry/ConsNews";
import PaedoNews from "./components/Academics/Department/Paedodontics/PaedoNews";
import PhResAchievements from "./components/Academics/Department/PublicHealthDentistry/PhResAchievements";
import TechnicalStaff from "./components/Academics/Department/OralMedicineRadiology/TechnicalStaff";
import Faculties from "./components/Academics/Department/Faculties";
import TechStaff from "./components/Academics/Department/TechStaff";
import { PHDFieldPrograms } from "./components/Academics/Department/PublicHealthDentistry/PHDFieldPrograms";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/"}`}
          component={Home}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/managementrvs"}`}
          component={managementRVS}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/aboutvsdc"}`}
          component={AboutVSDC}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/principalvsdc"}`}
          component={PrincipalVSDC}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/ChairmanVSDC"}`}
          component={ChairmanVSDS}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/administration"}`}
          component={Administration}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/teachinglearning"}`}
          component={TeachingLearning}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/library"}`}
          component={Library}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/campus"}`}
          component={Campus}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/studentactivities"}`}
          component={StudentActivities}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/sportsandgames"}`}
          component={SportsandGames}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/facilities"}`}
          component={Facilities}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/alumni"}`}
          component={Alumni}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/bestpractices"}`}
          component={BestPractices}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/orofacialpainclinic"}`}
          component={OrofacialPainClinic}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/danthabhagyayojane"}`}
          component={DantaBhagyaYojane}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/department"}`}
          component={Department}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/oralmedicineradiology"}`}
          component={OralMedicineRadiology}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/omrfacultiesandstaff"}`}
          component={OMRFacultiesandStaff}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/omrteachinglearning"}`}
          component={OMRTeachingLearning}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/omrresearch"}`}
          component={OMRResearch}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/omractivities"}`}
          component={OMRActivities}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/publichealth"}`}
          component={PublicHealth}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/phdfacultiesandstaff"}`}
          component={PHDFacultiesandStaff}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/phdteachinglearning"}`}
          component={PHDTeachingLearning}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/phdresearch"}`}
          component={PHDResearch}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/phdactivities"}`}
          component={PHDActivities}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/oralmaxillofs"}`}
          component={OralMaxilloFS}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/omfsfaculties"}`}
          component={OMFSfacultiesandStaff}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/omfsteaching"}`}
          component={OMFSTeachingLearning}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/omfsresearch"}`}
          component={OMFSResearch}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/omfsactivities"}`}
          component={OMFSActivities}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/prosthodontics"}`}
          component={Prosthodontics}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/profaculties"}`}
          component={ProFaculties}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/proteaching"}`}
          component={ProTeaching}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/proresearch"}`}
          component={ProResearch}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/proactivities"}`}
          component={ProActivities}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/periodontics"}`}
          component={Periodontics}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/periodonticsfaculties"}`}
          component={PeriodonticsFaculties}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/periodonticsteaching"}`}
          component={PeriodonticsTeaching}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/periodonticsresearch"}`}
          component={PeriodonticsResearch}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/periodonticsactivities"}`}
          component={PeriodonticsActivities}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/conservativedentistry"}`}
          component={ConservativeDentistry}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/consfaculties"}`}
          component={ConsFaculties}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/consteaching"}`}
          component={ConsTeaching}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/consresearch"}`}
          component={ConsResearch}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/consactivities"}`}
          component={ConsActivities}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/paedodontics"}`}
          component={Paedodontics}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/paedodonticsfaculties"}`}
          component={PaedodonticsFaculties}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/paedodonticsteaching"}`}
          component={PaedodonticsTeaching}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/paedodonticsresearch"}`}
          component={PaedodonticsResearch}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/paedodonticsactivities"}`}
          component={PaedodonticsActivities}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/orthodontics"}`}
          component={Orthodontics}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/orthodonticsactivities"}`}
          component={OrthodonticsActivities}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/orthodonticsfaculties"}`}
          component={OrthodonticsFaculties}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/orthodonticsresearch"}`}
          component={OrthodonticsResearch}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/orthodonticsteaching"}`}
          component={OrthodonticsTeaching}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/oralmaxilpathology"}`}
          component={OralMaxilPathology}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/pathologyactivities"}`}
          component={PathologyActivities}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/pathologyresearch"}`}
          component={PathologyResearch}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/pathologyteaching"}`}
          component={PathologyTeaching}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/pathologyfaculties"}`}
          component={PathologyFaculties}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/tobaccocessationcentre"}`}
          component={TobaccoCessationCentre}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/implantclinic"}`}
          component={ImplantClinic}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/expressdentalcare"}`}
          component={ExpressDentalCare}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/schoolhealth"}`}
          component={SchoolHealth}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/workshop"}`}
          component={Workshop}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/orientationsessions"}`}
          component={OrientationSessions}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/indhiragandhichildhealth"}`}
          component={IndhiraGandhiChildHealth}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/admission"}`}
          component={Admission}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/rules"}`}
          component={Rules}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/innovationcell"}`}
          component={InnovationCell}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/accreditation"}`}
          component={Accreditation}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/gallery"}`}
          component={Gallery}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/achievers"}`}
          component={Achievers}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/news"}`}
          component={News}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/ResearchAchievements"}`}
          component={ResearchAchievements}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/contact_01"}`}
          component={Contact_01}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/nss"}`}
          component={NSS}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/consresachievements"}`}
          component={ConResAchievements}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/surgeryachievements"}`}
          component={SurgeryAchievements}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/proresachievements"}`}
          component={ProResAchievements}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/perioresachievements"}`}
          component={PerioResAchievements}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/paedoresachievements"}`}
          component={PeadoResAchievements}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/orthoresachievements"}`}
          component={OrthoresAchievements}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/pathologyresachievements"}`}
          component={PathologyResAchievements}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/omfpnews"}`}
          component={OMFPNews}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/PHDnews"}`}
          component={PHDnews}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/omfsnews"}`}
          component={OMFSNews}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/pronews"}`}
          component={Pronews}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/periodonticnews"}`}
          component={PeriodonticsNews}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/consnews"}`}
          component={ConsNews}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/paedonews"}`}
          component={PaedoNews}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/eachfaculty/:id/:deptname"}`}
          component={Faculty}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/TechnicalStaff/:id"}`}
          component={TechnicalStaff}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/Faculties/:id/:deptname"}`}
          component={Faculties}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/TechStaff/:id/:deptname"}`}
          component={TechStaff}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/phresachievements"}`}
          component={PhResAchievements}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/phdfieldprograms"}`}
          component={PHDFieldPrograms}
        />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
