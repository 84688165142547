import React, { Component } from "react";
import Slider from "react-slick";
import Header from "../../layout/Header";
import image1 from "../../../images/Bestpractices/workshop1.jpg";
import image2 from "../../../images/Bestpractices/workshop2.jpg";
import image3 from "../../../images/Bestpractices/workshop3.jpg";
import image4 from "../../../images/Bestpractices/workshop4.jpg";
import image5 from "../../../images/Bestpractices/workshop5.jpg";

export class Workshop extends Component {
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row bestpractices-banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading  card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Capacity Building Workshop
                    </h4>
                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>VSDCH</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.page-title-captions */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        <section className="ttm-row project-single-section clearfix">
          <div className="container-xl">
            {/* row */}{" "}
            <div className="row mt-4">
              <div className="col-12">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      About <span> Workshops</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
                <p className="text-justify">
                  {" "}
                  The workshop aims to help students understand that Life skills
                  and Scientific Temper go hand in hand with development and can
                  assist students to succeed later in life. Life skills are
                  recognized as those essential skills that bring together the
                  social, emotional, and cognitive capacities of a person to
                  solve problems and achieve goals. Scientific temper is broadly
                  defined as "a modest open-minded temper—a temper ever ready to
                  welcome new light, new knowledge, new experiments, even when
                  their results are unfavourable to preconceived opinions and
                  long-cherished theories," said Diggle J in The Faith of
                  Science. Workshops empower postgraduate students in their
                  research endeavours by training them in scientific temper
                  values such as creative and critical thinking by using Life
                  Skills Education approach, the success of such training is
                  witnessed by the achievement of our alumni. We are committed
                  to develop not just dental practitioner but an individual
                  capable of lifelong learning for a fulfilled life. Facilitator
                  and In-charge: Dr. Sushi is recognised as the first dental
                  faculty in India to incorporate the concept of Life Skills to
                  the context of Dental Students through her unique tailor-made
                  workshops for dentistry. She has been conducting life skills
                  workshops for dental students and faculty throughout the
                  country as an invited facilitator by several dental teaching
                  institutions since 2010. Person in-charge: Dr. Sushi
                  Kadanakuppe MDS, Ph.D Co-ordinator: Mrs. Savitha
                </p>
              </div>
              {/* separator */}
            </div>
            {/* row end*/}
            <div className="row">
              <div className="col-lg-12 col-xl-8">
                <Slider
                  className="slick_slider project-single"
                  {...slick_slider}
                  arrows={false}
                  dots={true}
                >
                  <div className="featured-thumbnail">
                    <img
                      src={image1}
                      alt="workshop_image1"
                      className="img-fluid"
                    />
                  </div>
                  <div className="featured-thumbnail">
                    <img
                      src={image2}
                      alt="workshop_image2"
                      className="img-fluid"
                    />
                  </div>
                  <div className="featured-thumbnail">
                    <img
                      src={image3}
                      alt="workshop_image3"
                      className="img-fluid"
                    />
                  </div>
                </Slider>
              </div>
              <div className="col-lg-12 col-sm-12 col-xl-4">
                {/* row */}
                <div className="row res-1199-mt-20">
                  <div className="col-xl-12 col-lg-6 col-md-6 col-sm-6">
                    {/* ttm_single_image-wrapper */}
                    <div className="ttm_single_image-wrapper mb-20">
                      <img
                        className="img-fluid"
                        src={image4}
                        title="single-img-twentytwo"
                        alt="single-img-twentytwo"
                      />
                    </div>
                    {/* ttm_single_image-wrapper end */}
                  </div>
                  <div className="col-xl-12 col-lg-6 col-md-6 col-sm-6 mb-20">
                    {/* ttm_single_image-wrapper */}
                    <div className="ttm_single_image-wrapper">
                      <img
                        className="img-fluid"
                        src={image5}
                        title="single-img-twentythree"
                        alt="single-img-twentythree"
                      />
                    </div>
                    {/* ttm_single_image-wrapper end */}
                  </div>
                </div>
                {/* row end*/}
              </div>
            </div>
            {/* row end*/}
            {/* row */}
          </div>
        </section>
      </div>
    );
  }
}

export default Workshop;
