import React, { Component } from 'react';
import Header from '../../layout/Header';
import pdf from "../../../assets/Institutional Innovation Cell.pdf";
import image1 from "../../../images/Bestpractices/IIC1.jpg";
import image2 from "../../../images/Bestpractices/IIC2.jpg";


export class InnovationCell extends Component {
  render() {
 
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row bestpractices-banner">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading  card_box">
                            <h4 className="title" style={{color:"white"}}>Institution Innovation Cell</h4>
                            <div className="ttm-color-home">
                            <span>
                                <a className="ttm-color-home" title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>VSDCH</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}
                        </div>{/* /.page-title-captions */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                     
                </div>
        {/* page-title end*/}
        {/* vision mission-section */}
        <section className="ttm-row aboutus-section-style3 clearfix bottomzero-padding-section" style={{paddingBottom:"60px"}}>
          <div className="container-xl">
            <div className="row">{/* row */}
              <div className="col-lg-6">
                <div className="position-relative">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center mr-35 res-991-m-0">
                    <img className="img-fluid" src={image1}  alt="single-img-nine" />
                    
                  </div>{/* ttm_single_image-wrapper end */}<br/>
                  <div className="ttm_single_image-wrapper text-sm-center mr-35 res-991-m-0">
                    <img className="img-fluid" src={image2}  alt="single-img-nine" />
                    
                  </div>{/* ttm_single_image-wrapper end */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="res-991-mt-30">
                  {/* section title */}
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h5></h5>
                      <h2 className="title">Vision</h2>
                    </div>
                    <div className="title-desc">
                      <p>To be one among the nation’s premier institutions by promoting innovations in oral health practice, education and research.</p>
                    </div>
                    <div className="title-header">
                      <h5></h5>
                      <h2 className="title">Mission</h2>
                    </div>
                    <div className="title-desc">
                      <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To inculcate the spirit of innovation in future dental professionals.</span></li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">To promote innovations in teaching practice</span></li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">To promote oral health and scientific research</span></li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">To support and develop advanced technological innovations in dentistry by the faculty and students.</span></li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">To achieve oral health promotion of the community through advances in the field of oral health care and health education</span></li>
                      </ul>
                    </div>
                    <div className="widget widget-download">

                      <ul className="download">
                        <li>
                          <i className="far fa-file-pdf" />
                          <a title="Download" href={pdf}>INSTITUTIONAL INNOVATION CELL</a>
                        </li>

                      </ul>
                    </div>
                  </div>{/* section title end */}
                  {/* row */}
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* Vision mission section end */}

        <section className=" history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                {/* section title */}
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h5></h5>
                    <h2 className="title">Journey of <span>IIC</span></h2>
                  </div>
                </div>{/* section title end */}
              </div>
            </div>
            <div className="testimonials text-center">
              <div className="testimonial-content text-justify">{/* testimonials-content */}

                <blockquote>The IIC of Vokkaligara Sangha Dental College was formed on 26.02.2021 with the lofty vision and mission guided by the Innovation Cell of Ministry of Education</blockquote>
                <blockquote>The first meeting of the IIC was held on 22.03.2021, the Vision and Mission Statement was formulated and approved.</blockquote>
                <blockquote>The second meeting was held on 10.08.2021. It was ideated that Research publications and authoring Books was to be encouraged with incentives. A proposal regarding the same was given to the Administrator of the institution</blockquote>
                <blockquote>Awareness Webinar on Intellectual Property rights and Intellectual Property management was organized on 30.08.2021 in association with Karnataka State Council of Science and Technology for the benefit of teaching faculty and students.</blockquote>
                <blockquote>The third meeting of the IIC was held on 30.08.2021. The members concluded that an IP cell has to be constituted and an Idea and Innovation Competition may be held for the students.</blockquote>
              </div>
            </div>

          </div>
        </section>

        <section className="ttm-row grid-section ttm-bgcolor-grey clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-4 col-md-6">
                {/* featured-imagebox-blog */}
                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                  <div className="featured-content">{/* featured-content */}
                    <div className="featured-title">{/* featured-title */}
                      <h5>Dr. Raj Kumar G.C</h5>
                    </div>
                    <div className="post-meta">{/* post-meta */}
                      <span className="ttm-meta-line"><i className="fa fa-comments" />President</span>
                      <span className="ttm-meta-line"><i className="fa fa-user" />Principal, V.S.D.C & H</span>
                    </div>
                  </div>
                </div>{/* featured-imagebox-blog end */}
              </div>
              <div className="col-lg-4 col-md-6">
                {/* featured-imagebox-blog */}
                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                  <div className="featured-content">{/* featured-content */}
                    <div className="featured-title">{/* featured-title */}
                      <h5>Dr. Roopa K. M</h5>
                    </div>
                    <div className="post-meta">{/* post-meta */}
                      <span className="ttm-meta-line"><i className="fa fa-comments" />Vice President </span>
                      <span className="ttm-meta-line"><i className="fa fa-user" />Prof & Head, Dept. of Mathematics, Bangalore Institute of Technology</span>
                    </div>
                  </div>
                </div>{/* featured-imagebox-blog end */}
              </div>
              <div className="col-lg-4 col-md-6">
                {/* featured-imagebox-blog */}
                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                  <div className="featured-content">{/* featured-content */}
                    <div className="featured-title">{/* featured-title */}
                      <h5>Dr. Nagashree S.R</h5>
                    </div>
                    <div className="post-meta">{/* post-meta */}
                      <span className="ttm-meta-line"><i className="fa fa-comments" />Convenor & ARIIA Co-ordinator </span>
                      <span className="ttm-meta-line"><i className="fa fa-user" />Reader, Department of Public Health Dentistry</span>
                    </div>
                  </div>
                </div>{/* featured-imagebox-blog end */}
              </div>
              <div className="col-lg-4 col-md-6">
                {/* featured-imagebox-blog */}
                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                  <div className="featured-content">{/* featured-content */}
                    <div className="featured-title">{/* featured-title */}
                      <h5>Dr.Priya N.S</h5>
                    </div>
                    <div className="post-meta">{/* post-meta */}
                      <span className="ttm-meta-line"><i className="fa fa-comments" />Start up Activity Coordinator </span>
                      <span className="ttm-meta-line"><i className="fa fa-user" />Professor, Dept. of Oral Pathology </span>
                    </div>
                  </div>
                </div>{/* featured-imagebox-blog end */}
              </div>
              <div className="col-lg-4 col-md-6">
                {/* featured-imagebox-blog */}
                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                  <div className="featured-content">{/* featured-content */}
                    <div className="featured-title">{/* featured-title */}
                      <h5>Dr. Soumya Krishna</h5>
                    </div>
                    <div className="post-meta">{/* post-meta */}
                      <span className="ttm-meta-line"><i className="fa fa-comments" />IPR Activity Co-ordinator </span>
                      <span className="ttm-meta-line"><i className="fa fa-user" />Professor, Dept. of Oral Medicine & Radiology </span>
                    </div>
                  </div>
                </div>{/* featured-imagebox-blog end */}
              </div>
              <div className="col-lg-4 col-md-6">
                {/* featured-imagebox-blog */}
                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                  <div className="featured-content">{/* featured-content */}
                    <div className="featured-title">{/* featured-title */}
                      <h5>Dr.Bharath Reddy</h5>
                    </div>
                    <div className="post-meta">{/* post-meta */}
                      <span className="ttm-meta-line"><i className="fa fa-comments" />Innovation Activity Co-ordinator </span>
                      <span className="ttm-meta-line"><i className="fa fa-user" />Professor, Dept. of Orthodontics</span>
                    </div>
                  </div>
                </div>{/* featured-imagebox-blog end */}
              </div>
              <div className="col-lg-4 col-md-6">
                {/* featured-imagebox-blog */}
                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                  <div className="featured-content">{/* featured-content */}
                    <div className="featured-title">{/* featured-title */}
                      <h5>Dr. Rajiv N.P</h5>
                    </div>
                    <div className="post-meta">{/* post-meta */}
                      <span className="ttm-meta-line"><i className="fa fa-comments" />Internship Activity Co-ordinator </span>
                      <span className="ttm-meta-line"><i className="fa fa-user" />Professor, Dept. of Periodontics</span>
                    </div>
                  </div>
                </div>{/* featured-imagebox-blog end */}
              </div>
              <div className="col-lg-4 col-md-6">
                {/* featured-imagebox-blog */}
                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                  <div className="featured-content">{/* featured-content */}
                    <div className="featured-title">{/* featured-title */}
                      <h5>Dr. Adarsh M.S</h5>
                    </div>
                    <div className="post-meta">{/* post-meta */}
                      <span className="ttm-meta-line"><i className="fa fa-comments" />NIRF Co-ordinator </span>
                      <span className="ttm-meta-line"><i className="fa fa-user" />Professor, Dept. of Conservative Dentistry & Endodontics</span>
                    </div>
                  </div>
                </div>{/* featured-imagebox-blog end */}
              </div>
              <div className="col-lg-4 col-md-6">
                {/* featured-imagebox-blog */}
                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                  <div className="featured-content">{/* featured-content */}
                    <div className="featured-title">{/* featured-title */}
                      <h5>Dr. Santosh Nandimath</h5>
                    </div>
                    <div className="post-meta">{/* post-meta */}
                      <span className="ttm-meta-line"><i className="fa fa-comments" />Social Media Co-ordinator </span>
                      <span className="ttm-meta-line"><i className="fa fa-user" />Reader, Dept. of Oral Surgery</span>
                    </div>
                  </div>
                </div>{/* featured-imagebox-blog end */}
              </div>
              <div className="col-lg-4 col-md-6">
                {/* featured-imagebox-blog */}
                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                  <div className="featured-content">{/* featured-content */}
                    <div className="featured-title">{/* featured-title */}
                      <h5>Dr. Amitha</h5>
                    </div>
                    <div className="post-meta">{/* post-meta */}
                      <span className="ttm-meta-line"><i className="fa fa-comments" />Member </span>
                      <span className="ttm-meta-line"><i className="fa fa-user" />Reader, Dept. of Pedodontics</span>
                    </div>
                  </div>
                </div>{/* featured-imagebox-blog end */}
              </div>
              <div className="col-lg-4 col-md-6">
                {/* featured-imagebox-blog */}
                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                  <div className="featured-content">{/* featured-content */}
                    <div className="featured-title">{/* featured-title */}
                      <h5>Dr. Arjun Mitra</h5>
                    </div>
                    <div className="post-meta">{/* post-meta */}
                      <span className="ttm-meta-line"><i className="fa fa-comments" />Member </span>
                      <span className="ttm-meta-line"><i className="fa fa-user" />Senior Lecturer, Dept. of Prosthodontics </span>
                    </div>
                  </div>
                </div>{/* featured-imagebox-blog end */}
              </div>
            </div>{/* row end */}

          </div>
        </section>


      </div>

    )
  }
}


export default InnovationCell;