import React, { Component } from "react";
import image1 from "../../../../images/OralMedicinedept/Hoddept.jpg";
// import image1 from "../../../../images/OralMedicinedept/Hoddept.JPG"
import firestore from "../../../../Config/Config";
import { collection, getDocs } from "firebase/firestore";
import { Link, NavLink } from "react-router-dom";

export class OralMedicineSidebar extends Component {
  render() {
    return (
      <div>
        <div className="widget widget-nav-menu">
          <ul className="widget-menu">
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/OralMedicineRadiology"}>
                About
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/OMRFacultiesandStaff"}>
                Faculties and Staff
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/OMRTeachingLearning"}>
                Teaching - Learning
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/OMRResearch"}>
                Research and Innovations
              </NavLink>
            </li>

            <li>
              <NavLink to={process.env.PUBLIC_URL + "/OMRActivities"}>
                Activities
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="widget">
          <div className="section-title with-desc text-center clearfix">
            <div className="title-header">
              <h2 className="title">
                Head of the{" "}
                <span className="ttm-textcolor-blue">Department</span>
              </h2>
            </div>
          </div>
          <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
            <img className="d-block w-100" src={image1} alt="img" />
          </div>
          <h5 style={{ color: "black" }}>Dr. Deepak T. A. Professor</h5>
          <h6> Education and Qualification:</h6>
          <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
            <li>
              {" "}
              <i class="fas fa-star"></i>BDS: Vokkaligara Sangha Dental College
              and Hospital, Bangalore.1990 - 1995
            </li>
            <li>
              <i class="fas fa-star"></i>MDS: Oral Medicine & Radiology A. B.
              Shetty Dental College and Hospital, Mangalore. 2000 – 2002
            </li>
          </ul>
          <br />
          <h6>Work Experience: </h6>
          <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
            <li>
              <i class="fas fa-star"></i>Assistant professor from 2002 to 2005
            </li>
            <li>
              <i class="fas fa-star"></i>Associate professor 2005 2008
            </li>
            <li>
              <i class="fas fa-star"></i>Professor 2008 - 2020
            </li>
            <li>
              <i class="fas fa-star"></i>Professor and HOD till date{" "}
            </li>
            <li>
              <i class="fas fa-star"></i>TOTAL 19 YRS OF TEACHING EXPERIENCE
            </li>
          </ul>
        </div>

        {/* <div className="widget widget_media_image">
                    <h4>Head of the department</h4>
                    <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
                        <img className="d-block w-100" src={image1} alt="img" />
                    </div>
               
                <h5 >Dr. Deepak T. A. Professor</h5>
                <h6>	Education and Qualification:</h6>
                <ul>
                    <li>BDS: Vokkaligara Sangha Dental College and Hospital, Bangalore.1990 - 1995</li>
                    <li>MDS: Oral Medicine & Radiology  A. B. Shetty Dental College and Hospital, Mangalore. 2000 – 2002</li>
                </ul>
                <h6>Work Experience: </h6>
                <ul>
                    <li>Assistant professor from 2002 to 2005</li>
                    <li>Associate professor 2005  2008</li>
                    <li>Professor 2008 - 2020</li>
                    <li>Professor and HOD till date </li>
                    <li>TOTAL 19 YRS OF TEACHING EXPERIENCE</li>
                </ul>

                </div> */}
        <div className="widget widget-download">
          <h3 className="widget-title">Contact</h3>
          <ul className="download">
            <li>
              <i className="fa fa-phone" />
              <a> 080-22426705</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default OralMedicineSidebar;
