import React, { Component } from "react";
import Header from "../layout/Header";
import Image from "../../images/princip.jpg";

export class PrincipalVSDC extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row principalbanner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      Principal
                    </h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                          className="ttm-color-home"
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">VSDCH</span>
                    </div>
                  </div>
                  {/* /.page-title-captions */}
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        {/* Principal-section */}
        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-6">
                {/* section title */}
                <div
                  className="section-title with-desc clearfix"
                  style={{ paddingBlockEnd: "40px" }}
                >
                  <div className="title-header">
                    <h2 className="title">
                      Principal<span> Message</span>
                    </h2>
                  </div>
                  <div className="title-desc-ff">
                    <p className="text-justify">
                      It is my honor to welcome you to V. S. Dental College and
                      Hospital, a unique dental Institute committed to develop
                      health professionals who will maintain and improve the
                      oral health status of individuals and populations. We
                      focus on developing dental education suitable to produce
                      future empowered practitioners.Broadly stated, our basic
                      goals are to <br />
                      (1) educate students to serve their patients and
                      communities well and
                      <br />
                      (2) prepare students to continue to grow in skill and
                      knowledge over their lifetime in practice.
                      <p>
                        To achieve these goals, we strive to make you learn not
                        only technical dentistry skills but dental education
                        which is scientifically based, clinically relevant,
                        medically informed, and socially responsible. We provide
                        inter-disciplinary and multi-disciplinary learning
                        exposures through continuing dental education programs,
                        research and innovation guidance and exemplary clinical
                        dentistry guidance through up-to-date art and sciences
                        of dentistry. Simultaneously, we also train our students
                        to understand the oral health needs of communities and
                        engage in community service. It is our aim to help
                        students to be equipped to choose any career they deem
                        themselves fit, either in academics, research or public
                        health. We have excellent multi-talented faculty to
                        monitor and evaluate the education programs for the
                        overall development of the students. We are also aware
                        of the complex emotional and social factors influencing
                        the student in achieving a successful career beyond
                        syllabus. Hence, we prepare the student to face the
                        competitive work environment during and after graduation
                        through programs to equip them with life skills, career
                        guidance and scientific temperament.The critical and
                        creative thinking skills training is provided along with
                        the regular syllabus, the success of such training is
                        witnessed by the achievement of our alumni.We are
                        committed to develop not just dental practitioner but an
                        individual capable of life long learning for a fulfilled
                        life.
                      </p>
                    </p>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <div className="featured-imagebox featured-imagebox-portfolio ">
                      <div className="featured-thumbnail">
                        <img
                          className="img-fluid"
                          src={Image}
                          style={{ height: "500px" }}
                          title="single-img-seven"
                          alt="single-img-seven"
                        />
                      </div>
                      {/* ttm_single_image-wrapper end */}
                      <div>
                        <div>
                          <br />
                        </div>
                        <div>
                          <div
                            className="member-info-box border"
                            style={{ padding: "10px", textAlign: "left" }}
                          >
                            <div className="timing-box">
                              <h4>Dr.RajKumar G C</h4>
                            </div>
                            <ul className="info-list">
                              <li>
                                Principal , MDS[Specialty: Oral and
                                Maxillo-Facial Surgery]
                              </li>
                              <li>
                                <i className="ti ti-location-pin" />
                                <span>
                                  {" "}
                                  V S Dental College & Hospital Bengaluru.
                                </span>
                              </li>
                              <li>
                                <i className="ti ti-mobile" />
                                <span>
                                  {" "}
                                  Phone: 9845282113 Email: rajk23in@yahoo.co.in
                                </span>
                              </li>
                              <li>
                                <i className="ti ti-comment" />
                                <span>
                                  College Email: vsdchbangalore@gmail.com
                                </span>
                              </li>
                              <li>
                                <span>
                                  SENATE MEMBER- Rajiv Gandhi University of
                                  Health Sciences (2013-2016)
                                </span>
                              </li>
                              <li>
                                <span>
                                  Research committee member -Rajiv Gandhi
                                  University of Health Sciences.
                                </span>
                              </li>
                              <li>
                                <span>
                                  Squad chief: University examinations [Rajiv
                                  Gandhi University of Health Sciences.]
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* Principal-section end*/}
      </div>
    );
  }
}

export default PrincipalVSDC;
