import React, { Component } from "react";
import Slider from "react-slick";
import CountUp from "react-countup";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Header from "../layout/Header";
import { MDBProgress } from "mdbreact";
import image from "../../images/image11.jpeg";

export class AboutVSDC extends Component {
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row aboutbanner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      About VSDCH
                    </h4>
                    <div className="ttm-color-home">
                      <span className="ttm-color-home">
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className="ttm-color-home">RVS</span>
                    </div>
                  </div>
                  {/* /.page-title-captions */}
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}

        <section className="ttm-row aboutus-section-style3 clearfix">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-6">
                <div className="position-relative">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center mr-35 res-991-m-0">
                    <img
                      className="img-fluid-height"
                      src={image}
                      title="single-img-nine"
                      alt="single-img-nine"
                    />
                  </div>
                  {/* ttm_single_image-wrapper end */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="res-991-mt-30">
                  {/* section title */}
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        About <span> VSDCH</span>
                      </h2>
                    </div>
                    <div className="title-desc">
                      <p className="text-justify">
                        Vokkaligara Sangha Dental College and Hospital was
                        established in the year 1986 and is located in the heart
                        of the city close to Central Railway and Bus Station.
                        Occupying nearly 1,20,000 sq.ft. V.S. Dental College was
                        affiliated to Bangalore University and now is affiliated
                        to Rajiv Gandhi University of Health Sciences, Bangalore
                        , Karnataka. The College got its recognition from D.C.I.
                        in the year 1992. Dental Council of India has permitted
                        Post Graduate Studies in the year 2001 in all the
                        specialties Dentistry. The Institution started its first
                        batch with 52 students and the current intake for BDS is
                        100. The MDS courses started in the year 2001-02 with
                        the present intake of 39 seats. The Institution offers
                        Ph.D program in the subject of Oral & Maxillo-Facial
                        Surgery and Conservative Dentistry & Endodontics.
                      </p>
                      <p className="text-justify">
                        {" "}
                        Today V.S. Dental College and Hospital is one of the
                        most sought after institutions in India both for
                        undergraduate and postgraduate admissions. Clinics and
                        laboratories are well equipped with state of the art
                        equipment. Training in medical subjects is imparted at
                        KIMS Campus, with good inflow of patients (300 - 500
                        patients).
                      </p>
                    </div>
                  </div>
                  {/* section title end */}
                  {/* row */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className=" clearfix">
          <div className="container-xl">
            <div className="row no-gutters mt_100 res-991-mt-0">
              <div className="col-md-6">
                <div className="ttm-bgcolor-darkgrey ttm-textcolor-white z-11 position-relative h-100">
                  <div className="layer-content">
                    <div className="spacing-2">
                      <div className="section-title ttm with-desc clearfix">
                        <div className="title-header">
                          <h5>VSDCH</h5>
                          <h2 className="title">Vision</h2>
                        </div>
                      </div>
                      <div className="ttm-progress-bar">
                        <div className="progress-bar-title float-left">
                          To endow as the renowned oral healthcare Institution
                          emphasizing on excellent education, moral values, life
                          skills and core research combined with scientific
                          principles.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-skincolor ttm-textcolor-white">
                  <div className="section-title ttm with-desc clearfix">
                    <div className="spacing-2">
                      <div className="title-header">
                        <h5>VSDCH</h5>
                        <h2 className="title">Mission</h2>
                      </div>
                      <div>
                        <ul>
                          <li>
                            {" "}
                            * To promote creative and scientific learning
                          </li>
                          <li>
                            {" "}
                            * To encourage collaborative research activities{" "}
                          </li>
                          <li>
                            {" "}
                            * To indulge in effective community services and
                            oral care practices{" "}
                          </li>
                          <li>
                            * To nurture ethical and empathetic patient care
                            approaches
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* zero-padding-section */}

        <section className="ttm-row faqs-section clearfix" id="highlights">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-8 offset-lg-2">
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Highlights of <span> VSDCH</span>
                    </h2>
                  </div>
                </div>
                {/* section-title end */}
              </div>
            </div>
            {/* row end */}
            {/* row */}
            <div className="row">
              <div
                className="col-lg-6 res-991-mt-30"
                style={{ fontWeight: "700" }}
              >
                {/* accordian */}
                <Accordion preExpanded={["foo"]}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Advantage OF THE LOCATION OF THE INSTITUTION
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Located in the heart of the city{" "}
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Seamless connectivity to all major public
                            transportation hubs, namely railway station, bus
                            station and metro rail station
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Hassel free commuting
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Close proximity to all necessary conveniences and
                            other social infrastructure{" "}
                          </span>
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        RICH CLINICAL ORAL HEALTHCARE EXPOSURE
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Extensive exposure to clinical cases with highest
                            outpatient department numbers
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Diverse clinical cases in all specialties of
                            dentistry, surgical techniques and innovative
                            interventions in oral healthcare delivery
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            State of the art operation theatres in collaboration
                            with Kempe Gowda Institute of Medical Sciences
                            Hospital.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Institute has exclusive 376 state of the art dental
                            chairs with latest technology and equipment.
                          </span>
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        EXPOSURE TO COMMUNITY HEALTH DEVELOPMENT PROGRAMS
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Largest number of Community Development Programs in
                            Bengaluru rural and urban districts, that cater to
                            the community by providing free dental treatment.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Community Health Training of students through
                            effective programs with role plays, education videos
                            and talk to the public.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Integrating oral health in systemic health
                            management
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Longest running School Oral Health Program through
                            which students and children benefit.
                          </span>
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        ACADEMIC TRAINING PAR EXCELLENCE IN DENTISTRY
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Innovative education programs exposing students to
                            clinical and classroom teaching, engaging them in
                            research and developing their leadership skills.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Comprehensive, expanded programs in oral health,
                            including Continuing Dental Education Programs, are
                            available to train a broad range of dentistry in
                            multiple specialties.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Educating highly qualified professionals to serve
                            the oral health needs of patients and families
                            throughout the region and beyond.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Key skills gained from BDS and MDS degrees are
                            ability to work with and manage multi-skilled team,
                            enhanced social skills such as life skills and
                            scientific temper; flexibility and ability to adapt
                            and critical thinking.
                          </span>
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>

              <div
                className="col-lg-6 res-991-mt-30"
                style={{ fontWeight: "700" }}
              >
                {/* accordian */}
                <Accordion preExpanded={["foo"]}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        INNOVATIVE RESEARCH OPPORTUNITIES IN THE FIELD OF ORAL
                        HEALTH
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Extensive research opportunities are available
                            through advanced degrees in dentistry such as MDS,
                            Ph.D programs for students wanting to learn beyond
                            graduation
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Training students under able guidance from faculty
                            through Student grants from RGUHS and ICMR.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Training students through Scientific Temper
                            Workshops for acquiring critical thinking and
                            creative thinking capacity for research
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Opportunities for collaborative research for
                            postgraduate dental students through faculty
                            mentoring
                          </span>
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        HYGIENIC HOSTEL AND WELFARE MEASURES
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Well facilitated and secure hostel for both boys and
                            girls.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Nutritious meals and snacks for overall development
                            of students.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Pest control measures are ensured for good health of
                            the students
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Installation of good quality incinerator
                          </span>
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        WELFARE MEASURES FOR THE STUDENTS
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Anti-ragging committee for a healthy learning
                            atmosphere
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Committee to address sexual harassment at campus
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Mentorship of students
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Life skills workshops
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Immunization programs within the campus
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Training of students in Infection Prevention and
                            Control measures for COVID-19.
                          </span>
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
            {/* row end*/}
            {/* row */}
            {/* row end*/}
          </div>
        </section>
        {/* END Uniqueness Of VSDC */}
        {/* Excellent state of art library */}
        <section className="ttm-row bottomzero-padding-section bg-img-lib position-relative z-1 clearfix">
          <div className="container-xl">
            <div className="row no-gutters">
              <div className="col-lg-10 mb_80 res-991-mb-0">
                <div className="ttm-bgcolor-skincolor ttm-textcolor-white">
                  <div
                    className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                    style={{ marginLeft: "-400px", opacity: "1" }}
                  >
                    <div className="ttm-bg-layer-inner"></div>
                  </div>
                  <div className="layer-content">
                    <div className="spacing-5">
                      {/* section title */}
                      <div className="section-title mb-4 clearfix">
                        <div className="title-header">
                          {/* <h5>About Altech</h5> */}
                          <h2 className="title">
                            WELL EQUIPPED STATE OF <span>ART LIBRARY</span>
                          </h2>
                        </div>
                        {/* <div className="title-desc">
                              <p>
                                We listen. We advise. We design, together. Happy
                                customers and ongoing relationships are what we
                                strive for. Success is measured by results, the most
                                important being how our clients feel about their
                                experience with us. These reviews we’re onto
                                something.
                              </p>
                            </div> */}
                      </div>
                      <div className="row pt-3">
                        <div className="col-md-4">
                          {/*  featured-icon-box */}
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon">
                              {/*  featured-icon */}
                              <div className="ttm-icon ttm-icon_element-size-md">
                                <i className="flaticon flaticon-24h" />
                                {/*  ttm-icon */}
                              </div>
                            </div>
                            <div className="featured-content">
                              {/*  featured-content */}
                              <div className="featured-title">
                                {/*  featured-title */}
                                <h5 className="fw-500">Journal-48</h5>
                              </div>
                            </div>
                          </div>
                          {/*  featured-icon-box END */}
                        </div>
                        <div className="col-md-4">
                          {/*  featured-icon-box */}
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon">
                              {/*  featured-icon */}
                              <div className="ttm-icon ttm-icon_element-size-md">
                                <i className="flaticon flaticon-data" />
                                {/*  ttm-icon */}
                              </div>
                            </div>
                            <div className="featured-content">
                              {/*  featured-content */}
                              <div className="featured-title">
                                {/*  featured-title */}
                                <h5 className="fw-500">International-33</h5>
                              </div>
                            </div>
                          </div>
                          {/*  featured-icon-box END */}
                        </div>
                        <div className="col-md-4">
                          {/*  featured-icon-box */}
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon">
                              {/*  featured-icon */}
                              <div className="ttm-icon ttm-icon_element-size-md">
                                <i className="flaticon flaticon-code" />
                                {/*  ttm-icon */}
                              </div>
                            </div>
                            <div className="featured-content">
                              {/*  featured-content */}
                              <div className="featured-title">
                                {/*  featured-title */}
                                <h5 className="fw-500"> National - 15</h5>
                              </div>
                            </div>
                          </div>
                          {/*  featured-icon-box END */}
                        </div>
                      </div>
                      {/* section title end */}

                      <div className="row ttm-fid-row-wrapper">
                        <div className="col-md-4 col-sm-4">
                          {/*ttm-fid*/}
                          <div className="ttm-fid inside ttm-fid-view-lefticon style1">
                            <div className="ttm-fid-left">
                              {/*ttm-fid-left*/}
                              <div className="ttm-fid-icon-wrapper">
                                <i className="flaticon flaticon-developer" />
                              </div>
                            </div>
                            <div className="ttm-fid-contents text-left">
                              {/*ttm-fid-contents*/}
                              <h4 className="ttm-fid-inner">
                                <CountUp
                                  start={7700}
                                  end={7742}
                                  duration={30}
                                />
                              </h4>
                              <h3 className="ttm-fid-title">Books</h3>
                              {/*ttm-fid-title*/}
                            </div>
                          </div>
                          {/* ttm-fid end*/}
                        </div>
                        <div className="col-md-4 col-sm-4">
                          {/*ttm-fid*/}
                          <div className="ttm-fid inside ttm-fid-view-lefticon style1">
                            <div className="ttm-fid-left">
                              <div className="ttm-fid-icon-wrapper">
                                <i className="flaticon flaticon-interaction" />
                              </div>
                            </div>
                            <div className="ttm-fid-contents text-left">
                              <h4 className="ttm-fid-inner">
                                <CountUp
                                  start={2900}
                                  end={2945}
                                  duration={30}
                                />
                              </h4>
                              <h3 className="ttm-fid-title">Titles</h3>
                            </div>
                          </div>
                          {/* ttm-fid end*/}
                        </div>
                        <div className="col-md-4 col-sm-4">
                          {/*ttm-fid*/}
                          <div className="ttm-fid inside ttm-fid-view-lefticon style1">
                            <div className="ttm-fid-left">
                              <div className="ttm-fid-icon-wrapper">
                                <i className="flaticon flaticon-global-1" />
                              </div>
                            </div>
                            <div className="ttm-fid-contents text-left">
                              <h4 className="ttm-fid-inner">
                                <CountUp start={0} end={48} duration={30} />
                              </h4>
                              <h3 className="ttm-fid-title">
                                {" "}
                                Online e-journals (HELINET){" "}
                              </h3>
                            </div>
                          </div>
                          {/* ttm-fid end*/}
                        </div>
                      </div>

                      <div className="row ttm-fid-row-wrapper">
                        <div className="col-md-4">
                          {/*  featured-icon-box */}
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon">
                              {/*  featured-icon */}
                              <div className="ttm-icon ttm-icon_element-size-md">
                                <i className="flaticon flaticon-24h" />
                                {/*  ttm-icon */}
                              </div>
                            </div>
                            <div className="featured-content">
                              {/*  featured-content */}
                              <div className="featured-title">
                                {/*  featured-title */}
                                <h5 className="fw-500">Printer - 2</h5>
                              </div>
                            </div>
                          </div>
                          {/*  featured-icon-box END */}
                        </div>
                        <div className="col-md-4">
                          {/*  featured-icon-box */}
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon">
                              {/*  featured-icon */}
                              <div className="ttm-icon ttm-icon_element-size-md">
                                <i className="flaticon flaticon-data" />
                                {/*  ttm-icon */}
                              </div>
                            </div>
                            <div className="featured-content">
                              {/*  featured-content */}
                              <div className="featured-title">
                                {/*  featured-title */}
                                <h5 className="fw-500">
                                  Photocopying machine – 01
                                </h5>
                              </div>
                            </div>
                          </div>
                          {/*  featured-icon-box END */}
                        </div>
                        <div className="col-md-4">
                          {/*  featured-icon-box */}
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon">
                              {/*  featured-icon */}
                              <div className="ttm-icon ttm-icon_element-size-md">
                                <i className="flaticon flaticon-code" />
                                {/*  ttm-icon */}
                              </div>
                            </div>
                            <div className="featured-content">
                              {/*  featured-content */}
                              <div className="featured-title">
                                {/*  featured-title */}
                                <h5 className="fw-500"> Internet - 12 + 19</h5>
                              </div>
                            </div>
                          </div>
                          {/*  featured-icon-box END */}
                        </div>
                      </div>
                      <div className="row ttm-fid-row-wrapper">
                        <div className="col-md-4 col-sm-4">
                          {/*ttm-fid*/}
                          <div className="ttm-fid inside ttm-fid-view-lefticon style1">
                            <div className="ttm-fid-left">
                              {/*ttm-fid-left*/}
                              <div className="ttm-fid-icon-wrapper">
                                <i className="flaticon flaticon-developer" />
                              </div>
                            </div>
                            <div className="ttm-fid-contents text-left">
                              {/*ttm-fid-contents*/}
                              <h4 className="ttm-fid-inner">
                                <CountUp start={475} end={514} duration={30} />
                              </h4>
                              <h3 className="ttm-fid-title">
                                {" "}
                                Theses library dissertation
                              </h3>
                              {/*ttm-fid-title*/}
                            </div>
                          </div>
                          {/* ttm-fid end*/}
                        </div>
                        <div className="col-md-4 col-sm-4">
                          {/*ttm-fid*/}
                          <div className="ttm-fid inside ttm-fid-view-lefticon style1">
                            <div className="ttm-fid-left">
                              <div className="ttm-fid-icon-wrapper">
                                <i className="flaticon flaticon-interaction" />
                              </div>
                            </div>
                            <div className="ttm-fid-contents text-left">
                              <h4 className="ttm-fid-inner">
                                <CountUp start={475} end={514} duration={30} />
                              </h4>
                              <h3 className="ttm-fid-title">CD - ROM</h3>
                            </div>
                          </div>
                          {/* ttm-fid end*/}
                        </div>
                        <div className="col-md-4 col-sm-4">
                          {/*ttm-fid*/}
                          <div className="ttm-fid inside ttm-fid-view-lefticon style1">
                            <div className="ttm-fid-left">
                              <div className="ttm-fid-icon-wrapper">
                                <i className="flaticon flaticon-global-1" />
                              </div>
                            </div>
                            <div className="ttm-fid-contents text-left">
                              <h4 className="ttm-fid-inner">
                                <CountUp start={200} end={241} duration={30} />
                              </h4>
                              <h3 className="ttm-fid-title">Bound Volume</h3>
                            </div>
                          </div>

                          {/* ttm-fid end*/}
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-md-4">
                          {/*  featured-icon-box */}
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon">
                              {/*  featured-icon */}
                              <div className="ttm-icon ttm-icon_element-size-md">
                                <i className="flaticon flaticon-24h" />
                                {/*  ttm-icon */}
                              </div>
                            </div>
                            <div className="featured-content">
                              {/*  featured-content */}
                              <div className="featured-title">
                                {/*  featured-title */}
                                <h5 className="fw-500">
                                  Seating capacity – 325
                                </h5>
                              </div>
                            </div>
                          </div>
                          {/*  featured-icon-box END */}
                        </div>
                        <div className="col-md-4">
                          {/*  featured-icon-box */}
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon">
                              {/*  featured-icon */}
                              <div className="ttm-icon ttm-icon_element-size-md">
                                <i className="flaticon flaticon-data" />
                                {/*  ttm-icon */}
                              </div>
                            </div>
                            <div className="featured-content">
                              {/*  featured-content */}
                              <div className="featured-title">
                                {/*  featured-title */}
                                <h5 className="fw-500">
                                  Total library area – 9000 sq. ft
                                </h5>
                              </div>
                            </div>
                          </div>
                          {/*  featured-icon-box END */}
                        </div>
                        <div className="col-md-4">
                          {/*  featured-icon-box */}
                          <div className="featured-icon-box style4 left-icon">
                            <div className="featured-icon">
                              {/*  featured-icon */}
                              <div className="ttm-icon ttm-icon_element-size-md">
                                <i className="flaticon flaticon-code" />
                                {/*  ttm-icon */}
                              </div>
                            </div>
                            <div className="featured-content">
                              {/*  featured-content */}
                              <div className="featured-title">
                                {/*  featured-title */}
                                <h5 className="fw-500">
                                  {" "}
                                  Kannada Novels and Textbooks - 424
                                </h5>
                              </div>
                            </div>
                          </div>
                          {/*  featured-icon-box END */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* zero-padding-section end */}
        {/* team-section */}

        {/* team-section end*/}
        {/* our-partner-section */}

        {/*VSDC in number Section */}
        <section className="ttm-row fid-section ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes mt_225 res-991-mt-0 clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="section-title with-desc text-center clearfix">
              <div className="title-header">
                <h2 className="title">
                  VSDCH in<span> Numbers</span>
                </h2>
              </div>
            </div>
            {/* row */}
            <div className="row">
              <div className="col-md-3 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-online-library" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={646} duration={30} />
                    </h4>
                    <h3 className="ttm-fid-title">No of Students</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
              <div className="col-md-3 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-developer" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={954} duration={30} />
                    </h4>
                    <h3 className="ttm-fid-title">Number of Faculty</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
              <div className="col-md-3 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-24h" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={3} duration={30} />
                    </h4>
                    <h3 className="ttm-fid-title">Number of PhD</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
              <div className="col-md-3 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-report" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={1640} duration={30} />
                    </h4>
                    <h3 className="ttm-fid-title">Number of Alumni</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
            </div>
            {/* row end*/}
          </div>
        </section>
      </div>
    );
  }
}

export default AboutVSDC;
