import React, { Component } from "react";
import Header from "../../../layout/Header";
import firestore from "../../../../Config/Config";
import { collection, getDocs, doc, onSnapshot } from "firebase/firestore";
import { useLocation } from "react-router-dom";

export class TechnicalStaff extends Component {
  constructor(props) {
    super(props);

    this.dynamicData = {
      TechnicalStaff: [],
    };

    this.state = {
      TechnicalStaff: [],
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      //console.log(Snapshot);
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
      });
    }
    return result;
  }

  async componentDidMount() {
    // const Location = useLocation();
    // const { FacOne } = Location.state;
    // console.log("FacOne");
    this.state.TechnicalStaff = this.props.match.params.id;
    console.log("TechnicalStaff", this.props.match.params.id);
    onSnapshot(doc(firestore, "OralMedicine", "TechnicalStaff"), (doc) => {
      if (doc.exists()) {
        console.log("doc", doc);
        console.log("docData", doc.data());
        Object.entries(doc.data()).forEach(([key, value]) => {
          this.dynamicData.TechnicalStaff.push(
            Object.assign({ Id: key }, value)
          );
        });
        this.setState({
          TechnicalStaff: this.GetFacultyById(this.dynamicData.TechnicalStaff),
        });
      } else {
        // this.setState({
        //     Data: []
        // })
      }
    });
  }
  GetFacultyById(param1) {
    let FacDetail = param1.filter((a) => {
      return a.Id === this.props.match.params.id;
    });
    return FacDetail;
  }
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Faculty Details</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href="/">
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Home
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Our Staff</span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span> Teaching Learning</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <section className="ttm-row team-details-section clearfix">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="row">
                {this.state.TechnicalStaff.length &&
                  this.state.TechnicalStaff.map(function (item, index) {
                    return (
                      <div className="col-lg-4">
                        <div className="ttm-featured-wrapper" key={index}>
                          <div className="featured-thumbnail text-center">
                            <img
                              className="img-fluid"
                              src={item.Image}
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {this.state.TechnicalStaff.length &&
                  this.state.TechnicalStaff.map(function (item, index) {
                    return (
                      <div className="col-lg-8" Key={index}>
                        <div className="ttm-team-member-content shadow-box res-991-mt-30">
                          <div className="ttm-team-member-single-list">
                            <h2 className="ttm-team-member-single-title">
                              {item.Name}
                            </h2>
                            <span className="ttm-team-member-single-position">
                              {item.Designation}
                            </span>
                            <p>Role : {item.Role}</p>
                            {/* separator */}
                            <div className="separator">
                              <div className="sep-line mt-4 mb-4" />
                            </div>
                            {/* separator */}
                            <div className="ttm-team-data">
                              <div className="ttm-team-details-wrapper">
                                <ul className="ttm-team-details-list clearfix">
                                  <li>
                                    <div className="ttm-team-list-title">
                                      <i className="fa fa-phone" /> Phone :
                                    </div>
                                    <div className="ttm-team-list-value">
                                      <a href="tel:+1800200145">
                                        {" "}
                                        {item.PhoneNumber}
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="ttm-team-list-title">
                                      <i className="ti ti-email" /> Email :
                                    </div>
                                    <div className="ttm-team-list-value">
                                      <a href="mailto:info@example.com">
                                        {item.EmailId}
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="ttm-team-list-title">
                                      <i className="ti ti-book" /> Qualification
                                      :
                                    </div>
                                    <div className="ttm-team-list-value">
                                      <p>{item.Qualification}</p>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="ttm-team-list-title">
                                      <i className="ti ti-time" /> Experience :
                                    </div>
                                    <div className="ttm-team-list-value">
                                      {item.Experience}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {/* separator */}
                            <div className="separator">
                              <div className="sep-line mt-3 mb-4" />
                            </div>
                            {/* separator */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {/* row end */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default TechnicalStaff;
