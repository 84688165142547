import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDPhTnisPzg0SCZBoIYj-JgUMieDDO8WmE",
    authDomain: "rvs-dental-col.firebaseapp.com",
    projectId: "rvs-dental-col",
    storageBucket: "rvs-dental-col.appspot.com",
    appId: "1:373233480514:web:5b51a444591cd8e52b0edb",
  };

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export default firestore;