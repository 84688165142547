import React, { Component } from "react";
import Slider from "react-slick";
import { MDBProgress } from "mdbreact";
import { Header } from "./layout/Header";
import { CarouselPage } from "./banner/Home1_banner";
import { getDocs, collection } from "firebase/firestore";
import ReactReadMoreReadLess from "react-read-more-read-less";
import firestore from "../Config/Config";
import "react-image-lightbox/style.css";

import pdf from "../assets/BDScurriculum.pdf";
import pdf1 from "../assets/PhdCurriculum.pdf";
import image2 from "../images/Highlightsimages/image1.jpg";
import image3 from "../images/Highlightsimages/image2.JPG";
import image4 from "./../images/Highlightsimages/image3.jpg";
import image5 from "./../images/Highlightsimages/image4.jpeg";
import image6 from "./../images/Highlightsimages/image5.JPG";
import image7 from "../images/Highlightsimages/image6.JPG";
import image8 from "../images/Highlightsimages/image7.jpg";
import image9 from "../images/Highlightsimages/image8.JPG";
import image10 from "../images/Highlightsimages/GBlack.jpg";
import image11 from "../images/Highlightsimages/John.jpg";
import image12 from "../images/Highlightsimages/Pierre.jpg";
import NoData from "./pages/NoData";
import GCHA from "../assets/GCHA.png";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "black",
        borderradius: "10px",
      }}
      onClick={onClick}
    />
  );
}

export class Home extends Component {
  constructor(props) {
    super(props);
    this.dynamicData = {
      notifications: [],
      events: [],
      facultyAcheivers: [],
      studentAcheivers: [],
    };
  }

  getDynamicData(Snapshot) {
    var result = {};
    if (Snapshot) {
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        var arr = [];
        for (var key in docData) {
          arr.push(docData[key]);
        }
        result[doc.id] = arr;
        console.log("SnapShot Home.JS", arr);
      });
    }
    return result;
  }

  async componentDidMount() {
    const path = collection(firestore, "Home");
    const notificationKey = "Notifications";
    const eventsKey = "Events";
    const facAchKey = "FacultyAchievers";
    const stuAchKey = "StudentAchievers";

    const Snapshot = await getDocs(path);
    if (Snapshot) {
      Snapshot.forEach((doc) => {
        const docData = doc.data();
        for (var key in docData) {
          console.log(key, docData[key]);
        }
      });
      console.log("snap", Snapshot);

      const allDynamicData = this.getDynamicData(Snapshot);
      this.dynamicData.notifications = allDynamicData[notificationKey];
      this.dynamicData.events = allDynamicData[eventsKey];

      this.dynamicData.facultyAcheivers = allDynamicData[facAchKey];
      this.dynamicData.studentAcheivers = allDynamicData[stuAchKey];
    }
    this.setState({});
  }

  render() {
    var slick_slider = {
      dots: true,
      arrow: true,
      autoplay: true,
      autoplaySpeed: 2500,
      speed: 2000,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    return (
      <div className="site-main">
        <Header />
        <CarouselPage />

        {/*Notification  section */}
        <section className="ttm-row service-section position-relative z-1 clearfix">
          <div className="container xl">
            <Slider
              className={`row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey `}
              {...slick_slider}
            >
              {this.dynamicData.notifications === undefined ? (
                <NoData />
              ) : (
                this.dynamicData.notifications.length &&
                this.dynamicData.notifications.map(function (item, index) {
                  return (
                    <div key={index}>
                      <div
                        className={`featured-icon-box left-icon style1 ttm-textcolor-white h-100 ${
                          index % 2 === 0 ? "ttm-bgcolor-skincolor" : ""
                        }`}
                      >
                        <div className="featured-icon">
                          <div
                            className={`ttm-icon ttm-icon_element-size-md ${
                              index % 2 === 0
                                ? "ttm-bgcolor-darkgrey"
                                : "ttm-bgcolor-skincolor "
                            }`}
                          >
                            <i className="flaticon flaticon-system" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-desc">{item.Header}</div>
                          {item.Content !== "undefined" &&
                          item.Content.length &&
                          item.Content !== " " ? (
                            <a
                              href={item.Content}
                              target="_blank"
                              style={{ textDecoration: "underline" }}
                            >
                              Click Here
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        </section>
        {/*Banner sub section*/}

        {/* VSDC Highlights */}
        <section className="ttm-row process-section clearfix">
          <div className="container-xl">
            <div className="row mb-5">
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    backgroundColor: "#d3d3d352",
                    padding: "2rem",
                    borderRadius: "6px",
                  }}
                >
                  <div>
                    <img
                      className="img-fluid"
                      alt="step-one"
                      title="step-one"
                      src={GCHA}
                      style={{ height: "90px" }}
                    />
                  </div>

                  <div className="title-header">
                    <h6 className="title" style={{ marginLeft: "2rem" }}>
                      "V.S.Dental College & Hospital is a member of the Global
                      Climate and Health Alliance"
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h5>VSDCH</h5>
                    <h2 className="title">
                      <span>Highlights</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-processbox-wrapper">
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-one"
                        title="step-one"
                        src={image2}
                      />
                      <div className="process-num">
                        <span className="number">01</span>
                      </div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title">
                        <h5>Strategic Location</h5>
                      </div>
                      <div className="featured-desc text-justify">
                        <p>
                          Seamless connectivity to all major public
                          transportation hubs, namely railway station, bus
                          station and metro rail station.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-one"
                        title="step-one"
                        src={image3}
                      />
                      <div className="process-num">
                        <span className="number">02</span>
                      </div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title">
                        <h5>Diverse Clinical Cases</h5>
                      </div>
                      <div className="ttm-box-description text-justify">
                        Exposure to clinical cases in all specialties of
                        dentistry, surgical techniques and innovative
                        interventions in oral healthcare delivery
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-three"
                        title="step-three"
                        src={image4}
                      />
                      <div className="process-num">
                        <span className="number">03</span>
                      </div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title">
                        <h5>Community Oral Health</h5>
                      </div>
                      <div className="ttm-box-description text-justify">
                        Largest number of Community Development Programs in
                        Bengaluru rural and urban districts, that cater to the
                        community by providing free dental treatment
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center res-991-mb-0">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-four"
                        title="step-four"
                        src={image5}
                      />
                      <div className="process-num">
                        <span className="number">04</span>
                      </div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title">
                        <h5> Excellent Academics</h5>
                      </div>
                      <div className="ttm-box-descriptio text-justify">
                        Innovative education programs exposing students to
                        clinical and classroom teaching, engaging them in
                        research and developing their leadership skills
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-processbox-wrapper">
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-one"
                        title="step-one"
                        src={image6}
                      />
                      <div className="process-num">
                        <span className="number">05</span>
                      </div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title">
                        <h5> Innovative Research</h5>
                      </div>
                      <div className="ttm-box-description text-justify">
                        Extensive research opportunities are available through
                        advanced degrees in dentistry such as MDS, Ph.D programs
                        for students wanting to learn beyond graduation
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-two"
                        title="step-two"
                        src={image7}
                      />
                      <div className="process-num">
                        <span className="number">06</span>
                      </div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title">
                        <h5>Well-equipped Library</h5>
                      </div>
                      <div className="ttm-box-description text-justify">
                        State of the art library with diverse specialty
                        textbooks and journals.
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-three"
                        title="step-three"
                        src={image8}
                      />
                      <div className="process-num">
                        <span className="number">07</span>
                      </div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title">
                        <h5> Hygienic Hostel </h5>
                      </div>
                      <div className="ttm-box-description text-justify">
                        Well facilitated and secure hostel for both boys and
                        girls
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center res-991-mb-0">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-four"
                        title="step-four"
                        src={image9}
                      />
                      <div className="process-num">
                        <span className="number">08</span>
                      </div>
                    </div>
                    <div className="ttm-pricing-plan box-shadow text-left clearfix home-highlights h-100">
                      <div className="featured-title">
                        <h5>Student Welfare</h5>
                      </div>
                      <div className="ttm-box-description text-justify">
                        All measures to meet the personal, social and medical
                        needs of students and enhance their well-being
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>

        {/*Walkthrough VSDC */}
        <section className="ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes walkthrough-bg-img clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-8 offset-lg-2">
                <div className="text-center">
                  <div className="ttm-play-icon-btn mb-5">
                    <div className="ttm-play-icon-animation">
                      <a id="playbutton">
                        <div className="ttm-icon ttm-bgcolor-white ttm-textcolor-skincolor ttm-icon_element-size-sm rounded-circle">
                          <i className="fas fa-play" />
                        </div>
                      </a>
                      <div id="videobox">
                        <i id="close-btn" className="fa fa-times"></i>
                        <div id="video-wrapper">
                          <iframe
                            width="500"
                            height="500"
                            src="https://www.youtube.com/embed/yRzFxT8xwh0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen="accelerometer; autoplay;"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* section-title */}
                  <div className="section-title with-desc text-center clearfix">
                    <div className="title-header">
                      <h2 className="title">Walkthrough VSDCH</h2>
                    </div>
                    <div className="title-desc">
                      A journey through the learning life of
                      <br />
                      VSDCH
                    </div>
                  </div>
                  <div className="mt-5">
                    <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white">
                      VSDCH Prospectus
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Events */}
        <section
          className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix"
          style={{ paddingBottom: "70px" }}
        >
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      <span>Events</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <Slider
              className="row slick_slider ttm-boxes-spacing-30px"
              {...slick_slider}
            >
              {this.dynamicData.events === undefined ? (
                <NoData />
              ) : (
                this.dynamicData.events.length &&
                this.dynamicData.events.map(function (item, index) {
                  return (
                    <div className="ttm-box-col-wrapper" key={index}>
                      <div className="ttm-history-box-wrapper">
                        <div className="ttm-history-box-icon-wrapper">
                          <div className="featured-icon-box">
                            <div className="featured-icon">
                              <div
                                className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md "
                                style={{ width: "200px" }}
                              >
                                <img
                                  src={item.Image}
                                  alt="col-bgimage-1"
                                  style={{ width: "200px", height: "120px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="ttm-history-box-border"
                          style={{ margin: "49px 0px 43px -5px" }}
                        />
                        <div
                          className="ttm-history-box-details ttm-bgcolor-white h-100"
                          style={{ minHeight: "230px" }}
                        >
                          <div className="ttm-historybox-title">
                            <h5>{item.Header}</h5>
                          </div>
                          <div className="ttm-historybox-description text-justify">
                            {item.Description}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        </section>

        {/* Course Structure*/}
        <section
          className=" ttm-row services-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes  clearfix spacing_bottom"
          id="Course"
          style={{ padding: "70px" }}
        >
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-6 offset-lg-3">
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Course{" "}
                      <span className="ttm-textcolor-white">Structure</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            {/* row end */}
            {/* row */}
            <div className="row">
              <div className="col-lg-4 col-md-4 d-flex">
                {/* featured-icon-box */}
                <div
                  className="featured-icon-box style5 text-left res-767-mb-20"
                  style={{ paddingBottom: "60px" }}
                >
                  {/* featured-icon */}
                  <div className="featured-content">
                    {/* featured-content */}
                    <div className="featured-title">
                      <h5>BDS</h5>
                      {/* featured-title */}
                    </div>
                    <div className="featured-desc text-justify">
                      {/* featured-description */}
                      <p>
                        {/* <ReactReadMoreReadLess
                              charLimit={20}
                              readMoreText={"ReadMore^"}
                              readLessText={"ReadLess^"}
                              readMoreClassName="read-more-less--more"
                              readlessClassName="read-more-less--less"
                            > */}
                        BDS (Bachelor of Dental Surgery) is a 5-years
                        undergraduate program. BDS mainly focuses on training
                        and introducing the students to the dental sciences and
                        surgeries. The 5-year program involves 4 years of
                        classroom education and 1 year of compulsory rotating
                        internship. The degree is regulated by the Dental
                        Council of India (DCI).
                        {/* </ReactReadMoreReadLess> */}
                      </p>
                    </div>
                    <a
                      href={pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      style={{ position: "absolute", bottom: "10px" }}
                    >
                      download Syllabus
                    </a>
                  </div>
                  {/* featured-content END*/}
                </div>
                {/* featured-icon-box */}
              </div>
              <div className="col-lg-4 col-md-4 d-flex">
                {/* featured-icon-box */}
                <div className="featured-icon-box style5 text-left res-767-mb-20">
                  {/* featured-icon */}
                  <div className="featured-content" style={{ padding: "15px" }}>
                    {/* featured-content */}
                    <div className="featured-title">
                      <h5>MDS</h5>
                      {/* featured-title */}
                    </div>
                    <div className="featured-desc text-justify">
                      {/* featured-description */}
                      <p>
                        Master of Dental Surgery or MDS course is a master level
                        degree for student who have graduated in Dental Science.{" "}
                      </p>
                      <p>
                        MDS degree as recognized by Dental Council of India
                        (DCI) is offered in various specializations
                      </p>
                      {/* <ul className="ttm-feature-lines">
                        <li>1. Oral Medicine and Radiology </li>
                        <li>2. Public Health Dentistry </li>
                        <li>3.Oral and Maxillofacial Surgery </li>
                        <li>4. Prosthodontics </li>
                        <li>5. Periodontics </li>
                        <li>6.Conservative Dentistry and Endodontics </li>
                        <li>7. Orthodontics and Dentofacial Orthopedics </li>
                        <li>8. Pedodontics and Preventive Dentistry </li>
                        <li>9. Oral Pathology</li>
                      </ul> */}
                    </div>
                    <a
                      href={pdf} // mds pdf pending
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      style={{ position: "absolute", bottom: "10px" }}
                    >
                      download Syllabus
                    </a>
                  </div>
                  {/* featured-content END*/}
                </div>
                {/* featured-icon-box */}
              </div>
              <div className="col-lg-4 col-md-4 d-flex">
                {/* featured-icon-box */}
                <div className="featured-icon-box style5 text-left res-767-mb-20">
                  {/* featured-icon */}
                  <div className="featured-content">
                    {/* featured-content */}
                    <div className="featured-title">
                      <h5>Ph.D</h5>
                      {/* featured-title */}
                    </div>
                    <div
                      className="featured-desc text-justify"
                      style={{ padding: "0px 0px 20px 0px" }}
                    >
                      {/* featured-description */}
                      <p>Ph.D degree is offered in the following subjects: </p>
                      <ul>
                        <li>1. Oral and Maxillofacial Surgery </li>
                        <li>2. Conservative Dentistry and Endodontics.</li>
                      </ul>
                      <p>
                        A Ph.D in Dentistry is a globally recognized
                        postgraduate academic degree after MDS awarded by RGUHS
                        to a candidate who has submitted a thesis or
                        dissertation, based on extensive and original research
                        in their chosen field.{" "}
                      </p>
                      <p>
                        The course structure is according to RGUHS ordinance.
                      </p>
                    </div>
                    <a
                      href={pdf1}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                      style={{ position: "absolute", bottom: "10px" }}
                    >
                      download Syllabus
                    </a>
                  </div>
                  {/* featured-content END*/}
                </div>
                {/* featured-icon-box */}
              </div>
            </div>
            {/* row end*/}
          </div>
        </section>

        {/* Vsdc IN NumbersVsd*/}
        <section className="ttm-row clearfix">
          <div className="container-xl">
            <div className="row no-gutters mt_100 res-991-mt-0">
              <div className="col-md-6">
                <div className="ttm-bgcolor-darkgrey ttm-textcolor-white z-11 position-relative h-100">
                  <div className="layer-content">
                    {/* layer-content */}
                    <div className="spacing-2">
                      {/* spacing-2 */}
                      {/* section title */}
                      <div className="section-title ttm with-desc clearfix">
                        <div className="title-header">
                          {/* <h5>What We Do</h5> */}
                          <h2 className="title">
                            VSDCH In{" "}
                            <span className="ttm-textcolor-white">Numbers</span>
                          </h2>
                        </div>
                      </div>
                      {/* section title end */}
                      {/* progress-bar */}
                      <div className="ttm-progress-bar">
                        <div className="progress-bar-title float-left">
                          No of Students
                        </div>
                        {/* progress-bar-title */}
                        <label className="float-right">646</label>
                        <MDBProgress
                          className="w-100"
                          material
                          value={70}
                        ></MDBProgress>
                      </div>
                      <div className="ttm-progress-bar">
                        <div className="progress-bar-title float-left">
                          Number of Faculty
                        </div>
                        {/* progress-bar-title */}
                        <label className="float-right">954</label>
                        <MDBProgress
                          className="w-100"
                          material
                          value={80}
                        ></MDBProgress>
                      </div>
                      {/* progress-bar END */}
                      {/* progress-bar */}
                      <div className="ttm-progress-bar">
                        <div className="progress-bar-title float-left">
                          Number of Alumni
                        </div>
                        {/* progress-bar-title */}
                        <label className="float-right">1640</label>
                        <MDBProgress
                          className="w-100"
                          material
                          value={80}
                        ></MDBProgress>
                      </div>
                      {/* progress-bar END */}
                    </div>
                    {/* spacing-2 END */}
                  </div>
                  {/* layer-content END */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-bg-img-two  ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-skincolor ttm-textcolor-white">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div className="ttm-bg-layer-inner" />
                  </div>
                  <div className="layer-content">
                    <div className="testimonial-slide style1">
                      <Slider
                        className="row slick_slider"
                        arrows={false}
                        dots={true}
                      >
                        <div className="col-lg-12">
                          <div className="testimonials text-center">
                            <div className="testimonial-content">
                              {/* testimonials-content */}
                              <div className="testimonial-avatar">
                                <div className="testimonial-img">
                                  {/* testimonials-img */}
                                  <img
                                    className="img-center"
                                    alt="testimonial-img"
                                    src={image12}
                                  />
                                </div>
                              </div>
                              <blockquote>
                                By having so altruistically and successfully
                                raised the practice of Dentistry from an
                                indifferent trade to a dignified profession, he
                                is rightly called the Father of Modern Dentistry
                              </blockquote>
                              <br />
                              <div className="testimonial-caption">
                                {/* testimonials-caption */}
                                <h6>Pierre Fauchard</h6>
                                <label>Father of Modern Dentistry</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="testimonials text-center">
                            <div className="testimonial-content">
                              {/* testimonials-content */}
                              <div className="testimonial-avatar">
                                <div className="testimonial-img">
                                  {/* testimonials-img */}
                                  <img
                                    className="img-center"
                                    src={image10}
                                    alt="testimonial-img"
                                  />
                                </div>
                              </div>
                              <blockquote>
                                “The professional has no right to be other than
                                a continuous student”
                              </blockquote>
                              <br />
                              <div className="testimonial-caption">
                                {/* testimonials-caption */}
                                <h6>G. V. Black</h6>
                                <label></label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="testimonials text-center">
                            <div className="testimonial-content">
                              {/* testimonials-content */}
                              <div className="testimonial-avatar">
                                <div className="testimonial-img">
                                  {/* testimonials-img */}
                                  <img
                                    className="img-center"
                                    alt="testimonial-img"
                                    src={image11}
                                  />
                                </div>
                              </div>
                              <blockquote>
                                “Public health is the combination of science,
                                practical skills, and values directed to the
                                maintenance and improvement of the health of all
                                the people”
                              </blockquote>
                              <br />
                              <div className="testimonial-caption">
                                {/* testimonials-caption */}
                                <h6>John M Last</h6>
                                <label></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Students Acheivers */}
        <section className="ttm-row blog-section res-991-pt-0 clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h5>Our Students</h5>
                    <h2 className="title">
                      Students <span>Acheivers</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            <Slider
              className="row slick_slider ttm-boxes-spacing-30px "
              {...slick_slider}
              slidesToShow={3}
            >
              {/* {console.log(this.state.studentAcheivers)} */}
              {this.dynamicData.studentAcheivers === undefined ? (
                <NoData />
              ) : (
                this.dynamicData.studentAcheivers.length &&
                this.dynamicData.studentAcheivers.map(function (item, index) {
                  return (
                    <div className="ttm-box-col-wrapper " key={index}>
                      <div className="featured-imagebox featured-imagebox-blog">
                        <div className="featured-thumbnail">
                          {/* featured-thumbnail */}
                          <img
                            className="img-fluid"
                            alt=""
                            src={item.Image}
                            style={{ width: "360px", height: "260px" }}
                          />
                        </div>
                        <div className="featured-content">
                          {/* featured-content */}
                          <div className="ttm-box-post-date">
                            {/* ttm-box-post-date */}
                            <span className="ttm-entry-date">
                              <time
                                className="entry-date"
                                dateTime="2019-01-16T07:07:55+00:00"
                              >
                                <span className="entry-month entry-year">
                                  {item.Year}
                                </span>
                              </time>
                            </span>
                          </div>
                          <div className="featured-title">
                            {/* featured-title */}
                            <h5>
                              <a href={process.env.PUBLIC_URL + "/Single_blog"}>
                                {item.Name}
                              </a>
                            </h5>
                          </div>
                          <div className="post-meta">
                            {/* post-meta */}
                            <span className="ttm-meta-line">
                              <i className="fa fa-comments" />
                              {item.Achievement}
                            </span>
                            <span className="ttm-meta-line">
                              <i className="fa fa-user" />
                              {item.Course}
                            </span>
                          </div>
                          <div className="featured-desc text-justify">
                            {/* featured-description */}
                            <p>{item.Description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        </section>

        {/* VSDS Prospectus*/}
        <section className="ttm-row bottomzero-padding-section  ttm-bg ttm-bgimage-yes bg-img5 ttm-bgcolor-skincolor clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl pb-5 res-991-pb-0">
            <div className="row align-items-center ttm-textcolor-white">
              <div className="col-lg-5 res-991-text-center">
                <div className="section-title style3 mb-0 clearfix">
                  <div className="title-header mb-0">
                    <h5>VSDCH</h5>
                    <h2 className="title">
                      VSDCH{" "}
                      <span className="ttm-textcolor-white">Prospectus</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 res-991-text-center">
                <div className="title-desc">
                  Admissions to undergraduate (BDS) courses, postgraduate
                  courses (MDS) and doctorate courses (Ph.D) at the Institution
                  are based on NEET ranking and as per DCI and RGUHS guidelines.
                </div>
              </div>
              <div className="col-lg-2 text-right res-991-text-center res-991-mt-20">
                <a
                  href="true"
                  className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"
                >
                  {" "}
                  Prospectus{" "}
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Faculty Acheivers */}
        <section className="ttm-row blog-section res-991-pt-0 clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h5>Our Staff</h5>
                    <h2 className="title">
                      Faculty <span>Acheivers</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>

            <Slider
              className="row slick_slider ttm-boxes-spacing-30px"
              {...slick_slider}
              slidesToShow={3}
            >
              {/* {console.log(this.state.facultyAcheivers)} */}
              {this.dynamicData.facultyAcheivers === undefined ? (
                <NoData />
              ) : (
                this.dynamicData.facultyAcheivers.length &&
                this.dynamicData.facultyAcheivers.map(function (item, index) {
                  return (
                    <div className=" ttm-box-col-wrapper " key={index}>
                      <div className="featured-imagebox featured-imagebox-blog">
                        <div className="featured-thumbnail">
                          {/* featured-thumbnail */}
                          <img
                            className="img-fluid"
                            alt=""
                            src={item.Image}
                            style={{ width: "360px", height: "260px" }}
                          />
                        </div>
                        <div className="featured-content">
                          {/* featured-content */}
                          <div className="ttm-box-post-date">
                            {/* ttm-box-post-date */}
                            <span className="ttm-entry-date">
                              <time
                                className="entry-date"
                                dateTime="2019-01-16T07:07:55+00:00"
                              >
                                {item.Year}
                              </time>
                            </span>
                          </div>
                          <div className="featured-title">
                            {/* featured-title */}
                            <h5>
                              <a href={process.env.PUBLIC_URL + "/Single_blog"}>
                                {item.Name}
                              </a>
                            </h5>
                          </div>
                          <div className="post-meta">
                            {/* post-meta */}
                            <span className="ttm-meta-line">
                              <i className="fa fa-user" />
                              {item.Designation}
                            </span>
                            <span className="ttm-meta-line">
                              <i className="fa fa-comments" />
                              {item.Department}
                            </span>
                          </div>
                          <div className="featured-desc text-justify">
                            {/* featured-description */}
                            <p>{item.Achievement}</p>
                            <p>{item.Description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
